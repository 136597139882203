import { RequestRow } from '../../types';

export const STATUS_FILTER_TYPES = ['all', 'in-progress', 'completed', 'cancelled'] as const;
export type StatusFilterType = (typeof STATUS_FILTER_TYPES)[number];

export const STATUS_FILTER_MAP: Record<StatusFilterType, RequestRow['status'][]> = {
  all: ['PENDING', 'ACTIVE', 'COMPLETED', 'CANCELLED', 'TERMINATED', 'FAILED'],
  'in-progress': ['ACTIVE', 'FAILED'],
  completed: ['COMPLETED'],
  cancelled: ['CANCELLED', 'TERMINATED'],
} as const;

export type StatusFilter = {
  label: string;
  value: StatusFilterType;
  active: boolean;
};
