import { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { CircularProgress, Stack, useTheme } from '@mui/material';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link, useBlocker, useNavigate, useParams } from 'react-router-dom';

import Text from '@verticeone/design-system/src/components/Text';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import Button from '@verticeone/design-system/src/components/Button';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';

import { useGetWorkflowVersionQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Definitions } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { WorkflowEditor } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/WorkflowEditor';
import { WorkflowViewer } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowViewer/WorkflowViewer';
import Divider from '@verticeone/design-system/src/components/Divider';
import { IntelligentWorkflowsBreadcrumbs } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';
import { useWorkflowVersions } from '../WorkflowDetailPage/useWorkflowVersions';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { useSaveWorkflow } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useSaveWorkflow';
import { useConfirmPageExit } from '@vertice/core/src/hooks/useConfirmPageExit';
import { useSnackbar } from 'notistack';
import { DiscardChangesDialog } from './components/DiscardChangesDialog';

export const WorkflowEditorPage: FC = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams() as { workflowId: string; versionId: string; serviceId: string };

  const {
    data: workflowVersionData,
    isLoading: isWorkflowVersionLoading,
    refetch,
  } = useGetWorkflowVersionQuery({
    accountId,
    workflowId: params.workflowId,
    workflowVersion: params.versionId,
  });

  const { activeVersion } = useWorkflowVersions(params.workflowId);
  const { saveWorkflow, isSavingWorkflow } = useSaveWorkflow();
  const navigate = useNavigate();

  const workflowDefinition = workflowVersionData?.workflowDefinition as Definitions;
  const [workingDefinitions, setWorkingDefinitions] = useState<Definitions | undefined>(workflowDefinition);
  const [hasChanges, setHasChanges] = useState(false);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const isChangingPage = nextLocation.pathname !== currentLocation.pathname;
    const isExitAction = Boolean(new URLSearchParams(currentLocation.search).get('exit'));

    return hasChanges && isChangingPage && !isExitAction;
  });

  useEffect(() => {
    if (!isEqual(workingDefinitions, workflowDefinition)) setHasChanges(true);
    else setHasChanges(false);
  }, [workingDefinitions, workflowDefinition]);

  useEffect(() => {
    if (workflowDefinition) {
      setWorkingDefinitions(workflowDefinition);
    }
  }, [workflowDefinition]);

  useConfirmPageExit(hasChanges);

  if (isWorkflowVersionLoading) {
    return <Placeholder height="100%" />;
  }

  const handleSaveWorkflow = (shouldGoBack = false) => {
    if (workingDefinitions) {
      saveWorkflow(
        {
          workflowId: params.workflowId,
          definitions: workingDefinitions,
          workflowVersion: workflowVersionData?.versionId,
          serviceRef: workflowVersionData?.serviceRef,
          customVersionName: workflowVersionData?.versionName,
        },
        async () => {
          if (shouldGoBack) {
            const param = new URLSearchParams({ exit: 'true' }).toString();

            // Since state setters are async, we will use a search param as deciding factor to navigate
            navigate(`?${param}`);
            navigate(backPathWithTabParam);
          } else {
            await refetch();
          }

          enqueueSnackbar({ variant: 'success', message: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_SUCCESS') });
        },
        () => {
          enqueueSnackbar({
            variant: 'error',
            message: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_FAILURE'),
            description: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_FAILURE_DESCRIPTION'),
          });
        }
      );
    }
  };

  const backPath = generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL, {
    workflowId: params.workflowId,
    serviceId: params.serviceId,
  });

  const backPathWithTabParam = `${backPath}?${new URLSearchParams({ tab: 'drafts' }).toString()}`;

  return (
    <Stack height={'100%'} minWidth={'100%'}>
      <Stack
        paddingTop={6}
        paddingRight={8}
        paddingBottom={6}
        paddingLeft={6}
        bgcolor={palette.background.default}
        direction="column"
        gap={4}
      >
        <IntelligentWorkflowsBreadcrumbs
          parentPageAccountId={accountId}
          parentPageName={activeVersion?.workflowName}
          parentPagePath={backPathWithTabParam}
          currentPageName={workflowVersionData?.versionName}
        />
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={4} alignItems={'center'}>
            <IconButton
              icon={ChevronLeftOutlined}
              size="S"
              variant="outline"
              component={Link}
              to={backPathWithTabParam}
            />
            {workflowVersionData ? (
              <Text variant="heading" size="M">
                {`${activeVersion?.workflowName} - ${workflowVersionData?.versionName}`}
              </Text>
            ) : (
              <Placeholder width={300} height={32} />
            )}
            <ChipButton variant="solid" color="transparent" size="M" onClick={() => null} style={{ cursor: 'default' }}>
              {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DRAFT')}
            </ChipButton>
          </Stack>
          <Stack direction="row" gap={2}>
            <Button
              variant="plain"
              color="secondary"
              size="S"
              disabled={!hasChanges}
              onClick={() => {
                handleSaveWorkflow();
              }}
            >
              {isSavingWorkflow ? (
                <CircularProgress size="XS" color="secondary" />
              ) : (
                t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.SAVE')
              )}
            </Button>
            <Button
              color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
              variant="solid"
              size="S"
              onClick={() => {
                handleSaveWorkflow(true);
              }}
            >
              {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.SAVE_AND_CLOSE')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack flexGrow={1}>
        {workflowDefinition ? (
          <WorkflowViewer definitions={workingDefinitions} isEditor>
            <WorkflowEditor
              workflowDefinitions={workingDefinitions}
              setDefinitions={setWorkingDefinitions}
              serviceRef={workflowVersionData?.serviceRef}
            />
          </WorkflowViewer>
        ) : (
          <Placeholder height="100%" />
        )}
      </Stack>
      <DiscardChangesDialog
        open={blocker.state === 'blocked'}
        onClose={() => blocker.reset?.()}
        onDiscard={() => blocker.proceed?.()}
      />
    </Stack>
  );
};
