import { PredefinedFormDef } from '../shared/types';
import { RenewalRequirementsForm } from './RenewalRequirementsForm';
import { zodSchema, FormData } from './schema';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/renewal\/requirementsGathering\/v\d+$/,
  component: RenewalRequirementsForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue) => ({
    products: getValue('products'),
    vendor: getValue('vendor'),
    renewalType: getValue('renewalType'),

    targetSignDate: getValue('targetSignDate'),
    desiredContractLength: getValue('desiredContractLength'),

    legalReviewRequired: getValue('legalReviewRequired'),
    securityReviewRequired: getValue('securityReviewRequired'),
    piiExpectedToBeHeld: getValue('piiExpectedToBeHeld'),

    additionalNotes: getValue('additionalNotes'),

    mainVendorContact: getValue('mainVendorContact', true),
    mainVendorContactName: getValue('mainVendorContactName'),
    mainVendorContactEmail: getValue('mainVendorContactEmail'),
    contactVendorDirectly: getValue('contactVendorDirectly'),
    contractCurrency: getValue('contractCurrency'),

    approvedBudget: getValue('approvedBudget'),

    discussMultiYearDeals: getValue('discussMultiYearDeals'),
    discussPlannedGrowth: getValue('discussPlannedGrowth'),
    discussCompetitors: getValue('discussCompetitors'),
    discussCaseStudies: getValue('discussCaseStudies'),

    parentAccountId: getValue('parentAccountId'),
  }),
};
