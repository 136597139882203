import { api } from '../baseApi';
export type Maybe<T> = T;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
  BigInt: any;
  Double: any;
  Long: any;
};

export type AccountSettings = {
  __typename?: 'AccountSettings';
  excludedFromCco?: Maybe<Scalars['Boolean']>;
};

export type AccountSettingsParameters = {
  excludedFromCco?: InputMaybe<Scalars['Boolean']>;
};

export type AccountValue = {
  __typename?: 'AccountValue';
  id: Scalars['String'];
  name: Scalars['String'];
  probeErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
  probeStatus?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<AccountSettings>;
  tags?: Maybe<Array<KeyValueType>>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Availability = {
  __typename?: 'Availability';
  frequency?: Maybe<Scalars['String']>;
  initialCutOff?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
};

export type AwsAccountParameters = {
  accountSettings: AccountSettingsParameters;
  awsAccountId: Scalars['String'];
};

export type BaseQueryParameters = {
  accountId: Scalars['String'];
};

export type BinnedTestResult = {
  __typename?: 'BinnedTestResult';
  bin?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  cost: Scalars['Float'];
  summary: TestResultSummary;
  usage?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['String']>;
};

export type BooleanTestResult = {
  __typename?: 'BooleanTestResult';
  code: Scalars['String'];
  cost: Scalars['Float'];
  summary: TestResultSummary;
  usage?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['String']>;
};

export type CsvResult = {
  __typename?: 'CSVResult';
  result: Scalars['String'];
};

export type CacheData = {
  __typename?: 'CacheData';
  compressed?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  request?: Maybe<Scalars['String']>;
  retentionDate?: Maybe<Scalars['AWSTimestamp']>;
};

export type CacheQueryResult = CacheResult | ErroredQueryResult;

export type CacheResult = {
  __typename?: 'CacheResult';
  items?: Maybe<Array<Maybe<CacheData>>>;
};

export type CheckDataPoint = {
  __typename?: 'CheckDataPoint';
  time: Scalars['String'];
  values: Array<CheckValues>;
};

export type CheckQueryParameters = {
  accountId: Scalars['String'];
  code: Scalars['String'];
  startDate?: InputMaybe<Scalars['AWSDate']>;
};

export type CheckQueryResult = CheckResult | DeferredQueryResult | ErroredQueryResult;

export type CheckResult = {
  __typename?: 'CheckResult';
  hashcode: Scalars['String'];
  result?: Maybe<Array<Maybe<CheckDataPoint>>>;
};

export type CheckSpecification = {
  accountId: Scalars['String'];
  dataSourceName: Scalars['String'];
  invokeEvaluation?: InputMaybe<Scalars['Boolean']>;
};

export type CheckValues = {
  __typename?: 'CheckValues';
  checkResult: MonitoringValueCheckResult;
  code: Scalars['String'];
  dateEnd: Scalars['String'];
  dateStart: Scalars['String'];
};

export enum ContractStatus {
  Active = 'ACTIVE',
  All = 'ALL',
  Inactive = 'INACTIVE',
}

export type CostAllocationSpan = {
  __typename?: 'CostAllocationSpan';
  commitment: Scalars['Float'];
  discount: Scalars['Float'];
  endDate: Scalars['AWSDate'];
  name: Scalars['ID'];
  spanLength?: Maybe<Scalars['Int']>;
  startDate: Scalars['AWSDate'];
};

export type CostAllocationSpanInput = {
  commitment: Scalars['Float'];
  discount: Scalars['Float'];
  endDate: Scalars['AWSDate'];
  name: Scalars['ID'];
  spanLength?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['AWSDate'];
};

export type CostModel = {
  __typename?: 'CostModel';
  costAllocationSpans: Array<CostAllocationSpan>;
  endDate: Scalars['AWSDate'];
  marketplaceSpendCap?: Maybe<Scalars['Float']>;
  startDate: Scalars['AWSDate'];
};

export type CostModelContainer = {
  __typename?: 'CostModelContainer';
  costModel?: Maybe<CostModel>;
  id: Scalars['ID'];
};

export type CostModelInput = {
  costAllocationSpans: Array<CostAllocationSpanInput>;
  endDate: Scalars['AWSDate'];
  marketplaceSpendCap?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['AWSDate'];
};

export type CostUsageQueryParameters = {
  accountId: Scalars['String'];
  dimensions?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['AWSDate']>;
  precision: ForecastPrecisionType;
  recordType?: InputMaybe<Array<Scalars['String']>>;
  recordTypeExclude?: InputMaybe<Scalars['Boolean']>;
  refresh?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['AWSDate'];
  unit: ForecastUnit;
};

export type DataSource = {
  __typename?: 'DataSource';
  availability: DataSourceAvailability;
  code: Scalars['String'];
  intervals?: Maybe<Array<Maybe<Interval>>>;
  name: Scalars['String'];
  reconciledAt?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tasks?: Maybe<Array<Maybe<DataSourceTask>>>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  version: Scalars['String'];
};

export type DataSourceAvailability = {
  __typename?: 'DataSourceAvailability';
  frequency?: Maybe<Scalars['String']>;
  granularity?: Maybe<Scalars['String']>;
  initialCutoff?: Maybe<Scalars['String']>;
  prerequisites?: Maybe<Array<Maybe<DataSourcePrerequisite>>>;
};

export type DataSourceCondition = {
  __typename?: 'DataSourceCondition';
  code?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
};

export type DataSourcePrerequisite = {
  __typename?: 'DataSourcePrerequisite';
  condition?: Maybe<Scalars['String']>;
  datasource?: Maybe<DataSourceCondition>;
  type?: Maybe<Scalars['String']>;
};

export type DataSourceQueryParameters = {
  accountId: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
};

export type DataSourceQueryResult = DataSourceResult | ErroredQueryResult;

export type DataSourceResult = {
  __typename?: 'DataSourceResult';
  items?: Maybe<Array<DataSource>>;
};

export type DataSourceSpecification = {
  accountId: Scalars['String'];
  dataSourceName: Scalars['String'];
  includeDependent?: InputMaybe<Scalars['Boolean']>;
  reconcile?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['String'];
};

export type DataSourceTask = {
  __typename?: 'DataSourceTask';
  createdAt: Scalars['String'];
  errorData?: Maybe<ErrorData>;
  id: Scalars['String'];
  jobId?: Maybe<Scalars['String']>;
  range?: Maybe<Interval>;
  rescheduledAt?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
};

export type DataTable = {
  __typename?: 'DataTable';
  availableColumns?: Maybe<Array<Scalars['String']>>;
  columns: Array<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  dataTypes: Array<Scalars['String']>;
};

export type DataTableResult = {
  __typename?: 'DataTableResult';
  link?: Maybe<Scalars['String']>;
  table?: Maybe<DataTable>;
};

export type DataTableResultTableArgs = {
  columns: Array<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Order>;
  orderColumns?: InputMaybe<Array<Scalars['String']>>;
};

export type DeferredQueryResult = {
  __typename?: 'DeferredQueryResult';
  hashcode: Scalars['String'];
};

export type DeletedContractResult = EdpContractMemento | ErroredQueryResult;

export type EdpContract = {
  __typename?: 'EDPContract';
  costModel: CostModel;
  identifier: Scalars['ID'];
};

export type EdpContractFacade = {
  __typename?: 'EDPContractFacade';
  contractId: Scalars['ID'];
  costModelContainer: CostModelContainer;
};

export type EdpContractInput = {
  accountId: Scalars['ID'];
  costModel: CostModelInput;
};

export type EdpContractListing = {
  __typename?: 'EDPContractListing';
  items?: Maybe<Array<Maybe<EdpContractFacade>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type EdpContractListingResult = EdpContractListing | ErroredQueryResult;

export type EdpContractMemento = {
  __typename?: 'EDPContractMemento';
  identifier: Scalars['ID'];
};

export type EdpContractResult = EdpContract | EdpValidationError | ErroredQueryResult;

export type EdpContractsQueryParams = {
  accountId: Scalars['String'];
  contractStatus?: InputMaybe<ContractStatus>;
};

export type EdpValidationError = {
  __typename?: 'EDPValidationError';
  code: Scalars['String'];
  context?: Maybe<Scalars['AWSJSON']>;
  fieldName: Scalars['String'];
};

export type EbsAttachment = {
  __typename?: 'EbsAttachment';
  attachTime: Scalars['AWSDateTime'];
  deleteOnTermination: Scalars['Boolean'];
  device: Scalars['String'];
  instanceId: Scalars['String'];
  state: Scalars['String'];
  volumeId: Scalars['String'];
};

export type EbsSnapshot = {
  __typename?: 'EbsSnapshot';
  dataEncryptionKeyId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  encrypted: Scalars['Boolean'];
  kmsKeyId?: Maybe<Scalars['String']>;
  outpostArn?: Maybe<Scalars['String']>;
  ownerAlias?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  progress?: Maybe<Scalars['String']>;
  restoreExpiryTime?: Maybe<Scalars['AWSDateTime']>;
  snapshotId: Scalars['String'];
  sseType?: Maybe<Scalars['String']>;
  startTime: Scalars['AWSDateTime'];
  state: Scalars['String'];
  stateMessage?: Maybe<Scalars['String']>;
  storageTier: Scalars['String'];
  volumeId: Scalars['String'];
  volumeSize: Scalars['Int'];
};

export type EbsVolume = {
  __typename?: 'EbsVolume';
  attachments?: Maybe<Array<Maybe<EbsAttachment>>>;
  createTime: Scalars['AWSDateTime'];
  encrypted: Scalars['Boolean'];
  fastRestored?: Maybe<Scalars['Boolean']>;
  iops?: Maybe<Scalars['Int']>;
  kmsKeyId?: Maybe<Scalars['String']>;
  multiAttachEnabled: Scalars['Boolean'];
  outpostArn?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  snapshotId: Scalars['String'];
  sseType?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  throughput?: Maybe<Scalars['Int']>;
  volumeId: Scalars['String'];
  volumeType: Scalars['String'];
};

export type ErrorData = {
  __typename?: 'ErrorData';
  cause?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  maxAttempts?: Maybe<Scalars['Int']>;
  remainingAttempts?: Maybe<Scalars['Int']>;
  retryable?: Maybe<Scalars['Boolean']>;
};

export type ErroredQueryResult = {
  __typename?: 'ErroredQueryResult';
  error: Scalars['String'];
};

export type EventDetails = {
  __typename?: 'EventDetails';
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};

export enum ExecutionType {
  Immediate = 'IMMEDIATE',
  Scheduled = 'SCHEDULED',
}

export type ExistingEdpContractInput = {
  accountId: Scalars['ID'];
  costModel: CostModelInput;
  identifier: Scalars['ID'];
};

export type ExistingEdpContractMemento = {
  accountId: Scalars['ID'];
  identifier: Scalars['ID'];
};

export type ForecastDataPoint = {
  __typename?: 'ForecastDataPoint';
  time: Scalars['String'];
  values: Array<ForecastValue>;
};

export enum ForecastPrecisionType {
  Day = 'DAY',
  Month = 'MONTH',
}

export type ForecastQueryParameters = {
  accountId: Scalars['String'];
  precision: ForecastPrecisionType;
  predictionIntervalLevel?: InputMaybe<Scalars['Int']>;
  refresh?: InputMaybe<Scalars['Boolean']>;
  unit: ForecastUnit;
};

export type ForecastQueryResult = ErroredQueryResult | ForecastResult;

export type ForecastResult = {
  __typename?: 'ForecastResult';
  hashcode: Scalars['String'];
  result?: Maybe<Array<Maybe<ForecastDataPoint>>>;
};

export enum ForecastUnit {
  AmortizedCost = 'AMORTIZED_COST',
  BlendedCost = 'BLENDED_COST',
  NetAmortizedCost = 'NET_AMORTIZED_COST',
  NetUnblendedCost = 'NET_UNBLENDED_COST',
  NormalizedUsageAmount = 'NORMALIZED_USAGE_AMOUNT',
  UnblendedCost = 'UNBLENDED_COST',
  UsageQuantity = 'USAGE_QUANTITY',
}

export type ForecastValue = {
  __typename?: 'ForecastValue';
  lowerBound?: Maybe<Scalars['Float']>;
  mean: Scalars['Float'];
  upperBound?: Maybe<Scalars['Float']>;
};

export enum Frequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Never = 'NEVER',
  Once = 'ONCE',
  Weekly = 'WEEKLY',
}

export type IntegrationQueryResult = ErroredQueryResult | IntegrationResult;

export type IntegrationResult = {
  __typename?: 'IntegrationResult';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Interval = {
  __typename?: 'Interval';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type KeyValueType = {
  __typename?: 'KeyValueType';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum LimitedDataSourceNames {
  AwsOrganizationsExplorerReport = 'AWS_ORGANIZATIONS_EXPLORER_REPORT',
}

export type ListCurQueryResult = ErroredQueryResult | ListCurResult;

export type ListCurResult = {
  __typename?: 'ListCURResult';
  items: Array<Maybe<PartitionResult>>;
};

export type MetricQueryParameters = {
  accountId: Scalars['String'];
  dimensions?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['AWSDate']>;
  filter?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  metric: Scalars['String'];
  precision?: InputMaybe<PrecisionType>;
  refresh?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['AWSDate']>;
  units?: InputMaybe<Array<Scalars['String']>>;
};

export type MetricQueryResult = DeferredQueryResult | ErroredQueryResult | MetricSeriesResult;

export type MetricSeriesResult = {
  __typename?: 'MetricSeriesResult';
  hashcode: Scalars['String'];
  result?: Maybe<Array<Maybe<TimeSeriesDataPoint>>>;
};

export type MonitoringLatestResults =
  | BinnedTestResult
  | BooleanTestResult
  | ErroredQueryResult
  | MonitoringValueCheckResult
  | MulticlassBinnedTestResult;

export type MonitoringQueryParameters = {
  accountId: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  monitoringType: MonitoringType;
};

export type MonitoringQueryResult = ErroredQueryResult | MonitoringResult;

export type MonitoringResult = {
  __typename?: 'MonitoringResult';
  items?: Maybe<Array<MonitoringValue>>;
};

export enum MonitoringType {
  Check = 'CHECK',
  Test = 'TEST',
}

export type MonitoringValue = {
  __typename?: 'MonitoringValue';
  breaching?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  dateEnd?: Maybe<Scalars['String']>;
  dateResolution?: Maybe<Scalars['String']>;
  dateStart?: Maybe<Scalars['String']>;
  resultPartitions?: Maybe<Array<Maybe<Scalars['String']>>>;
  results?: Maybe<MonitoringLatestResults>;
  tags?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  taskFulfilledAt?: Maybe<Scalars['String']>;
};

export type MonitoringValueCheckResult = {
  __typename?: 'MonitoringValueCheckResult';
  columns: Array<Maybe<Scalars['String']>>;
  data: Array<Maybe<Array<Maybe<Scalars['String']>>>>;
  dataTypes: Array<Maybe<Scalars['String']>>;
};

export type MulticlassBinnedTestResult = {
  __typename?: 'MulticlassBinnedTestResult';
  code: Scalars['String'];
  cost: Scalars['Float'];
  dataViews?: Maybe<Array<TestResultDataView>>;
  labelCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  labelTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  labels?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  summary: TestResultSummary;
  usage?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  buyReservation?: Maybe<RiPurchaseResult>;
  cacheInvalidation?: Maybe<PutEventResult>;
  createContract?: Maybe<EdpContractResult>;
  deleteContract?: Maybe<DeletedContractResult>;
  deleteQueuedReservation?: Maybe<RiDeleteResult>;
  evaluateRecommendations?: Maybe<PutEventResult>;
  refreshDataSource?: Maybe<PutEventResult>;
  restartCheck?: Maybe<PutEventResult>;
  restartChecks?: Maybe<PutEventResult>;
  restartDataSource?: Maybe<PutEventResult>;
  updateContract?: Maybe<EdpContractResult>;
  updateSettings?: Maybe<PutEventResult>;
};

export type MutationBuyReservationArgs = {
  params?: InputMaybe<RiPurchaseParameters>;
};

export type MutationCacheInvalidationArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type MutationCreateContractArgs = {
  params?: InputMaybe<EdpContractInput>;
};

export type MutationDeleteContractArgs = {
  params?: InputMaybe<ExistingEdpContractMemento>;
};

export type MutationDeleteQueuedReservationArgs = {
  params?: InputMaybe<RiDeleteReservationParameters>;
};

export type MutationEvaluateRecommendationsArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type MutationRefreshDataSourceArgs = {
  params?: InputMaybe<RefreshQueryParameters>;
};

export type MutationRestartCheckArgs = {
  params?: InputMaybe<CheckSpecification>;
};

export type MutationRestartChecksArgs = {
  params?: InputMaybe<RestartChecksParameters>;
};

export type MutationRestartDataSourceArgs = {
  params?: InputMaybe<DataSourceSpecification>;
};

export type MutationUpdateContractArgs = {
  params?: InputMaybe<ExistingEdpContractInput>;
};

export type MutationUpdateSettingsArgs = {
  params?: InputMaybe<SettingsUpdateParameters>;
};

export type OptimizationRecommendation = {
  __typename?: 'OptimizationRecommendation';
  breaching: Scalars['Boolean'];
  code: Scalars['String'];
  effort?: Maybe<RecommendationEffort>;
  proposedCost?: Maybe<Scalars['Float']>;
  resourceCost: Scalars['Float'];
  saving: Scalars['Float'];
  status?: Maybe<RecommendationStatus>;
};

export type OptimizationSaving = {
  __typename?: 'OptimizationSaving';
  category: OptimizationSavingCategory;
  count: Scalars['Int'];
  saving: Scalars['Float'];
};

export enum OptimizationSavingCategory {
  Cancelled = 'CANCELLED',
  HighEffort = 'HIGH_EFFORT',
  LowEffort = 'LOW_EFFORT',
  MediumEffort = 'MEDIUM_EFFORT',
  Total = 'TOTAL',
}

export type OptimizationSummary = {
  __typename?: 'OptimizationSummary';
  estimatedSavings: Array<Maybe<OptimizationSaving>>;
  recsBreaching: Scalars['Float'];
  recsCancelled: Scalars['Float'];
  recsNonBreaching: Scalars['Float'];
  recsTotal: Scalars['Float'];
};

export type OptimizationTest = {
  __typename?: 'OptimizationTest';
  breaching: Scalars['Boolean'];
  category: Scalars['String'];
  code: Scalars['String'];
  recommendations: Array<OptimizationRecommendation>;
  saving: Scalars['Float'];
  testSummary: TestResultSummary;
};

export type OptimizationsQueryResult = ErroredQueryResult | OptimizationsResult;

export type OptimizationsResult = {
  __typename?: 'OptimizationsResult';
  summary: OptimizationSummary;
  tests: Array<Maybe<OptimizationTest>>;
};

export enum Order {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum OrgResourceType {
  Account = 'ACCOUNT',
  ManagementAccount = 'MANAGEMENT_ACCOUNT',
  OrganizationalUnit = 'ORGANIZATIONAL_UNIT',
  Root = 'ROOT',
}

export type PartitionResult = {
  __typename?: 'PartitionResult';
  files?: Maybe<Scalars['Int']>;
  partitions: Array<Scalars['String']>;
  rows?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Long']>;
};

export type PoliciesResult = {
  __typename?: 'PoliciesResult';
  action: Scalars['String'];
  decision: Scalars['String'];
  group: Scalars['String'];
  resource: Scalars['String'];
};

export enum PrecisionType {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type PriceListQueryParameters = {
  accountId: Scalars['String'];
  filter?: InputMaybe<Scalars['String']>;
  pricelist: Scalars['String'];
};

export type PriceListQueryResult = DeferredQueryResult | ErroredQueryResult | PriceListResult;

export type PriceListResult = {
  __typename?: 'PriceListResult';
  hashcode: Scalars['String'];
  result?: Maybe<TableType>;
};

export type ProbeCurResult = {
  __typename?: 'ProbeCURResult';
  items?: Maybe<Array<Maybe<ProbeResultType>>>;
  result?: Maybe<Scalars['Boolean']>;
};

export type ProbeQueryParameters = {
  accountId: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  roleArn?: InputMaybe<Scalars['String']>;
  s3Bucket?: InputMaybe<Scalars['String']>;
  s3Prefix?: InputMaybe<Scalars['String']>;
};

export type ProbeQueryResult = ErroredQueryResult | ProbeCurResult | ProbeRoleResult;

export type ProbeResultType = {
  __typename?: 'ProbeResultType';
  code: Scalars['String'];
  description: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export type ProbeRoleResult = {
  __typename?: 'ProbeRoleResult';
  result: Array<Maybe<PoliciesResult>>;
};

export type ProposedCostsValue = {
  __typename?: 'ProposedCostsValue';
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
};

export type PutEventDetails = {
  __typename?: 'PutEventDetails';
  entries?: Maybe<Array<Maybe<EventDetails>>>;
  failedEntry?: Maybe<Scalars['Int']>;
};

export type PutEventResult = ErroredQueryResult | PutEventDetails;

export type Query = {
  __typename?: 'Query';
  athenaQuery?: Maybe<MetricQueryResult>;
  athenaViewQuery?: Maybe<ViewResult>;
  cacheQuery?: Maybe<CacheQueryResult>;
  checkQuery?: Maybe<CheckQueryResult>;
  costUsageQuery?: Maybe<MetricQueryResult>;
  dataSourceQuery?: Maybe<DataSourceQueryResult>;
  forecastQuery?: Maybe<ForecastQueryResult>;
  integrationQuery?: Maybe<IntegrationQueryResult>;
  listCURQuery?: Maybe<ListCurQueryResult>;
  listEDPContracts?: Maybe<EdpContractListingResult>;
  monitoringLatestQuery?: Maybe<MonitoringQueryResult>;
  optimizationsQuery?: Maybe<OptimizationsQueryResult>;
  priceListQuery?: Maybe<PriceListQueryResult>;
  probeCURQuery?: Maybe<ProbeQueryResult>;
  probeRoleQuery?: Maybe<ProbeQueryResult>;
  recommendationsQuery?: Maybe<RecommendationsQueryResult>;
  resourceQuery?: Maybe<ResourceQueryUnionResult>;
  riListOfferingsQuery?: Maybe<RiListOfferingsUnionResult>;
  riPurchasePermissionQuery?: Maybe<RiPurchasePermissionUnionResult>;
  riPurchasesQuery?: Maybe<RiPurchasesUnionResult>;
  testDataTableQuery?: Maybe<DataTableResult>;
  testResultsQuery?: Maybe<TestQueryResult>;
};

export type QueryAthenaQueryArgs = {
  params?: InputMaybe<MetricQueryParameters>;
};

export type QueryAthenaViewQueryArgs = {
  params?: InputMaybe<ViewQueryParameters>;
};

export type QueryCacheQueryArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type QueryCheckQueryArgs = {
  params?: InputMaybe<CheckQueryParameters>;
};

export type QueryCostUsageQueryArgs = {
  params?: InputMaybe<CostUsageQueryParameters>;
};

export type QueryDataSourceQueryArgs = {
  params?: InputMaybe<DataSourceQueryParameters>;
};

export type QueryForecastQueryArgs = {
  params?: InputMaybe<ForecastQueryParameters>;
};

export type QueryIntegrationQueryArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type QueryListCurQueryArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type QueryListEdpContractsArgs = {
  params?: InputMaybe<EdpContractsQueryParams>;
};

export type QueryMonitoringLatestQueryArgs = {
  params?: InputMaybe<MonitoringQueryParameters>;
};

export type QueryOptimizationsQueryArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type QueryPriceListQueryArgs = {
  params?: InputMaybe<PriceListQueryParameters>;
};

export type QueryProbeCurQueryArgs = {
  params?: InputMaybe<ProbeQueryParameters>;
};

export type QueryProbeRoleQueryArgs = {
  params?: InputMaybe<ProbeQueryParameters>;
};

export type QueryRecommendationsQueryArgs = {
  params?: InputMaybe<BaseQueryParameters>;
};

export type QueryResourceQueryArgs = {
  params?: InputMaybe<ResourceQueryParameters>;
};

export type QueryRiListOfferingsQueryArgs = {
  params?: InputMaybe<RiOfferingParameters>;
};

export type QueryRiPurchasePermissionQueryArgs = {
  params?: InputMaybe<RiPurchasePermissionParameters>;
};

export type QueryRiPurchasesQueryArgs = {
  params?: InputMaybe<RiPurchasesParameters>;
};

export type QueryTestDataTableQueryArgs = {
  params?: InputMaybe<TestResultsTableParameters>;
};

export type QueryTestResultsQueryArgs = {
  params?: InputMaybe<TestResultsQueryParameters>;
};

export enum RiAction {
  Offerings = 'OFFERINGS',
  Purchase = 'PURCHASE',
}

export type RiDeleteReservation = {
  purchaseOrderId: Scalars['String'];
  region: Scalars['String'];
  service: Scalars['String'];
};

export type RiDeleteReservationParameters = {
  accountId: Scalars['String'];
  deletions: Array<RiDeleteReservation>;
};

export type RiDeleteResult = ErroredQueryResult | ReservedInstanceDeleteResult;

export type RiInstanceType = {
  __typename?: 'RIInstanceType';
  count: Scalars['Int'];
  product?: Maybe<Scalars['String']>;
  tenancy?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RiListOfferingsUnionResult = ErroredQueryResult | RiOfferingsEc2Result | RiOfferingsRdsResult;

export type RiOfferingEc2 = {
  __typename?: 'RIOfferingEC2';
  currencyCode: Scalars['String'];
  duration: Scalars['Int'];
  fixedPrice: Scalars['Float'];
  instanceTenancy: Scalars['String'];
  instanceType: Scalars['String'];
  marketplace: Scalars['Boolean'];
  offeringClass: Scalars['String'];
  offeringType: Scalars['String'];
  productDescription: Scalars['String'];
  recurringCharges?: Maybe<Array<RiRecurringPrice>>;
  reservedInstancesOfferingId: Scalars['String'];
  scope: Scalars['String'];
  usagePrice: Scalars['Float'];
};

export type RiOfferingParameters = {
  accountId: Scalars['String'];
  filter?: InputMaybe<RiOfferingsFilter>;
  region: Scalars['String'];
  service: Scalars['String'];
};

export type RiOfferingRds = {
  __typename?: 'RIOfferingRDS';
  currencyCode: Scalars['String'];
  dbInstanceClass: Scalars['String'];
  duration: Scalars['Int'];
  fixedPrice: Scalars['Float'];
  multiAZ: Scalars['Boolean'];
  offeringType: Scalars['String'];
  productDescription: Scalars['String'];
  recurringCharges?: Maybe<Array<RiRecurringPrice>>;
  reservedDBInstancesOfferingId: Scalars['String'];
  usagePrice: Scalars['Float'];
};

export type RiOfferingType = {
  __typename?: 'RIOfferingType';
  class?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type RiOfferingsEc2Result = {
  __typename?: 'RIOfferingsEC2Result';
  items?: Maybe<Array<RiOfferingEc2>>;
};

export type RiOfferingsFilter = {
  duration?: InputMaybe<Scalars['Int']>;
  includeMarketplace?: InputMaybe<Scalars['Boolean']>;
  instanceFamily: Scalars['String'];
  instanceSize: Scalars['String'];
  multiAZ?: InputMaybe<Scalars['Boolean']>;
  offeringClass?: InputMaybe<Scalars['String']>;
  offeringType?: InputMaybe<Scalars['String']>;
  productDescription: Scalars['String'];
};

export type RiOfferingsRdsResult = {
  __typename?: 'RIOfferingsRDSResult';
  items?: Maybe<Array<RiOfferingRds>>;
};

export type RiPriceType = {
  __typename?: 'RIPriceType';
  fixed?: Maybe<Scalars['Float']>;
  recurring?: Maybe<Array<RiRecurringPrice>>;
  usage?: Maybe<Scalars['Float']>;
};

export type RiPurchase = {
  __typename?: 'RIPurchase';
  accountId: Scalars['String'];
  awsAccountId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  details?: Maybe<RiResult>;
  id: Scalars['String'];
  purchasePlannedAt: Scalars['String'];
  purchasedAt?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type RiPurchaseParameters = {
  accountId: Scalars['String'];
  purchases: Array<RiPurchaseReservation>;
};

export type RiPurchasePermissionParameters = {
  accountId: Scalars['String'];
  action: RiAction;
  region: Scalars['String'];
  service: Scalars['String'];
};

export type RiPurchasePermissionResult = {
  __typename?: 'RIPurchasePermissionResult';
  action: RiAction;
  allowed: Scalars['Boolean'];
  service: Scalars['String'];
};

export type RiPurchasePermissionUnionResult = ErroredQueryResult | RiPurchasePermissionResult;

export type RiPurchaseReservation = {
  instanceCount: Scalars['Int'];
  offeringId: Scalars['String'];
  purchaseExecutionTime: Scalars['AWSDateTime'];
  region: Scalars['String'];
  service: Scalars['String'];
};

export type RiPurchaseResult = ErroredQueryResult | ReservedInstancePurchaseResult;

export type RiPurchasesParameters = {
  accountId: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  purchaseId?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RiPurchasesResult = {
  __typename?: 'RIPurchasesResult';
  items?: Maybe<Array<Maybe<RiPurchase>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type RiPurchasesUnionResult = ErroredQueryResult | RiPurchasesResult;

export type RiRecurringPrice = {
  __typename?: 'RIRecurringPrice';
  amount?: Maybe<Scalars['Float']>;
  frequency?: Maybe<Scalars['String']>;
};

export type RiResult = {
  __typename?: 'RIResult';
  currencyCode?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['String']>;
  instance?: Maybe<RiInstanceType>;
  multiAZ?: Maybe<Scalars['Boolean']>;
  offering?: Maybe<RiOfferingType>;
  price?: Maybe<RiPriceType>;
  region?: Maybe<Scalars['String']>;
  retentionDate?: Maybe<Scalars['AWSTimestamp']>;
  scope?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['AWSDateTime']>;
  state?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<KeyValueType>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum RecommendationEffort {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export type RecommendationSettingParameters = {
  status?: InputMaybe<RecommendationStatus>;
};

export type RecommendationSettings = {
  code: Scalars['String'];
  recommendationSettings: RecommendationSettingParameters;
};

export enum RecommendationStatus {
  Cancelled = 'CANCELLED',
  Discovered = 'DISCOVERED',
  EmptyResults = 'EMPTY_RESULTS',
  Fulfilled = 'FULFILLED',
  Inprogress = 'INPROGRESS',
}

export type RecommendationValue = {
  __typename?: 'RecommendationValue';
  breaching: Scalars['Boolean'];
  categories: Array<Maybe<Scalars['String']>>;
  code: Scalars['String'];
  effort?: Maybe<RecommendationEffort>;
  empty?: Maybe<Scalars['Boolean']>;
  frequency?: Maybe<Frequency>;
  fulfilledAt?: Maybe<Scalars['String']>;
  products: Array<Maybe<Scalars['String']>>;
  proposedCost?: Maybe<Scalars['Float']>;
  proposedCosts?: Maybe<ProposedCostsValue>;
  resourceCost: Scalars['Float'];
  status?: Maybe<RecommendationStatus>;
  tags?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  testResults?: Maybe<Array<MonitoringValue>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type RecommendationsQueryResult = ErroredQueryResult | RecommendationsResult;

export type RecommendationsResult = {
  __typename?: 'RecommendationsResult';
  items: Array<Maybe<RecommendationValue>>;
};

export type RefreshQueryParameters = {
  accountId: Scalars['String'];
  dataSourceName: LimitedDataSourceNames;
  startDate: Scalars['AWSDate'];
};

export type ReservedInstanceDelete = {
  __typename?: 'ReservedInstanceDelete';
  purchaseOrderId: Scalars['String'];
  status: Scalars['String'];
};

export type ReservedInstanceDeleteResult = {
  __typename?: 'ReservedInstanceDeleteResult';
  items: Array<Maybe<ReservedInstanceDelete>>;
};

export type ReservedInstancePurchase = {
  __typename?: 'ReservedInstancePurchase';
  dueDate: Scalars['String'];
  offeringId: Scalars['String'];
  purchaseOrderId: Scalars['String'];
  status: Scalars['String'];
};

export type ReservedInstancePurchaseResult = {
  __typename?: 'ReservedInstancePurchaseResult';
  items: Array<Maybe<ReservedInstancePurchase>>;
};

export enum ResourceCode {
  EbsSnapshot = 'EBS_SNAPSHOT',
  EbsVolume = 'EBS_VOLUME',
  OrganizationResource = 'ORGANIZATION_RESOURCE',
  RiEc2 = 'RI_EC2',
  RiRds = 'RI_RDS',
}

export type ResourceItem = {
  __typename?: 'ResourceItem';
  accountId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  resource: ResourceUnion;
};

export type ResourceQueryFilter = {
  endDate?: InputMaybe<Scalars['String']>;
  excludeDeleted?: InputMaybe<Scalars['Boolean']>;
  orgResourceTypes?: InputMaybe<Array<OrgResourceType>>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type ResourceQueryParameters = {
  accountId: Scalars['String'];
  code: ResourceCode;
  filter?: InputMaybe<ResourceQueryFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type ResourceQueryResult = {
  __typename?: 'ResourceQueryResult';
  items?: Maybe<Array<Maybe<ResourceItem>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ResourceQueryUnionResult = ErroredQueryResult | ResourceQueryResult;

export type ResourceUnion = AccountValue | EbsSnapshot | EbsVolume | RiResult;

export type RestartChecksParameters = {
  accountId: Scalars['String'];
  executionType?: InputMaybe<ExecutionType>;
  invokeEvaluation?: InputMaybe<Scalars['Boolean']>;
};

export type SettingsUpdateParameters = {
  accountId: Scalars['String'];
  accounts?: InputMaybe<Array<InputMaybe<AwsAccountParameters>>>;
  recommendations?: InputMaybe<Array<InputMaybe<RecommendationSettings>>>;
};

export type TableType = {
  __typename?: 'TableType';
  columns: Array<Scalars['String']>;
  data: Array<Maybe<Array<Maybe<Scalars['String']>>>>;
  dataTypes?: Maybe<Array<Scalars['String']>>;
};

export type TestDataPoint = {
  __typename?: 'TestDataPoint';
  resultDetails: TestResultDetails;
  time: Scalars['String'];
};

export type TestQueryResult = DeferredQueryResult | ErroredQueryResult | TestResult;

export type TestResult = {
  __typename?: 'TestResult';
  hashcode: Scalars['String'];
  result?: Maybe<Array<Maybe<TestDataPoint>>>;
};

export type TestResultDataView = {
  __typename?: 'TestResultDataView';
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TestResultDetails = BinnedTestResult | BooleanTestResult | MulticlassBinnedTestResult;

export type TestResultSummary = {
  __typename?: 'TestResultSummary';
  tooltip?: Maybe<TestResultTooltip>;
  type: Scalars['String'];
  value: Scalars['String'];
};

export type TestResultTooltip = {
  __typename?: 'TestResultTooltip';
  data?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TestResultsQueryParameters = {
  accountId: Scalars['String'];
  code: Scalars['String'];
  startDate?: InputMaybe<Scalars['AWSDate']>;
};

export type TestResultsTableParameters = {
  accountId: Scalars['String'];
  code: Scalars['String'];
  data_view_name?: InputMaybe<Scalars['String']>;
};

export type TimeSeriesDataPoint = {
  __typename?: 'TimeSeriesDataPoint';
  time: Scalars['String'];
  values: Array<TimeSeriesDataValue>;
};

export type TimeSeriesDataValue = {
  __typename?: 'TimeSeriesDataValue';
  dimensions: Array<Scalars['String']>;
  units: Array<Scalars['String']>;
  values: Array<Scalars['Float']>;
};

export type ViewQueryParameters = {
  accountId: Scalars['String'];
  name: Scalars['String'];
  parameters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ViewResult = CsvResult | DataTableResult | DeferredQueryResult | ErroredQueryResult;

export type ForecastCostQueryVariables = Exact<{
  accountId: Scalars['String'];
  precision: ForecastPrecisionType;
}>;

export type ForecastCostQuery = {
  __typename?: 'Query';
  forecastQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'ForecastResult';
        result?: Array<{
          __typename?: 'ForecastDataPoint';
          time: string;
          values: Array<{ __typename: 'ForecastValue'; mean: number; lowerBound?: number; upperBound?: number }>;
        }>;
      };
};

export type SpendByServiceLineQueryVariables = Exact<{
  accountId: Scalars['String'];
  startDate: Scalars['AWSDate'];
  endDate?: InputMaybe<Scalars['AWSDate']>;
  precision: ForecastPrecisionType;
}>;

export type SpendByServiceLineQuery = {
  __typename?: 'Query';
  costUsageQuery?:
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'MetricSeriesResult';
        result?: Array<{
          __typename?: 'TimeSeriesDataPoint';
          time: string;
          values: Array<{
            __typename: 'TimeSeriesDataValue';
            dimensions: Array<string>;
            units: Array<string>;
            values: Array<number>;
          }>;
        }>;
      };
};

export type UsageCostPerServiceQueryVariables = Exact<{
  accountId: Scalars['String'];
  startDate: Scalars['AWSDate'];
  endDate?: InputMaybe<Scalars['AWSDate']>;
  precision: ForecastPrecisionType;
}>;

export type UsageCostPerServiceQuery = {
  __typename?: 'Query';
  costUsageQuery?:
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'MetricSeriesResult';
        result?: Array<{
          __typename?: 'TimeSeriesDataPoint';
          time: string;
          values: Array<{
            __typename: 'TimeSeriesDataValue';
            dimensions: Array<string>;
            units: Array<string>;
            values: Array<number>;
          }>;
        }>;
      };
};

export type UsageCostPerRecordTypeQueryVariables = Exact<{
  accountId: Scalars['String'];
  startDate: Scalars['AWSDate'];
  endDate?: InputMaybe<Scalars['AWSDate']>;
  precision: ForecastPrecisionType;
}>;

export type UsageCostPerRecordTypeQuery = {
  __typename?: 'Query';
  costUsageQuery?:
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'MetricSeriesResult';
        result?: Array<{
          __typename?: 'TimeSeriesDataPoint';
          time: string;
          values: Array<{
            __typename: 'TimeSeriesDataValue';
            dimensions: Array<string>;
            units: Array<string>;
            values: Array<number>;
          }>;
        }>;
      };
};

export type UsageCostPerAccountQueryVariables = Exact<{
  accountId: Scalars['String'];
  startDate: Scalars['AWSDate'];
  precision: ForecastPrecisionType;
}>;

export type UsageCostPerAccountQuery = {
  __typename?: 'Query';
  costUsageQuery?:
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'MetricSeriesResult';
        result?: Array<{
          __typename?: 'TimeSeriesDataPoint';
          time: string;
          values: Array<{
            __typename: 'TimeSeriesDataValue';
            dimensions: Array<string>;
            units: Array<string>;
            values: Array<number>;
          }>;
        }>;
      };
};

export type OrgHierarchyQueryVariables = Exact<{
  accountId: Scalars['String'];
  orgResourceTypes?: InputMaybe<Array<OrgResourceType> | OrgResourceType>;
}>;

export type OrgHierarchyQuery = {
  __typename?: 'Query';
  resourceQuery?:
    | { __typename?: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'ResourceQueryResult';
        nextToken?: string;
        items?: Array<{
          __typename?: 'ResourceItem';
          resource:
            | {
                __typename?: 'AccountValue';
                id: string;
                name: string;
                probeErrors?: Array<string>;
                probeStatus?: boolean;
                type: string;
                updatedAt: string;
                tags?: Array<{ __typename?: 'KeyValueType'; key: string; value: string }>;
                settings?: { __typename?: 'AccountSettings'; excludedFromCco?: boolean };
              }
            | { __typename?: 'EbsSnapshot' }
            | { __typename?: 'EbsVolume' }
            | { __typename?: 'RIResult' };
        }>;
      };
};

export type ComputeUsageByProductQueryVariables = Exact<{
  accountId: Scalars['String'];
  startDate: Scalars['AWSDate'];
  groupBy: Scalars['String'];
}>;

export type ComputeUsageByProductQuery = {
  __typename?: 'Query';
  athenaQuery?:
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'MetricSeriesResult';
        result?: Array<{
          __typename?: 'TimeSeriesDataPoint';
          time: string;
          values: Array<{
            __typename?: 'TimeSeriesDataValue';
            dimensions: Array<string>;
            units: Array<string>;
            values: Array<number>;
          }>;
        }>;
      };
};

export type LatestOptimizationTestsQueryVariables = Exact<{
  accountId: Scalars['String'];
  testCode?: InputMaybe<Scalars['String']>;
}>;

export type LatestOptimizationTestsQuery = {
  __typename?: 'Query';
  monitoringLatestQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'MonitoringResult';
        items?: Array<{
          __typename?: 'MonitoringValue';
          breaching?: boolean;
          code: string;
          dateStart?: string;
          dateEnd?: string;
          dateResolution?: string;
          taskFulfilledAt?: string;
          tags?: Array<Array<string>>;
          results?:
            | {
                __typename: 'BinnedTestResult';
                bin?: string;
                code: string;
                cost: number;
                value?: string;
                summary: {
                  __typename?: 'TestResultSummary';
                  value: string;
                  type: string;
                  tooltip?: { __typename?: 'TestResultTooltip'; data?: Array<Array<string>>; types?: Array<string> };
                };
              }
            | {
                __typename: 'BooleanTestResult';
                code: string;
                cost: number;
                value?: string;
                summary: {
                  __typename?: 'TestResultSummary';
                  value: string;
                  type: string;
                  tooltip?: { __typename?: 'TestResultTooltip'; data?: Array<Array<string>>; types?: Array<string> };
                };
              }
            | { __typename: 'ErroredQueryResult' }
            | {
                __typename: 'MonitoringValueCheckResult';
                columns: Array<string>;
                data: Array<Array<string>>;
                dataTypes: Array<string>;
              }
            | {
                __typename: 'MulticlassBinnedTestResult';
                code: string;
                cost: number;
                labelCategories?: Array<string>;
                labelTypes?: Array<string>;
                labels?: Array<Array<string>>;
                summary: {
                  __typename?: 'TestResultSummary';
                  value: string;
                  type: string;
                  tooltip?: { __typename?: 'TestResultTooltip'; data?: Array<Array<string>>; types?: Array<string> };
                };
              };
        }>;
      };
};

export type LatestOptimizationCheckQueryVariables = Exact<{
  accountId: Scalars['String'];
  checkCode: Scalars['String'];
}>;

export type LatestOptimizationCheckQuery = {
  __typename?: 'Query';
  monitoringLatestQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'MonitoringResult';
        items?: Array<{
          __typename?: 'MonitoringValue';
          code: string;
          results?:
            | { __typename: 'BinnedTestResult' }
            | { __typename: 'BooleanTestResult' }
            | { __typename: 'ErroredQueryResult' }
            | {
                __typename: 'MonitoringValueCheckResult';
                columns: Array<string>;
                data: Array<Array<string>>;
                dataTypes: Array<string>;
              }
            | { __typename: 'MulticlassBinnedTestResult' };
        }>;
      };
};

export type OptimizationRecommendationsQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type OptimizationRecommendationsQuery = {
  __typename?: 'Query';
  recommendationsQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'RecommendationsResult';
        items: Array<{
          __typename?: 'RecommendationValue';
          breaching: boolean;
          categories: Array<string>;
          code: string;
          frequency?: Frequency;
          fulfilledAt?: string;
          updatedAt?: string;
          products: Array<string>;
          proposedCost?: number;
          empty?: boolean;
          resourceCost: number;
          status?: RecommendationStatus;
          tags?: Array<Array<string>>;
          proposedCosts?: { __typename?: 'ProposedCostsValue'; columns?: Array<string>; data?: Array<Array<number>> };
          testResults?: Array<{
            __typename: 'MonitoringValue';
            breaching?: boolean;
            code: string;
            dateEnd?: string;
            dateResolution?: string;
            dateStart?: string;
            tags?: Array<Array<string>>;
            taskFulfilledAt?: string;
          }>;
        }>;
      };
};

export type OptimizationCheckQueryVariables = Exact<{
  accountId: Scalars['String'];
  startDate: Scalars['AWSDate'];
  checkCode: Scalars['String'];
}>;

export type OptimizationCheckQuery = {
  __typename?: 'Query';
  checkQuery?:
    | {
        __typename: 'CheckResult';
        hashcode: string;
        result?: Array<{
          __typename?: 'CheckDataPoint';
          time: string;
          values: Array<{
            __typename?: 'CheckValues';
            code: string;
            dateEnd: string;
            dateStart: string;
            checkResult: {
              __typename?: 'MonitoringValueCheckResult';
              columns: Array<string>;
              data: Array<Array<string>>;
              dataTypes: Array<string>;
            };
          }>;
        }>;
      }
    | { __typename?: 'DeferredQueryResult' }
    | { __typename?: 'ErroredQueryResult'; error: string };
};

export type CloudIntegrationQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type CloudIntegrationQuery = {
  __typename?: 'Query';
  integrationQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | { __typename: 'IntegrationResult'; id?: string; status?: string };
};

export type PriceListQueryVariables = Exact<{
  accountId: Scalars['String'];
  pricelist: Scalars['String'];
  filter?: InputMaybe<Scalars['String']>;
}>;

export type PriceListQuery = {
  __typename?: 'Query';
  priceListQuery?:
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'PriceListResult';
        result?: {
          __typename?: 'TableType';
          columns: Array<string>;
          data: Array<Array<string>>;
          dataTypes?: Array<string>;
        };
      };
};

export type RefreshOrganizationsDataSourceMutationVariables = Exact<{
  accountId: Scalars['String'];
  startDate: Scalars['AWSDate'];
}>;

export type RefreshOrganizationsDataSourceMutation = {
  __typename?: 'Mutation';
  refreshDataSource?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'PutEventDetails';
        failedEntry?: number;
        entries?: Array<{ __typename?: 'EventDetails'; errorCode?: string; errorMessage?: string; eventId?: string }>;
      };
};

export type RiListOfferingsQueryVariables = Exact<{
  service: Scalars['String'];
  accountId: Scalars['String'];
  region: Scalars['String'];
  instanceFamily: Scalars['String'];
  instanceSize: Scalars['String'];
  productDescription: Scalars['String'];
  duration?: InputMaybe<Scalars['Int']>;
  offeringClass?: InputMaybe<Scalars['String']>;
  offeringType?: InputMaybe<Scalars['String']>;
  includeMarketplace?: InputMaybe<Scalars['Boolean']>;
  multiAZ?: InputMaybe<Scalars['Boolean']>;
}>;

export type RiListOfferingsQuery = {
  __typename?: 'Query';
  riListOfferingsQuery?:
    | { __typename?: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'RIOfferingsEC2Result';
        items?: Array<{
          __typename?: 'RIOfferingEC2';
          currencyCode: string;
          duration: number;
          fixedPrice: number;
          instanceTenancy: string;
          instanceType: string;
          marketplace: boolean;
          offeringClass: string;
          offeringType: string;
          productDescription: string;
          reservedInstancesOfferingId: string;
          scope: string;
          usagePrice: number;
          recurringCharges?: Array<{ __typename?: 'RIRecurringPrice'; amount?: number; frequency?: string }>;
        }>;
      }
    | {
        __typename: 'RIOfferingsRDSResult';
        items?: Array<{
          __typename?: 'RIOfferingRDS';
          currencyCode: string;
          dbInstanceClass: string;
          duration: number;
          fixedPrice: number;
          multiAZ: boolean;
          offeringType: string;
          productDescription: string;
          reservedDBInstancesOfferingId: string;
          usagePrice: number;
          recurringCharges?: Array<{ __typename?: 'RIRecurringPrice'; amount?: number; frequency?: string }>;
        }>;
      };
};

export type BuyReservationMutationVariables = Exact<{
  accountId: Scalars['String'];
  purchases: Array<RiPurchaseReservation> | RiPurchaseReservation;
}>;

export type BuyReservationMutation = {
  __typename?: 'Mutation';
  buyReservation?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'ReservedInstancePurchaseResult';
        items: Array<{
          __typename?: 'ReservedInstancePurchase';
          dueDate: string;
          offeringId: string;
          purchaseOrderId: string;
          status: string;
        }>;
      };
};

export type RiPurchasePermissionQueryVariables = Exact<{
  service: Scalars['String'];
  accountId: Scalars['String'];
  region: Scalars['String'];
  action: RiAction;
}>;

export type RiPurchasePermissionQuery = {
  __typename?: 'Query';
  riPurchasePermissionQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | { __typename: 'RIPurchasePermissionResult'; allowed: boolean };
};

export type EbsVolumeQueryVariables = Exact<{
  accountId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;

export type EbsVolumeQuery = {
  __typename?: 'Query';
  resourceQuery?:
    | { __typename?: 'ErroredQueryResult' }
    | {
        __typename: 'ResourceQueryResult';
        items?: Array<{
          __typename?: 'ResourceItem';
          accountId?: string;
          region?: string;
          resource:
            | { __typename?: 'AccountValue' }
            | { __typename?: 'EbsSnapshot' }
            | { __typename: 'EbsVolume'; volumeId: string; size?: number }
            | { __typename?: 'RIResult' };
        }>;
      };
};

export type RecommendationEstimatedSavingsQueryVariables = Exact<{
  accountId: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  date_range_from: Scalars['String'];
}>;

export type RecommendationEstimatedSavingsQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type Ec2ReservationsQueryVariables = Exact<{
  accountId: Scalars['String'];
  sizeFlexibilityGroup: Scalars['String'];
}>;

export type Ec2ReservationsQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type RdsReservationsQueryVariables = Exact<{
  accountId: Scalars['String'];
  instanceFamily: Scalars['String'];
  region: Scalars['String'];
  databaseEngine: Scalars['String'];
  databaseEdition: Scalars['String'];
  licenseModel: Scalars['String'];
}>;

export type RdsReservationsQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type RiPurchasesQueryVariables = Exact<{
  accountId: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;

export type RiPurchasesQuery = {
  __typename?: 'Query';
  riPurchasesQuery?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'RIPurchasesResult';
        nextToken?: string;
        items?: Array<{
          __typename?: 'RIPurchase';
          id: string;
          accountId: string;
          awsAccountId?: string;
          createdAt: string;
          purchasedAt?: string;
          purchasePlannedAt: string;
          revision?: number;
          status: string;
          updatedAt: string;
          details?: {
            __typename: 'RIResult';
            start?: any;
            end?: any;
            multiAZ?: boolean;
            currencyCode?: string;
            duration?: number;
            retentionDate?: any;
            scope?: string;
            state?: string;
            region?: string;
            service?: string;
            updatedAt?: string;
            tags?: Array<{ __typename?: 'KeyValueType'; key: string; value: string }>;
            instance?: {
              __typename?: 'RIInstanceType';
              count: number;
              tenancy?: string;
              type?: string;
              product?: string;
            };
            price?: {
              __typename?: 'RIPriceType';
              fixed?: number;
              usage?: number;
              recurring?: Array<{ __typename?: 'RIRecurringPrice'; amount?: number; frequency?: string }>;
            };
            offering?: { __typename?: 'RIOfferingType'; class?: string; type: string };
          };
        }>;
      };
};

export type ReservedInstancesByCodeQueryVariables = Exact<{
  accountId: Scalars['String'];
  code: ResourceCode;
}>;

export type ReservedInstancesByCodeQuery = {
  __typename?: 'Query';
  resourceQuery?:
    | { __typename?: 'ErroredQueryResult' }
    | {
        __typename: 'ResourceQueryResult';
        nextToken?: string;
        items?: Array<{
          __typename?: 'ResourceItem';
          accountId?: string;
          region?: string;
          resource:
            | { __typename?: 'AccountValue' }
            | { __typename?: 'EbsSnapshot' }
            | { __typename?: 'EbsVolume' }
            | {
                __typename?: 'RIResult';
                id?: string;
                currencyCode?: string;
                duration?: number;
                end?: any;
                retentionDate?: any;
                scope?: string;
                start?: any;
                state?: string;
                updatedAt?: string;
                multiAZ?: boolean;
                instance?: {
                  __typename?: 'RIInstanceType';
                  count: number;
                  product?: string;
                  tenancy?: string;
                  type?: string;
                };
                offering?: { __typename?: 'RIOfferingType'; class?: string; type: string };
                price?: {
                  __typename?: 'RIPriceType';
                  fixed?: number;
                  usage?: number;
                  recurring?: Array<{ __typename?: 'RIRecurringPrice'; amount?: number; frequency?: string }>;
                };
                tags?: Array<{ __typename?: 'KeyValueType'; key: string; value: string }>;
              };
        }>;
      };
};

export type OrganizationResourceQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type OrganizationResourceQuery = {
  __typename?: 'Query';
  resourceQuery?:
    | { __typename?: 'ErroredQueryResult' }
    | {
        __typename: 'ResourceQueryResult';
        nextToken?: string;
        items?: Array<{
          __typename?: 'ResourceItem';
          resource:
            | {
                __typename?: 'AccountValue';
                id: string;
                name: string;
                probeErrors?: Array<string>;
                probeStatus?: boolean;
                type: string;
                updatedAt: string;
                tags?: Array<{ __typename?: 'KeyValueType'; key: string; value: string }>;
              }
            | { __typename?: 'EbsSnapshot' }
            | { __typename?: 'EbsVolume' }
            | { __typename?: 'RIResult' };
        }>;
      };
};

export type CancelRiReservationMutationVariables = Exact<{
  accountId: Scalars['String'];
  deletions: Array<RiDeleteReservation> | RiDeleteReservation;
}>;

export type CancelRiReservationMutation = {
  __typename?: 'Mutation';
  deleteQueuedReservation?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'ReservedInstanceDeleteResult';
        items: Array<{ __typename?: 'ReservedInstanceDelete'; purchaseOrderId: string; status: string }>;
      };
};

export type RioTableQueryVariables = Exact<{
  accountId: Scalars['String'];
  code: Scalars['String'];
  dataViewName?: InputMaybe<Scalars['String']>;
  columns: Array<Scalars['String']> | Scalars['String'];
  orderColumns: Array<Scalars['String']> | Scalars['String'];
  order: Order;
  withLink: Scalars['Boolean'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type RioTableQuery = {
  __typename?: 'Query';
  testDataTableQuery?: {
    __typename?: 'DataTableResult';
    link?: string;
    table?: { __typename?: 'DataTable'; columns: Array<string>; dataTypes: Array<string>; data?: Array<Array<string>> };
  };
};

export type UpdateAccountSettingsMutationVariables = Exact<{
  accountId: Scalars['String'];
  accounts?: InputMaybe<Array<InputMaybe<AwsAccountParameters>> | InputMaybe<AwsAccountParameters>>;
}>;

export type UpdateAccountSettingsMutation = {
  __typename?: 'Mutation';
  updateSettings?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | { __typename?: 'PutEventDetails'; entries?: Array<{ __typename?: 'EventDetails'; eventId?: string }> };
};

export type UpdateRecommendationsMutationVariables = Exact<{
  accountId: Scalars['String'];
  recommendations?: InputMaybe<Array<InputMaybe<RecommendationSettings>> | InputMaybe<RecommendationSettings>>;
}>;

export type UpdateRecommendationsMutation = {
  __typename?: 'Mutation';
  updateSettings?:
    | { __typename: 'ErroredQueryResult'; error: string }
    | { __typename?: 'PutEventDetails'; entries?: Array<{ __typename?: 'EventDetails'; eventId?: string }> };
};

export type HistoricalSpendByEc2ProcessorQueryVariables = Exact<{
  accountId: Scalars['String'];
  billingPeriodStart: Scalars['String'];
}>;

export type HistoricalSpendByEc2ProcessorQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type Ec2ProcessorUsageQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type Ec2ProcessorUsageQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsAccountsQueryVariables = Exact<{
  accountId: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsAccountsQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsAccountServicesQueryVariables = Exact<{
  accountId: Scalars['String'];
  linkedAccountId: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsAccountServicesQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsAccountServicesUsageQueryVariables = Exact<{
  accountId: Scalars['String'];
  linkedAccountId: Scalars['String'];
  productCode: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsAccountServicesUsageQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsChartAccountsQueryVariables = Exact<{
  accountId: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsChartAccountsQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsChartAccountServicesQueryVariables = Exact<{
  accountId: Scalars['String'];
  linkedAccountId: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsChartAccountServicesQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsChartAccountServicesUsageQueryVariables = Exact<{
  accountId: Scalars['String'];
  linkedAccountId: Scalars['String'];
  productCode: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsChartAccountServicesUsageQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsTagsServicesQueryVariables = Exact<{
  accountId: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  tagName: Scalars['String'];
  tagValues?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsTagsServicesQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsTagsServicesUsageQueryVariables = Exact<{
  accountId: Scalars['String'];
  productCode: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  tagName: Scalars['String'];
  tagValues?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsTagsServicesUsageQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsTagsQueryVariables = Exact<{
  accountId: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
}>;

export type AnalyticsTagsQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsTagValuesQueryVariables = Exact<{
  accountId: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  tagName: Scalars['String'];
  offset: Scalars['String'];
  limit: Scalars['String'];
  responseLimit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsTagValuesQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type OptimizationsQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type OptimizationsQuery = {
  __typename?: 'Query';
  optimizationsQuery?:
    | { __typename?: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'OptimizationsResult';
        summary: {
          __typename?: 'OptimizationSummary';
          recsBreaching: number;
          recsCancelled: number;
          recsNonBreaching: number;
          recsTotal: number;
          estimatedSavings: Array<{
            __typename?: 'OptimizationSaving';
            category: OptimizationSavingCategory;
            count: number;
            saving: number;
          }>;
        };
        tests: Array<{
          __typename?: 'OptimizationTest';
          breaching: boolean;
          category: string;
          code: string;
          saving: number;
          testSummary: {
            __typename?: 'TestResultSummary';
            type: string;
            value: string;
            tooltip?: { __typename?: 'TestResultTooltip'; data?: Array<Array<string>>; types?: Array<string> };
          };
          recommendations: Array<{
            __typename?: 'OptimizationRecommendation';
            breaching: boolean;
            code: string;
            effort?: RecommendationEffort;
            proposedCost?: number;
            resourceCost: number;
            saving: number;
            status?: RecommendationStatus;
          }>;
        }>;
      };
};

export type AnalyticsChartTagsServicesQueryVariables = Exact<{
  accountId: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  tagName: Scalars['String'];
  tagValues?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsChartTagsServicesQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsChartTagsServicesUsageQueryVariables = Exact<{
  accountId: Scalars['String'];
  productCode: Scalars['String'];
  periodOneStart: Scalars['String'];
  periodOneEnd: Scalars['String'];
  periodTwoStart: Scalars['String'];
  periodTwoEnd: Scalars['String'];
  tagName: Scalars['String'];
  tagValues?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsChartTagsServicesUsageQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsDrawerAccountsQueryVariables = Exact<{
  accountId: Scalars['String'];
  periodStart: Scalars['String'];
  periodEnd: Scalars['String'];
  granularity: Scalars['String'];
  selectionIds?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsDrawerAccountsQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsDrawerAccountServicesQueryVariables = Exact<{
  accountId: Scalars['String'];
  linkedAccountId: Scalars['String'];
  periodStart: Scalars['String'];
  periodEnd: Scalars['String'];
  granularity: Scalars['String'];
  selectionIds?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsDrawerAccountServicesQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsDrawerAccountServicesUsageQueryVariables = Exact<{
  accountId: Scalars['String'];
  linkedAccountId: Scalars['String'];
  productCode: Scalars['String'];
  periodStart: Scalars['String'];
  periodEnd: Scalars['String'];
  granularity: Scalars['String'];
  selectionIds?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsDrawerAccountServicesUsageQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsDrawerTagsServicesQueryVariables = Exact<{
  accountId: Scalars['String'];
  periodStart: Scalars['String'];
  periodEnd: Scalars['String'];
  tagName: Scalars['String'];
  tagValues?: InputMaybe<Scalars['String']>;
  granularity: Scalars['String'];
  selectionIds?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsDrawerTagsServicesQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type AnalyticsDrawerTagsServicesUsageQueryVariables = Exact<{
  accountId: Scalars['String'];
  productCode: Scalars['String'];
  periodStart: Scalars['String'];
  periodEnd: Scalars['String'];
  tagName: Scalars['String'];
  tagValues?: InputMaybe<Scalars['String']>;
  granularity: Scalars['String'];
  selectionIds?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AnalyticsDrawerTagsServicesUsageQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult' }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type TestResultsQueryVariables = Exact<{
  accountId: Scalars['String'];
  startDate?: InputMaybe<Scalars['AWSDate']>;
  testCode: Scalars['String'];
}>;

export type TestResultsQuery = {
  __typename?: 'Query';
  testResultsQuery?:
    | { __typename: 'DeferredQueryResult'; hashcode: string }
    | { __typename: 'ErroredQueryResult'; error: string }
    | {
        __typename: 'TestResult';
        result?: Array<{
          __typename?: 'TestDataPoint';
          time: string;
          resultDetails:
            | { __typename?: 'BinnedTestResult' }
            | { __typename?: 'BooleanTestResult' }
            | {
                __typename: 'MulticlassBinnedTestResult';
                code: string;
                cost: number;
                dataViews?: Array<{
                  __typename?: 'TestResultDataView';
                  columns?: Array<string>;
                  data?: Array<Array<string>>;
                  name?: string;
                  types?: Array<string>;
                }>;
              };
        }>;
      };
};

export type SavingsPlanInventoryQueryVariables = Exact<{
  accountId: Scalars['String'];
  spType: Scalars['String'];
}>;

export type SavingsPlanInventoryQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          availableColumns?: Array<string>;
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult'; hashcode: string }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export type SavingsPlanUtilizationQueryVariables = Exact<{
  accountId: Scalars['String'];
  savingsPlanId: Scalars['String'];
}>;

export type SavingsPlanUtilizationQuery = {
  __typename?: 'Query';
  athenaViewQuery?:
    | { __typename: 'CSVResult' }
    | {
        __typename: 'DataTableResult';
        table?: {
          __typename?: 'DataTable';
          columns: Array<string>;
          data?: Array<Array<string>>;
          dataTypes: Array<string>;
        };
      }
    | { __typename: 'DeferredQueryResult'; hashcode: string }
    | { __typename: 'ErroredQueryResult'; error: string };
};

export const ForecastCostDocument = `
    query ForecastCost($accountId: String!, $precision: ForecastPrecisionType!) {
  forecastQuery(
    params: {accountId: $accountId, unit: UNBLENDED_COST, precision: $precision}
  ) {
    __typename
    ... on ForecastResult {
      result {
        time
        values {
          __typename
          mean
          lowerBound
          upperBound
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const SpendByServiceLineDocument = `
    query SpendByServiceLine($accountId: String!, $startDate: AWSDate!, $endDate: AWSDate, $precision: ForecastPrecisionType!) {
  costUsageQuery(
    params: {accountId: $accountId, startDate: $startDate, endDate: $endDate, unit: UNBLENDED_COST, precision: $precision, dimensions: ["CATEGORY", "RECORD_TYPE"]}
  ) {
    __typename
    ... on MetricSeriesResult {
      result {
        time
        values {
          __typename
          dimensions
          units
          values
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const UsageCostPerServiceDocument = `
    query UsageCostPerService($accountId: String!, $startDate: AWSDate!, $endDate: AWSDate, $precision: ForecastPrecisionType!) {
  costUsageQuery(
    params: {accountId: $accountId, startDate: $startDate, endDate: $endDate, unit: UNBLENDED_COST, precision: $precision, dimensions: ["SERVICE", "RECORD_TYPE"]}
  ) {
    __typename
    ... on MetricSeriesResult {
      result {
        time
        values {
          __typename
          dimensions
          units
          values
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const UsageCostPerRecordTypeDocument = `
    query UsageCostPerRecordType($accountId: String!, $startDate: AWSDate!, $endDate: AWSDate, $precision: ForecastPrecisionType!) {
  costUsageQuery(
    params: {accountId: $accountId, startDate: $startDate, endDate: $endDate, precision: $precision, unit: UNBLENDED_COST, dimensions: ["RECORD_TYPE"]}
  ) {
    __typename
    ... on MetricSeriesResult {
      result {
        time
        values {
          __typename
          dimensions
          units
          values
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const UsageCostPerAccountDocument = `
    query UsageCostPerAccount($accountId: String!, $startDate: AWSDate!, $precision: ForecastPrecisionType!) {
  costUsageQuery(
    params: {accountId: $accountId, startDate: $startDate, precision: $precision, unit: UNBLENDED_COST, dimensions: ["LINKED_ACCOUNT", "RECORD_TYPE"]}
  ) {
    __typename
    ... on MetricSeriesResult {
      result {
        time
        values {
          __typename
          dimensions
          units
          values
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const OrgHierarchyDocument = `
    query OrgHierarchy($accountId: String!, $orgResourceTypes: [OrgResourceType!]) {
  resourceQuery(
    params: {accountId: $accountId, code: ORGANIZATION_RESOURCE, filter: {orgResourceTypes: $orgResourceTypes}}
  ) {
    ... on ResourceQueryResult {
      __typename
      nextToken
      items {
        resource {
          ... on AccountValue {
            id
            name
            probeErrors
            probeStatus
            tags {
              key
              value
            }
            type
            updatedAt
            settings {
              ... on AccountSettings {
                excludedFromCco
              }
            }
          }
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const ComputeUsageByProductDocument = `
    query ComputeUsageByProduct($accountId: String!, $startDate: AWSDate!, $groupBy: String!) {
  athenaQuery(
    params: {accountId: $accountId, precision: MONTH, metric: "cco_services_total", units: ["amortized_cost"], startDate: $startDate, filter: "(product = 'AmazonEC2' and operation begins_with 'RunInstances') or (product = 'AWSLambda' and usage_type not in ('%DataTransfer%', '%AWS-In%', '%AWS-In%', '%Lambda-Storage%'))", dimensions: [$groupBy]}
  ) {
    __typename
    ... on MetricSeriesResult {
      result {
        time
        values {
          dimensions
          units
          values
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const LatestOptimizationTestsDocument = `
    query LatestOptimizationTests($accountId: String!, $testCode: String) {
  monitoringLatestQuery(
    params: {accountId: $accountId, monitoringType: TEST, code: $testCode}
  ) {
    __typename
    ... on MonitoringResult {
      items {
        breaching
        code
        dateStart
        dateEnd
        dateResolution
        taskFulfilledAt
        tags
        results {
          __typename
          ... on MonitoringValueCheckResult {
            columns
            data
            dataTypes
          }
          ... on BooleanTestResult {
            code
            cost
            value
            summary {
              value
              type
              tooltip {
                data
                types
              }
            }
          }
          ... on BinnedTestResult {
            bin
            code
            cost
            value
            summary {
              value
              type
              tooltip {
                data
                types
              }
            }
          }
          ... on MulticlassBinnedTestResult {
            code
            cost
            labelCategories
            labelTypes
            labels
            summary {
              value
              type
              tooltip {
                data
                types
              }
            }
          }
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const LatestOptimizationCheckDocument = `
    query LatestOptimizationCheck($accountId: String!, $checkCode: String!) {
  monitoringLatestQuery(
    params: {accountId: $accountId, monitoringType: CHECK, code: $checkCode}
  ) {
    __typename
    ... on MonitoringResult {
      items {
        code
        results {
          __typename
          ... on MonitoringValueCheckResult {
            columns
            data
            dataTypes
          }
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const OptimizationRecommendationsDocument = `
    query OptimizationRecommendations($accountId: String!) {
  recommendationsQuery(params: {accountId: $accountId}) {
    __typename
    ... on RecommendationsResult {
      items {
        breaching
        categories
        code
        frequency
        fulfilledAt
        updatedAt
        products
        proposedCost
        proposedCosts {
          columns
          data
        }
        empty
        resourceCost
        status
        tags
        testResults {
          __typename
          ... on MonitoringValue {
            breaching
            code
            dateEnd
            dateResolution
            dateStart
            tags
            taskFulfilledAt
          }
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const OptimizationCheckDocument = `
    query OptimizationCheck($accountId: String!, $startDate: AWSDate!, $checkCode: String!) {
  checkQuery(
    params: {accountId: $accountId, startDate: $startDate, code: $checkCode}
  ) {
    ... on CheckResult {
      __typename
      hashcode
      result {
        time
        values {
          code
          dateEnd
          dateStart
          checkResult {
            columns
            data
            dataTypes
          }
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const CloudIntegrationDocument = `
    query CloudIntegration($accountId: String!) {
  integrationQuery(params: {accountId: $accountId}) {
    __typename
    ... on IntegrationResult {
      id
      status
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const PriceListDocument = `
    query PriceList($accountId: String!, $pricelist: String!, $filter: String) {
  priceListQuery(
    params: {accountId: $accountId, pricelist: $pricelist, filter: $filter}
  ) {
    __typename
    ... on PriceListResult {
      __typename
      result {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const RefreshOrganizationsDataSourceDocument = `
    mutation RefreshOrganizationsDataSource($accountId: String!, $startDate: AWSDate!) {
  refreshDataSource(
    params: {accountId: $accountId, dataSourceName: AWS_ORGANIZATIONS_EXPLORER_REPORT, startDate: $startDate}
  ) {
    ... on PutEventDetails {
      __typename
      entries {
        errorCode
        errorMessage
        eventId
      }
      failedEntry
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
  }
}
    `;
export const RiListOfferingsDocument = `
    query RiListOfferings($service: String!, $accountId: String!, $region: String!, $instanceFamily: String!, $instanceSize: String!, $productDescription: String!, $duration: Int, $offeringClass: String, $offeringType: String, $includeMarketplace: Boolean, $multiAZ: Boolean) {
  riListOfferingsQuery(
    params: {service: $service, accountId: $accountId, region: $region, filter: {instanceFamily: $instanceFamily, instanceSize: $instanceSize, productDescription: $productDescription, duration: $duration, offeringClass: $offeringClass, offeringType: $offeringType, includeMarketplace: $includeMarketplace, multiAZ: $multiAZ}}
  ) {
    ... on RIOfferingsEC2Result {
      __typename
      items {
        currencyCode
        duration
        fixedPrice
        instanceTenancy
        instanceType
        marketplace
        offeringClass
        offeringType
        productDescription
        recurringCharges {
          amount
          frequency
        }
        reservedInstancesOfferingId
        scope
        usagePrice
      }
    }
    ... on RIOfferingsRDSResult {
      __typename
      items {
        currencyCode
        dbInstanceClass
        duration
        fixedPrice
        multiAZ
        offeringType
        productDescription
        reservedDBInstancesOfferingId
        usagePrice
        recurringCharges {
          amount
          frequency
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const BuyReservationDocument = `
    mutation BuyReservation($accountId: String!, $purchases: [RIPurchaseReservation!]!) {
  buyReservation(params: {accountId: $accountId, purchases: $purchases}) {
    ... on ReservedInstancePurchaseResult {
      __typename
      items {
        dueDate
        offeringId
        purchaseOrderId
        status
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
  }
}
    `;
export const RiPurchasePermissionDocument = `
    query RiPurchasePermission($service: String!, $accountId: String!, $region: String!, $action: RIAction!) {
  riPurchasePermissionQuery(
    params: {service: $service, accountId: $accountId, region: $region, action: $action}
  ) {
    __typename
    ... on RIPurchasePermissionResult {
      allowed
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const EbsVolumeDocument = `
    query EbsVolume($accountId: String!, $startDate: String!, $endDate: String!) {
  resourceQuery(
    params: {accountId: $accountId, code: EBS_VOLUME, filter: {endDate: $endDate, startDate: $startDate}}
  ) {
    ... on ResourceQueryResult {
      __typename
      items {
        accountId
        region
        resource {
          ... on EbsVolume {
            __typename
            volumeId
            size
          }
        }
      }
    }
  }
}
    `;
export const RecommendationEstimatedSavingsDocument = `
    query RecommendationEstimatedSavings($accountId: String!, $code: String, $date_range_from: String!) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_recommendations_v1", parameters: ["{accountId}", $code, $date_range_from]}
  ) {
    __typename
    ... on DataTableResult {
      table(columns: ["billing_period_start", "status", "saving"]) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const Ec2ReservationsDocument = `
    query Ec2Reservations($accountId: String!, $sizeFlexibilityGroup: String!) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_ec2_usage_ownership_v1", parameters: ["{accountId}", $sizeFlexibilityGroup]}
  ) {
    __typename
    ... on DataTableResult {
      table(
        columns: ["timestamp", "instance_family", "reserved_capacity", "pre_installed_sw", "region", "license_model", "on_demand_usage", "reserved_usage", "sp_usage"]
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const RdsReservationsDocument = `
    query RdsReservations($accountId: String!, $instanceFamily: String!, $region: String!, $databaseEngine: String!, $databaseEdition: String!, $licenseModel: String!) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_rds_usage_ownership_v1", parameters: ["{accountId}", $instanceFamily, $region, $databaseEngine, $databaseEdition, $licenseModel]}
  ) {
    __typename
    ... on DataTableResult {
      table(
        columns: ["timestamp", "instance_family", "reserved_capacity", "database_engine", "database_edition", "region", "license_model", "on_demand_usage", "reserved_usage"]
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const RiPurchasesDocument = `
    query RiPurchases($accountId: String!, $region: String, $service: String, $status: String, $limit: Int, $nextToken: String) {
  riPurchasesQuery(
    params: {accountId: $accountId, region: $region, service: $service, status: $status, limit: $limit, nextToken: $nextToken}
  ) {
    __typename
    ... on RIPurchasesResult {
      items {
        id
        accountId
        awsAccountId
        createdAt
        purchasedAt
        purchasePlannedAt
        revision
        status
        updatedAt
        details {
          __typename
          ... on RIResult {
            start
            end
            multiAZ
            currencyCode
            duration
            retentionDate
            scope
            state
            region
            service
            tags {
              key
              value
            }
            updatedAt
            instance {
              count
              tenancy
              type
              product
            }
            price {
              fixed
              usage
              recurring {
                amount
                frequency
              }
            }
            offering {
              class
              type
            }
          }
        }
      }
      nextToken
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
  }
}
    `;
export const ReservedInstancesByCodeDocument = `
    query ReservedInstancesByCode($accountId: String!, $code: ResourceCode!) {
  resourceQuery(params: {accountId: $accountId, code: $code}) {
    ... on ResourceQueryResult {
      __typename
      nextToken
      items {
        accountId
        region
        resource {
          ... on RIResult {
            id
            currencyCode
            duration
            end
            instance {
              count
              product
              tenancy
              type
            }
            offering {
              class
              type
            }
            price {
              fixed
              usage
              recurring {
                amount
                frequency
              }
              usage
            }
            retentionDate
            scope
            start
            state
            tags {
              key
              value
            }
            updatedAt
            multiAZ
          }
        }
      }
    }
  }
}
    `;
export const OrganizationResourceDocument = `
    query OrganizationResource($accountId: String!) {
  resourceQuery(
    params: {accountId: $accountId, code: ORGANIZATION_RESOURCE, filter: {orgResourceTypes: [ACCOUNT]}}
  ) {
    ... on ResourceQueryResult {
      __typename
      nextToken
      items {
        resource {
          ... on AccountValue {
            id
            name
            probeErrors
            probeStatus
            tags {
              key
              value
            }
            type
            updatedAt
          }
        }
      }
    }
  }
}
    `;
export const CancelRiReservationDocument = `
    mutation CancelRiReservation($accountId: String!, $deletions: [RIDeleteReservation!]!) {
  deleteQueuedReservation(params: {accountId: $accountId, deletions: $deletions}) {
    ... on ReservedInstanceDeleteResult {
      __typename
      items {
        purchaseOrderId
        status
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
  }
}
    `;
export const RioTableDocument = `
    query RioTable($accountId: String!, $code: String!, $dataViewName: String, $columns: [String!]!, $orderColumns: [String!]!, $order: Order!, $withLink: Boolean!, $limit: Int) {
  testDataTableQuery(
    params: {accountId: $accountId, code: $code, data_view_name: $dataViewName}
  ) {
    ... on DataTableResult {
      link @include(if: $withLink)
      table(
        columns: $columns
        orderColumns: $orderColumns
        order: $order
        limit: $limit
      ) {
        columns
        dataTypes
        data
      }
    }
  }
}
    `;
export const UpdateAccountSettingsDocument = `
    mutation UpdateAccountSettings($accountId: String!, $accounts: [AwsAccountParameters]) {
  updateSettings(params: {accountId: $accountId, accounts: $accounts}) {
    ... on PutEventDetails {
      entries {
        ... on EventDetails {
          eventId
        }
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
  }
}
    `;
export const UpdateRecommendationsDocument = `
    mutation UpdateRecommendations($accountId: String!, $recommendations: [RecommendationSettings]) {
  updateSettings(
    params: {accountId: $accountId, recommendations: $recommendations}
  ) {
    ... on PutEventDetails {
      entries {
        ... on EventDetails {
          eventId
        }
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
  }
}
    `;
export const HistoricalSpendByEc2ProcessorDocument = `
    query HistoricalSpendByEc2Processor($accountId: String!, $billingPeriodStart: String!) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_ec2_processor_spend_v1", parameters: ["{accountId}", $billingPeriodStart]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(columns: ["billing_period_start", "processor", "sum_cost"]) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const Ec2ProcessorUsageDocument = `
    query Ec2ProcessorUsage($accountId: String!) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_ec2_processor_usage_v1", parameters: ["{accountId}"]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(
        columns: ["processor", "sum_normalized_usage_quantity", "percentage", "sum_cost", "effective_hourly_rate"]
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsAccountsDocument = `
    query AnalyticsAccounts($accountId: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_accounts_v1", parameters: ["{accountId}", $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(
        columns: ["account_name", "account_id", "cost_period_one", "cost_period_two", "cost_change", "charges_period_one", "credit_period_one", "tax_period_one", "charges_period_two", "credit_period_two", "tax_period_two"]
        limit: $limit
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsAccountServicesDocument = `
    query AnalyticsAccountServices($accountId: String!, $linkedAccountId: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_account_services_v1", parameters: ["{accountId}", $linkedAccountId, $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(
        columns: ["product_code", "product_name", "cost_period_one", "cost_period_two", "cost_change", "charges_period_one", "credit_period_one", "tax_period_one", "charges_period_two", "credit_period_two", "tax_period_two"]
        limit: $limit
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsAccountServicesUsageDocument = `
    query AnalyticsAccountServicesUsage($accountId: String!, $linkedAccountId: String!, $productCode: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_account_services_usage_v1", parameters: ["{accountId}", $linkedAccountId, $productCode, $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(
        columns: ["usage_type", "cost_period_one", "cost_period_two", "cost_change", "charges_period_one", "credit_period_one", "tax_period_one", "charges_period_two", "credit_period_two", "tax_period_two"]
        limit: $limit
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsChartAccountsDocument = `
    query AnalyticsChartAccounts($accountId: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_chart_accounts_v1", parameters: ["{accountId}", $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(columns: ["date", "cost_period_one", "cost_period_two"], limit: $limit) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsChartAccountServicesDocument = `
    query AnalyticsChartAccountServices($accountId: String!, $linkedAccountId: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_chart_account_services_v1", parameters: ["{accountId}", $linkedAccountId, $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(columns: ["date", "cost_period_one", "cost_period_two"], limit: $limit) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsChartAccountServicesUsageDocument = `
    query AnalyticsChartAccountServicesUsage($accountId: String!, $linkedAccountId: String!, $productCode: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_chart_account_services_usage_v1", parameters: ["{accountId}", $linkedAccountId, $productCode, $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(columns: ["date", "cost_period_one", "cost_period_two"], limit: $limit) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsTagsServicesDocument = `
    query AnalyticsTagsServices($accountId: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $tagName: String!, $tagValues: String, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_tags_services_v1", parameters: ["{accountId}", $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd, $tagName, $tagValues]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(
        columns: ["product_code", "product_name", "cost_period_one", "cost_period_two", "cost_change", "charges_period_one", "credit_period_one", "tax_period_one", "charges_period_two", "credit_period_two", "tax_period_two"]
        limit: $limit
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsTagsServicesUsageDocument = `
    query AnalyticsTagsServicesUsage($accountId: String!, $productCode: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $tagName: String!, $tagValues: String, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_tags_services_usage_v1", parameters: ["{accountId}", $productCode, $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd, $tagName, $tagValues]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(
        columns: ["usage_type", "cost_period_one", "cost_period_two", "cost_change", "charges_period_one", "credit_period_one", "tax_period_one", "charges_period_two", "credit_period_two", "tax_period_two"]
        limit: $limit
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsTagsDocument = `
    query AnalyticsTags($accountId: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_tags_v1", parameters: ["{accountId}", $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(columns: ["tag_name", "tag_count"]) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsTagValuesDocument = `
    query AnalyticsTagValues($accountId: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $tagName: String!, $offset: String!, $limit: String!, $responseLimit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_tag_values_v1", parameters: ["{accountId}", $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd, $tagName, $offset, $limit]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(columns: ["tag_value"], limit: $responseLimit) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const OptimizationsDocument = `
    query Optimizations($accountId: String!) {
  optimizationsQuery(params: {accountId: $accountId}) {
    ... on OptimizationsResult {
      __typename
      summary {
        recsBreaching
        recsCancelled
        recsNonBreaching
        recsTotal
        estimatedSavings {
          category
          count
          saving
        }
      }
      tests {
        breaching
        category
        code
        saving
        testSummary {
          type
          value
          tooltip {
            data
            types
          }
        }
        recommendations {
          breaching
          code
          effort
          proposedCost
          resourceCost
          saving
          status
        }
      }
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const AnalyticsChartTagsServicesDocument = `
    query AnalyticsChartTagsServices($accountId: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $tagName: String!, $tagValues: String, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_chart_tags_services_v1", parameters: ["{accountId}", $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd, $tagName, $tagValues]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(columns: ["date", "cost_period_one", "cost_period_two"], limit: $limit) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsChartTagsServicesUsageDocument = `
    query AnalyticsChartTagsServicesUsage($accountId: String!, $productCode: String!, $periodOneStart: String!, $periodOneEnd: String!, $periodTwoStart: String!, $periodTwoEnd: String!, $tagName: String!, $tagValues: String, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_chart_tags_services_usage_v1", parameters: ["{accountId}", $productCode, $periodOneStart, $periodOneEnd, $periodTwoStart, $periodTwoEnd, $tagName, $tagValues]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(columns: ["date", "cost_period_one", "cost_period_two"], limit: $limit) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsDrawerAccountsDocument = `
    query AnalyticsDrawerAccounts($accountId: String!, $periodStart: String!, $periodEnd: String!, $granularity: String!, $selectionIds: String, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_drawer_accounts_v1", parameters: ["{accountId}", $periodStart, $periodEnd, $granularity, $selectionIds]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(
        columns: ["start_date", "account_id", "account_name", "cost"]
        limit: $limit
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsDrawerAccountServicesDocument = `
    query AnalyticsDrawerAccountServices($accountId: String!, $linkedAccountId: String!, $periodStart: String!, $periodEnd: String!, $granularity: String!, $selectionIds: String, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_drawer_account_services_v1", parameters: ["{accountId}", $linkedAccountId, $periodStart, $periodEnd, $granularity, $selectionIds]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(
        columns: ["start_date", "product_code", "product_name", "cost"]
        limit: $limit
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsDrawerAccountServicesUsageDocument = `
    query AnalyticsDrawerAccountServicesUsage($accountId: String!, $linkedAccountId: String!, $productCode: String!, $periodStart: String!, $periodEnd: String!, $granularity: String!, $selectionIds: String, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_drawer_account_services_usage_v1", parameters: ["{accountId}", $linkedAccountId, $productCode, $periodStart, $periodEnd, $granularity, $selectionIds]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(columns: ["start_date", "usage_type", "cost"], limit: $limit) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsDrawerTagsServicesDocument = `
    query AnalyticsDrawerTagsServices($accountId: String!, $periodStart: String!, $periodEnd: String!, $tagName: String!, $tagValues: String, $granularity: String!, $selectionIds: String, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_drawer_tags_services_v1", parameters: ["{accountId}", $periodStart, $periodEnd, $tagName, $tagValues, $granularity, $selectionIds]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(
        columns: ["start_date", "product_code", "product_name", "cost"]
        limit: $limit
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const AnalyticsDrawerTagsServicesUsageDocument = `
    query AnalyticsDrawerTagsServicesUsage($accountId: String!, $productCode: String!, $periodStart: String!, $periodEnd: String!, $tagName: String!, $tagValues: String, $granularity: String!, $selectionIds: String, $limit: Int) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_analytics_drawer_tags_services_usage_v1", parameters: ["{accountId}", $productCode, $periodStart, $periodEnd, $tagName, $tagValues, $granularity, $selectionIds]}
  ) {
    __typename
    ... on DataTableResult {
      __typename
      table(columns: ["start_date", "usage_type", "cost"], limit: $limit) {
        columns
        data
        dataTypes
      }
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
    ... on DeferredQueryResult {
      __typename
    }
  }
}
    `;
export const TestResultsDocument = `
    query TestResults($accountId: String!, $startDate: AWSDate, $testCode: String!) {
  testResultsQuery(
    params: {accountId: $accountId, code: $testCode, startDate: $startDate}
  ) {
    ... on TestResult {
      __typename
      result {
        time
        resultDetails {
          ... on MulticlassBinnedTestResult {
            __typename
            code
            cost
            dataViews {
              columns
              data
              name
              types
            }
          }
        }
      }
    }
    ... on DeferredQueryResult {
      __typename
      hashcode
    }
    ... on ErroredQueryResult {
      __typename
      error
    }
  }
}
    `;
export const SavingsPlanInventoryDocument = `
    query SavingsPlanInventory($accountId: String!, $spType: String!) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_sprio_savingsplans_inventory_v1", parameters: ["{accountId}", $spType]}
  ) {
    __typename
    ... on DataTableResult {
      table(
        columns: ["savings_plan_id", "savings_plan_type", "term_length_years", "account_id", "payment_option", "ec2_instance_family", "ec2_region", "hourly_commitment", "total_commitment", "start_date", "end_date"]
      ) {
        availableColumns
        columns
        data
        dataTypes
      }
    }
    ... on DeferredQueryResult {
      hashcode
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;
export const SavingsPlanUtilizationDocument = `
    query SavingsPlanUtilization($accountId: String!, $savingsPlanId: String!) {
  athenaViewQuery(
    params: {accountId: $accountId, name: "cco_view_sprio_savingsplans_utilization_v1", parameters: ["{accountId}", $savingsPlanId]}
  ) {
    __typename
    ... on DataTableResult {
      table(
        columns: ["savings_plan_id", "usage_day", "used_commitment", "unused_commitment", "utilization", "savings"]
      ) {
        columns
        data
        dataTypes
      }
    }
    ... on DeferredQueryResult {
      hashcode
    }
    ... on ErroredQueryResult {
      error
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ForecastCost: build.query<ForecastCostQuery, ForecastCostQueryVariables>({
      query: (variables) => ({ document: ForecastCostDocument, variables }),
    }),
    SpendByServiceLine: build.query<SpendByServiceLineQuery, SpendByServiceLineQueryVariables>({
      query: (variables) => ({ document: SpendByServiceLineDocument, variables }),
    }),
    UsageCostPerService: build.query<UsageCostPerServiceQuery, UsageCostPerServiceQueryVariables>({
      query: (variables) => ({ document: UsageCostPerServiceDocument, variables }),
    }),
    UsageCostPerRecordType: build.query<UsageCostPerRecordTypeQuery, UsageCostPerRecordTypeQueryVariables>({
      query: (variables) => ({ document: UsageCostPerRecordTypeDocument, variables }),
    }),
    UsageCostPerAccount: build.query<UsageCostPerAccountQuery, UsageCostPerAccountQueryVariables>({
      query: (variables) => ({ document: UsageCostPerAccountDocument, variables }),
    }),
    OrgHierarchy: build.query<OrgHierarchyQuery, OrgHierarchyQueryVariables>({
      query: (variables) => ({ document: OrgHierarchyDocument, variables }),
    }),
    ComputeUsageByProduct: build.query<ComputeUsageByProductQuery, ComputeUsageByProductQueryVariables>({
      query: (variables) => ({ document: ComputeUsageByProductDocument, variables }),
    }),
    LatestOptimizationTests: build.query<LatestOptimizationTestsQuery, LatestOptimizationTestsQueryVariables>({
      query: (variables) => ({ document: LatestOptimizationTestsDocument, variables }),
    }),
    LatestOptimizationCheck: build.query<LatestOptimizationCheckQuery, LatestOptimizationCheckQueryVariables>({
      query: (variables) => ({ document: LatestOptimizationCheckDocument, variables }),
    }),
    OptimizationRecommendations: build.query<
      OptimizationRecommendationsQuery,
      OptimizationRecommendationsQueryVariables
    >({
      query: (variables) => ({ document: OptimizationRecommendationsDocument, variables }),
    }),
    OptimizationCheck: build.query<OptimizationCheckQuery, OptimizationCheckQueryVariables>({
      query: (variables) => ({ document: OptimizationCheckDocument, variables }),
    }),
    CloudIntegration: build.query<CloudIntegrationQuery, CloudIntegrationQueryVariables>({
      query: (variables) => ({ document: CloudIntegrationDocument, variables }),
    }),
    PriceList: build.query<PriceListQuery, PriceListQueryVariables>({
      query: (variables) => ({ document: PriceListDocument, variables }),
    }),
    RefreshOrganizationsDataSource: build.mutation<
      RefreshOrganizationsDataSourceMutation,
      RefreshOrganizationsDataSourceMutationVariables
    >({
      query: (variables) => ({ document: RefreshOrganizationsDataSourceDocument, variables }),
    }),
    RiListOfferings: build.query<RiListOfferingsQuery, RiListOfferingsQueryVariables>({
      query: (variables) => ({ document: RiListOfferingsDocument, variables }),
    }),
    BuyReservation: build.mutation<BuyReservationMutation, BuyReservationMutationVariables>({
      query: (variables) => ({ document: BuyReservationDocument, variables }),
    }),
    RiPurchasePermission: build.query<RiPurchasePermissionQuery, RiPurchasePermissionQueryVariables>({
      query: (variables) => ({ document: RiPurchasePermissionDocument, variables }),
    }),
    EbsVolume: build.query<EbsVolumeQuery, EbsVolumeQueryVariables>({
      query: (variables) => ({ document: EbsVolumeDocument, variables }),
    }),
    RecommendationEstimatedSavings: build.query<
      RecommendationEstimatedSavingsQuery,
      RecommendationEstimatedSavingsQueryVariables
    >({
      query: (variables) => ({ document: RecommendationEstimatedSavingsDocument, variables }),
    }),
    Ec2Reservations: build.query<Ec2ReservationsQuery, Ec2ReservationsQueryVariables>({
      query: (variables) => ({ document: Ec2ReservationsDocument, variables }),
    }),
    RdsReservations: build.query<RdsReservationsQuery, RdsReservationsQueryVariables>({
      query: (variables) => ({ document: RdsReservationsDocument, variables }),
    }),
    RiPurchases: build.query<RiPurchasesQuery, RiPurchasesQueryVariables>({
      query: (variables) => ({ document: RiPurchasesDocument, variables }),
    }),
    ReservedInstancesByCode: build.query<ReservedInstancesByCodeQuery, ReservedInstancesByCodeQueryVariables>({
      query: (variables) => ({ document: ReservedInstancesByCodeDocument, variables }),
    }),
    OrganizationResource: build.query<OrganizationResourceQuery, OrganizationResourceQueryVariables>({
      query: (variables) => ({ document: OrganizationResourceDocument, variables }),
    }),
    CancelRiReservation: build.mutation<CancelRiReservationMutation, CancelRiReservationMutationVariables>({
      query: (variables) => ({ document: CancelRiReservationDocument, variables }),
    }),
    RioTable: build.query<RioTableQuery, RioTableQueryVariables>({
      query: (variables) => ({ document: RioTableDocument, variables }),
    }),
    UpdateAccountSettings: build.mutation<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>({
      query: (variables) => ({ document: UpdateAccountSettingsDocument, variables }),
    }),
    UpdateRecommendations: build.mutation<UpdateRecommendationsMutation, UpdateRecommendationsMutationVariables>({
      query: (variables) => ({ document: UpdateRecommendationsDocument, variables }),
    }),
    HistoricalSpendByEc2Processor: build.query<
      HistoricalSpendByEc2ProcessorQuery,
      HistoricalSpendByEc2ProcessorQueryVariables
    >({
      query: (variables) => ({ document: HistoricalSpendByEc2ProcessorDocument, variables }),
    }),
    Ec2ProcessorUsage: build.query<Ec2ProcessorUsageQuery, Ec2ProcessorUsageQueryVariables>({
      query: (variables) => ({ document: Ec2ProcessorUsageDocument, variables }),
    }),
    AnalyticsAccounts: build.query<AnalyticsAccountsQuery, AnalyticsAccountsQueryVariables>({
      query: (variables) => ({ document: AnalyticsAccountsDocument, variables }),
    }),
    AnalyticsAccountServices: build.query<AnalyticsAccountServicesQuery, AnalyticsAccountServicesQueryVariables>({
      query: (variables) => ({ document: AnalyticsAccountServicesDocument, variables }),
    }),
    AnalyticsAccountServicesUsage: build.query<
      AnalyticsAccountServicesUsageQuery,
      AnalyticsAccountServicesUsageQueryVariables
    >({
      query: (variables) => ({ document: AnalyticsAccountServicesUsageDocument, variables }),
    }),
    AnalyticsChartAccounts: build.query<AnalyticsChartAccountsQuery, AnalyticsChartAccountsQueryVariables>({
      query: (variables) => ({ document: AnalyticsChartAccountsDocument, variables }),
    }),
    AnalyticsChartAccountServices: build.query<
      AnalyticsChartAccountServicesQuery,
      AnalyticsChartAccountServicesQueryVariables
    >({
      query: (variables) => ({ document: AnalyticsChartAccountServicesDocument, variables }),
    }),
    AnalyticsChartAccountServicesUsage: build.query<
      AnalyticsChartAccountServicesUsageQuery,
      AnalyticsChartAccountServicesUsageQueryVariables
    >({
      query: (variables) => ({ document: AnalyticsChartAccountServicesUsageDocument, variables }),
    }),
    AnalyticsTagsServices: build.query<AnalyticsTagsServicesQuery, AnalyticsTagsServicesQueryVariables>({
      query: (variables) => ({ document: AnalyticsTagsServicesDocument, variables }),
    }),
    AnalyticsTagsServicesUsage: build.query<AnalyticsTagsServicesUsageQuery, AnalyticsTagsServicesUsageQueryVariables>({
      query: (variables) => ({ document: AnalyticsTagsServicesUsageDocument, variables }),
    }),
    AnalyticsTags: build.query<AnalyticsTagsQuery, AnalyticsTagsQueryVariables>({
      query: (variables) => ({ document: AnalyticsTagsDocument, variables }),
    }),
    AnalyticsTagValues: build.query<AnalyticsTagValuesQuery, AnalyticsTagValuesQueryVariables>({
      query: (variables) => ({ document: AnalyticsTagValuesDocument, variables }),
    }),
    Optimizations: build.query<OptimizationsQuery, OptimizationsQueryVariables>({
      query: (variables) => ({ document: OptimizationsDocument, variables }),
    }),
    AnalyticsChartTagsServices: build.query<AnalyticsChartTagsServicesQuery, AnalyticsChartTagsServicesQueryVariables>({
      query: (variables) => ({ document: AnalyticsChartTagsServicesDocument, variables }),
    }),
    AnalyticsChartTagsServicesUsage: build.query<
      AnalyticsChartTagsServicesUsageQuery,
      AnalyticsChartTagsServicesUsageQueryVariables
    >({
      query: (variables) => ({ document: AnalyticsChartTagsServicesUsageDocument, variables }),
    }),
    AnalyticsDrawerAccounts: build.query<AnalyticsDrawerAccountsQuery, AnalyticsDrawerAccountsQueryVariables>({
      query: (variables) => ({ document: AnalyticsDrawerAccountsDocument, variables }),
    }),
    AnalyticsDrawerAccountServices: build.query<
      AnalyticsDrawerAccountServicesQuery,
      AnalyticsDrawerAccountServicesQueryVariables
    >({
      query: (variables) => ({ document: AnalyticsDrawerAccountServicesDocument, variables }),
    }),
    AnalyticsDrawerAccountServicesUsage: build.query<
      AnalyticsDrawerAccountServicesUsageQuery,
      AnalyticsDrawerAccountServicesUsageQueryVariables
    >({
      query: (variables) => ({ document: AnalyticsDrawerAccountServicesUsageDocument, variables }),
    }),
    AnalyticsDrawerTagsServices: build.query<
      AnalyticsDrawerTagsServicesQuery,
      AnalyticsDrawerTagsServicesQueryVariables
    >({
      query: (variables) => ({ document: AnalyticsDrawerTagsServicesDocument, variables }),
    }),
    AnalyticsDrawerTagsServicesUsage: build.query<
      AnalyticsDrawerTagsServicesUsageQuery,
      AnalyticsDrawerTagsServicesUsageQueryVariables
    >({
      query: (variables) => ({ document: AnalyticsDrawerTagsServicesUsageDocument, variables }),
    }),
    TestResults: build.query<TestResultsQuery, TestResultsQueryVariables>({
      query: (variables) => ({ document: TestResultsDocument, variables }),
    }),
    SavingsPlanInventory: build.query<SavingsPlanInventoryQuery, SavingsPlanInventoryQueryVariables>({
      query: (variables) => ({ document: SavingsPlanInventoryDocument, variables }),
    }),
    SavingsPlanUtilization: build.query<SavingsPlanUtilizationQuery, SavingsPlanUtilizationQueryVariables>({
      query: (variables) => ({ document: SavingsPlanUtilizationDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useForecastCostQuery,
  useLazyForecastCostQuery,
  useSpendByServiceLineQuery,
  useLazySpendByServiceLineQuery,
  useUsageCostPerServiceQuery,
  useLazyUsageCostPerServiceQuery,
  useUsageCostPerRecordTypeQuery,
  useLazyUsageCostPerRecordTypeQuery,
  useUsageCostPerAccountQuery,
  useLazyUsageCostPerAccountQuery,
  useOrgHierarchyQuery,
  useLazyOrgHierarchyQuery,
  useComputeUsageByProductQuery,
  useLazyComputeUsageByProductQuery,
  useLatestOptimizationTestsQuery,
  useLazyLatestOptimizationTestsQuery,
  useLatestOptimizationCheckQuery,
  useLazyLatestOptimizationCheckQuery,
  useOptimizationRecommendationsQuery,
  useLazyOptimizationRecommendationsQuery,
  useOptimizationCheckQuery,
  useLazyOptimizationCheckQuery,
  useCloudIntegrationQuery,
  useLazyCloudIntegrationQuery,
  usePriceListQuery,
  useLazyPriceListQuery,
  useRefreshOrganizationsDataSourceMutation,
  useRiListOfferingsQuery,
  useLazyRiListOfferingsQuery,
  useBuyReservationMutation,
  useRiPurchasePermissionQuery,
  useLazyRiPurchasePermissionQuery,
  useEbsVolumeQuery,
  useLazyEbsVolumeQuery,
  useRecommendationEstimatedSavingsQuery,
  useLazyRecommendationEstimatedSavingsQuery,
  useEc2ReservationsQuery,
  useLazyEc2ReservationsQuery,
  useRdsReservationsQuery,
  useLazyRdsReservationsQuery,
  useRiPurchasesQuery,
  useLazyRiPurchasesQuery,
  useReservedInstancesByCodeQuery,
  useLazyReservedInstancesByCodeQuery,
  useOrganizationResourceQuery,
  useLazyOrganizationResourceQuery,
  useCancelRiReservationMutation,
  useRioTableQuery,
  useLazyRioTableQuery,
  useUpdateAccountSettingsMutation,
  useUpdateRecommendationsMutation,
  useHistoricalSpendByEc2ProcessorQuery,
  useLazyHistoricalSpendByEc2ProcessorQuery,
  useEc2ProcessorUsageQuery,
  useLazyEc2ProcessorUsageQuery,
  useAnalyticsAccountsQuery,
  useLazyAnalyticsAccountsQuery,
  useAnalyticsAccountServicesQuery,
  useLazyAnalyticsAccountServicesQuery,
  useAnalyticsAccountServicesUsageQuery,
  useLazyAnalyticsAccountServicesUsageQuery,
  useAnalyticsChartAccountsQuery,
  useLazyAnalyticsChartAccountsQuery,
  useAnalyticsChartAccountServicesQuery,
  useLazyAnalyticsChartAccountServicesQuery,
  useAnalyticsChartAccountServicesUsageQuery,
  useLazyAnalyticsChartAccountServicesUsageQuery,
  useAnalyticsTagsServicesQuery,
  useLazyAnalyticsTagsServicesQuery,
  useAnalyticsTagsServicesUsageQuery,
  useLazyAnalyticsTagsServicesUsageQuery,
  useAnalyticsTagsQuery,
  useLazyAnalyticsTagsQuery,
  useAnalyticsTagValuesQuery,
  useLazyAnalyticsTagValuesQuery,
  useOptimizationsQuery,
  useLazyOptimizationsQuery,
  useAnalyticsChartTagsServicesQuery,
  useLazyAnalyticsChartTagsServicesQuery,
  useAnalyticsChartTagsServicesUsageQuery,
  useLazyAnalyticsChartTagsServicesUsageQuery,
  useAnalyticsDrawerAccountsQuery,
  useLazyAnalyticsDrawerAccountsQuery,
  useAnalyticsDrawerAccountServicesQuery,
  useLazyAnalyticsDrawerAccountServicesQuery,
  useAnalyticsDrawerAccountServicesUsageQuery,
  useLazyAnalyticsDrawerAccountServicesUsageQuery,
  useAnalyticsDrawerTagsServicesQuery,
  useLazyAnalyticsDrawerTagsServicesQuery,
  useAnalyticsDrawerTagsServicesUsageQuery,
  useLazyAnalyticsDrawerTagsServicesUsageQuery,
  useTestResultsQuery,
  useLazyTestResultsQuery,
  useSavingsPlanInventoryQuery,
  useLazySavingsPlanInventoryQuery,
  useSavingsPlanUtilizationQuery,
  useLazySavingsPlanUtilizationQuery,
} = injectedRtkApi;
