import React from 'react';
import { SageMakerWidget } from './SavingsPlans/widgets/Overview';

const Overview = () => (
  <>
    <SageMakerWidget />
  </>
);

export default Overview;
