import { useListRequestTasksQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { EdgeState, WorkflowState } from '../model/types';
import { parseUserRef, parseUserTaskRef } from '../../../../hooks/workflows/refUtils';
import { useMemo } from 'react';
import { useGetRequestQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useWorkflowPassedTransitions } from './useWorkflowPassedTransitions';

// get task definition id from task instance id
const taskIdToTaskDefinitionId = (taskInstanceId: string) => {
  const lastUnderscoreIndex = taskInstanceId.lastIndexOf('_');
  return taskInstanceId.substring(0, lastUnderscoreIndex);
};

export const useRequestState = (requestId?: string, requestRoute?: string): WorkflowState => {
  const { accountId } = useAccountContext();
  const { data: tasks } = useListRequestTasksQuery({ accountId, requestId: requestId! }, { skip: !requestId });
  const { data: requestData } = useGetRequestQuery(
    {
      accountId,
      requestId: requestId!,
    },
    { skip: !requestId }
  );

  const { passedTransitions } = useWorkflowPassedTransitions(requestId);

  return useMemo(
    () => ({
      requestRoute: requestRoute,
      tasks:
        tasks?.items?.reduce<WorkflowState['tasks']>((acc, task) => {
          const { taskId } = parseUserTaskRef(task.ref);
          const taskDefinitionId = taskIdToTaskDefinitionId(taskId);
          acc[taskDefinitionId] = {
            status: task.status,
            assigneeIds: task.assignment.map((a) => parseUserRef(a).userId),
            closedAt: task.closedAt,
            createdAt: task.createdAt,
            resourceUrn: task.resourceUrn,
            requestRef: task.childRequestRef,
          };
          return acc;
        }, {}) || {},
      edges: passedTransitions.reduce<Record<string, EdgeState>>((acc, edgeId) => {
        return {
          ...acc,
          [`${edgeId}`]: {
            passed: true,
          },
        };
      }, {}),
      parentRequest: requestData?.request?.parentRequestRef
        ? {
            ref: requestData.request.parentRequestRef,
            name: requestData.request.parentRequestName || '',
            route: requestRoute || '',
          }
        : undefined,
    }),
    [requestRoute, tasks?.items, passedTransitions, requestData]
  );
};
