import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import Text from '@verticeone/design-system/src/components/Text';
import Alert, { AlertButton } from '@verticeone/design-system/src/components/Alert';
import Loader from '@verticeone/design-system/src/components/Loader';

import { useFulfillUserTaskMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { parseUserRef, parseUserTaskRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useLoggedUser } from '@verticeone/auth/src';
import TaskDrawerForm from './TaskDrawerForm';
import { FORM_CONFIGURATION_KIND } from './constants';
import { useTaskContext } from './TaskContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import {
  SAAS_APPROVAL_FORM_CONFIG,
  isSaaSApprovalTask,
} from './TaskDrawerForm/predefinedForms/ApprovalSimpleFormSaaS/formDef';
import { useSnackbar } from 'notistack';
import { TaskDrawerFormResult } from './TaskDrawerForm/TaskDrawerFormResult';

type TaskDrawerActionsProps = {
  onSubmit?: () => void;
};

export const TaskDrawerActions = ({ onSubmit }: TaskDrawerActionsProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const { taskOverview } = useTaskContext();

  const [fulfillUserTask] = useFulfillUserTaskMutation();
  const [taskCompletionResult, setTaskCompletionResult] = useState<'SUCCESS' | 'ERROR' | undefined>();

  const handleCompleteTask = async (resultBody: object) => {
    if (taskOverview) {
      const response = await fulfillUserTask({
        accountId,
        taskId: parseUserTaskRef(taskOverview.task.ref).taskId,
        body: resultBody,
      });

      if ('error' in response) {
        setTaskCompletionResult('ERROR');
      } else {
        setTaskCompletionResult('SUCCESS');
      }
    }
  };

  const onTaskCompleted = useCallback(() => {
    enqueueSnackbar(
      t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETED_SUCCESS', { taskName: taskOverview?.task.name }),
      {
        variant: 'success',
      }
    );
  }, [enqueueSnackbar, t, taskOverview]);

  useEffect(() => {
    if (taskCompletionResult === 'SUCCESS') {
      onTaskCompleted();
      onSubmit?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskCompletionResult]);

  const formConfig = isSaaSApprovalTask(taskOverview)
    ? SAAS_APPROVAL_FORM_CONFIG
    : taskOverview?.task.configurations?.find((configuration) => configuration.kind === FORM_CONFIGURATION_KIND);
  const currentUserAssigned = taskOverview?.task.assignment?.some(
    (assignment) => parseUserRef(assignment).userId === userId
  );

  if (!taskOverview) {
    return <Loader size={20} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />;
  }

  const taskCompleted = taskOverview.task.status === 'COMPLETED';
  if (!formConfig || (!currentUserAssigned && !taskCompleted)) {
    return null;
  }

  return (
    <Stack minHeight={0} overflow="hidden" height={'100%'}>
      {taskCompletionResult === 'ERROR' && (
        <Stack p={6}>
          <Alert
            size="S"
            variant="ghost"
            color="error"
            title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETION_FAILED')}
          >
            <AlertButton onClick={() => setTaskCompletionResult(undefined)}>{t('DIALOG.BUTTONS.CLOSE')}</AlertButton>
          </Alert>
        </Stack>
      )}
      {taskCompletionResult === undefined && !taskCompleted && (
        <Stack gap={4} minHeight={0} height="100%">
          <TaskDrawerForm onSubmit={handleCompleteTask} onCancel={() => null} formConfig={formConfig} />
        </Stack>
      )}
      {/*task completed confirmation*/}
      {taskCompletionResult === 'SUCCESS' && !taskCompleted && (
        <Stack gap={4} p={6} justifyContent="center" alignItems="center" minHeight={0}>
          <Text variant="heading" size="M">
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETED_HEADING')}
          </Text>
          <Stack gap={5} alignItems="center">
            <Text variant="body-regular" size="M">
              {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETED_MESSAGE', {
                taskType: t('ENTITIES.WORKFLOW_TASK.NAME'),
              })}
            </Text>
          </Stack>
        </Stack>
      )}
      {taskCompletionResult === undefined && taskCompleted && (
        <Stack gap={4} p={6} alignItems="center" overflow="auto">
          <Text variant="heading" size="M">
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETE_HEADING')}
          </Text>
          <TaskDrawerFormResult formConfig={formConfig} />
        </Stack>
      )}
    </Stack>
  );
};
