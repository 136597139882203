import React from 'react';
import { Grid, Stack } from '@mui/material';
import ConcessionsContractFormEntry from './form/ConcessionsContractFormEntry';
import { useTranslation } from 'react-i18next';
import ContractFormEntry from '../ContractFormEntry';
import FormReadOnlyText from '../../../forms/fields/FormReadOnlyText';
import FormNumberField from '../../../forms/fields/FormNumberField';
import FormTextField from '../../../forms/fields/FormTextField';
import { useAllFieldsValid } from '../hooks/useAllFieldsValid';

export const useIsSavingsBreakdownTabValid = () =>
  useAllFieldsValid([
    'parts.model.cost.configuration.withVertice.model.scope_reduction_savings.amount',
    'parts.model.cost.configuration.withVertice.model.scope_reduction_savings.description',
    'parts.model.cost.configuration.withVertice.model.concessions',
  ]);

const SavingsBreakdownTab = () => {
  const { t } = useTranslation();

  return (
    <Stack p={6} gap={6}>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.model.cost.configuration.withVertice.model.scope_reduction_savings.amount"
            label={t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION_SAVINGS_VALUE')}
            component={FormReadOnlyText}
            componentProps={{ allowsFloat: true }}
            writeComponent={FormNumberField}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.model.cost.configuration.withVertice.model.scope_reduction_savings.description"
            label={t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION_SAVINGS_DESCRIPTION')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
      </Grid>
      <ConcessionsContractFormEntry name="parts.model.cost.configuration.withVertice.model.concessions" width={12} />
    </Stack>
  );
};

export default SavingsBreakdownTab;
