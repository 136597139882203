import { Product, RangePricingBenchmark } from '@vertice/slices/src/openapi/codegen/vendorAPI';
import { Stack } from '@mui/material';
import { isNil, map, every } from 'lodash';
import Alert from '@verticeone/design-system/src/components/Alert';
import { ThumbUpOutlined, WarningAmberOutlined } from '@mui/icons-material';
import React, { useCallback, useMemo } from 'react';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { Trans, useTranslation } from 'react-i18next';
import { BENCHMARKING_CURRENCY } from './constants';
import { BenchmarkingPeriods } from './useGetBenchmarkData';
import assertExhausted from '@vertice/core/src/utils/assertExhausted';

export type AlertsRowProps = {
  accountPricingRangePerTier: Record<string, RangePricingBenchmark>;
  product: Product;
  period: BenchmarkingPeriods;
};

const ALERT_SIZE = 'S';

export const AlertsRow = ({ accountPricingRangePerTier, product, period }: AlertsRowProps) => {
  const { t } = useTranslation();
  const formatPercentage = useFormatPercentage();
  const formatCurrency = useFormatCurrency();

  const periodLabel = useMemo(() => {
    switch (period) {
      case BenchmarkingPeriods.MONTHLY:
        return t('COMMON.MONTH');
      case BenchmarkingPeriods.ANNUALLY:
        return t('COMMON.YEAR');
      default:
        assertExhausted(period);
        return t('PRODUCT.PRICING_BENCHMARK_CARD.TIME_PERIOD');
    }
  }, [period, t]);

  const missingData = useCallback(
    (range: RangePricingBenchmark) => isNil(range.priceAccount) || range.priceMedian === 0,
    []
  );

  if (every(accountPricingRangePerTier, missingData)) {
    return null;
  }

  return (
    <Stack gap={2} direction="row" justifyItems="stretch" sx={{ '> *': { flex: 1 } }}>
      {map(accountPricingRangePerTier, (range, tier) => {
        if (missingData(range)) {
          return null;
        }
        const relativeDiff = Math.abs(range.priceAccount! - range.priceMedian) / range.priceMedian;

        const yourPriceFormatted = formatCurrency(range.priceAccount!, { currency: BENCHMARKING_CURRENCY });
        const commonPriceFormatted = formatCurrency(range.priceMedian, { currency: BENCHMARKING_CURRENCY });
        // Comparing rounded values for equality to avoid floating point issues
        const isYourPriceCommon = yourPriceFormatted === commonPriceFormatted;

        const subtitle = (
          <Trans
            i18nKey={`PRODUCT.PRICING_BENCHMARK_CARD.${
              isYourPriceCommon ? 'FOR_THE_LICENSE' : 'THAN_THE_MOST_COMMON_PRICE'
            }`}
            components={{ b: <strong /> }}
            values={{
              product: product.name,
              tier,
              yourPrice: yourPriceFormatted,
              commonPrice: commonPriceFormatted,
              period: periodLabel,
            }}
          />
        );

        if (isYourPriceCommon) {
          return (
            <Alert
              key={tier}
              color="success"
              title={t('PRODUCT.PRICING_BENCHMARK_CARD.YOU_ARE_PAYING_COMMON_PRICE')}
              subtitle={subtitle}
              icon={ThumbUpOutlined}
              size={ALERT_SIZE}
            />
          );
        }

        return range.priceAccount! > range.priceMedian ? (
          <Alert
            key={tier}
            color="warning"
            title={t('PRODUCT.PRICING_BENCHMARK_CARD.YOU_ARE_PAYING_N_PCT_MORE', {
              percentage: formatPercentage(relativeDiff),
            })}
            subtitle={subtitle}
            icon={WarningAmberOutlined}
            size={ALERT_SIZE}
          />
        ) : (
          <Alert
            key={tier}
            color="success"
            title={t('PRODUCT.PRICING_BENCHMARK_CARD.YOU_ARE_PAYING_N_PCT_LESS', {
              percentage: formatPercentage(relativeDiff),
            })}
            subtitle={subtitle}
            icon={ThumbUpOutlined}
            size={ALERT_SIZE}
          />
        );
      })}
    </Stack>
  );
};
