import React, { FC } from 'react';
import { type GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Tier } from '../types';
import { GridSelect } from '@verticeone/design-system/src/components/DataGrid';
import { useVendorProductsContext } from '../VendorProductsContextProvider';
import { components, MenuListProps } from 'react-select';
import Button from '@verticeone/design-system/src/components/Button/Button';
import { Stack } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import { truncate } from 'lodash';

const MenuList = (props: MenuListProps<Tier, false>) => {
  const { t } = useTranslation();
  const { addTier } = useVendorProductsContext();
  const inputValue = props.selectProps.inputValue;
  const truncatedInputValue = truncate(inputValue.trim(), {
    length: 15,
    omission: '...',
  });
  const notInTheList = !props.selectProps.options.some(
    (option) => (option as Tier).label.toLowerCase() === inputValue.trim().toLowerCase()
  );

  const onAddClick = () => {
    const newTier = addTier(inputValue);
    props.selectOption(newTier);
  };

  return (
    <components.MenuList {...props}>
      {props.children}
      {inputValue && notInTheList && (
        <Button color="primary" variant="plain" size="S" onClick={onAddClick}>
          <Stack direction="row" gap={1} alignItems="center">
            <AddOutlined sx={{ width: 16, height: 16 }} />
            {t('INTAKE_FORM.PRODUCTS_TABLE.ADD_NEW_TIER', { tier: truncatedInputValue })}
          </Stack>
        </Button>
      )}
    </components.MenuList>
  );
};

const EditableTierCell: FC<GridCellParams<any, Tier>> = ({ id, value, field }) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const { tiers, color } = useVendorProductsContext();

  return (
    <GridSelect
      value={value}
      options={tiers}
      isMulti={false}
      isClearable={true}
      placeholder={t('INTAKE_FORM.PRODUCTS_TABLE.TIER')}
      menuPlacement="auto"
      getOptionValue={(option) => (option as Tier).id}
      getOptionLabel={(option) => (option as Tier).label}
      onChange={(newValue) => {
        void apiRef.current.setEditCellValue({ id, field, value: newValue });
      }}
      components={{
        MenuList,
        NoOptionsMessage: () => null,
      }}
      color={color}
    />
  );
};

export default EditableTierCell;
