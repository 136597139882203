import React, { FC, useMemo } from 'react';
import { Stack } from '@mui/material';
import ProductsContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/ProductsContractFormEntry';
import useBenchmarking from '../../vendor/hooks/useBenchmarking';
import { PricingBenchmarkDetailRow } from '../../product/components/PricingBenchmarkDetailRow/PricingBenchmarkDetailRow';
import { useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { ProductItem } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';

type ProductsTabProps = {
  showAnnualCost?: boolean;
};

const ProductsTab: FC<ProductsTabProps> = ({ showAnnualCost }) => {
  const { benchmarkingStage2 } = useBenchmarking();
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const vendorId = contractContext.fetchedContract.contract.parts.contractual?.vendor?.vendorId;

  const benchmarkingDetailRowRenderer = useMemo(
    () =>
      benchmarkingStage2 && vendorId
        ? ({ row }: GridRowParams<ProductItem>) => (
            <PricingBenchmarkDetailRow vendorId={vendorId} productId={row.productId} />
          )
        : undefined,
    [benchmarkingStage2, vendorId]
  );

  return (
    <Stack p={6}>
      <ProductsContractFormEntry
        showAnnualCost={showAnnualCost}
        detailRowRenderer={benchmarkingDetailRowRenderer}
        name="parts.contractual.products"
        width={12}
      />
    </Stack>
  );
};

export default ProductsTab;
