import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Grid } from '@mui/material';
import Card, { CardHeader, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system/src/components/Card';
import { useListVendorProductsQuery } from '@vertice/slices';
import SingleProductCard from './SingleProductCard';
import SingleProductCardSkeleton from './SingleProductCardSkeleton';
import useBenchmarking from '../../hooks/useBenchmarking';
import { RequestBenchmarkingDataButton } from '../../buttons/RequestBenchmarkingDataButton';

export type ProductsPricingCardProps = {
  vendorId: string;
};

const getLayoutBreakpoints = (numberOfProducts: number) => {
  switch (numberOfProducts) {
    case 1:
      return { xs: 12 };
    case 2:
      return { xs: 12, sm: 6 };
    case 3:
      return { xs: 12, sm: 6, md: 4 };
    default:
      return { xs: 12, sm: 6, md: 4, xl: 3 };
  }
};

const ProductsPricingCard = ({ vendorId }: ProductsPricingCardProps) => {
  const { t } = useTranslation();
  const { benchmarkingStage0 } = useBenchmarking();

  const { data: vendorData, isLoading } = useListVendorProductsQuery(
    {
      vendorId,
      benchmarkedOnly: true,
    },
    { skip: !benchmarkingStage0 }
  );
  const numberOfProducts = vendorData?.products?.length ?? 0;

  if (!benchmarkingStage0) {
    return null;
  }

  return (
    <Collapse in={numberOfProducts > 0}>
      <Card minWidth={374}>
        <CardHeader size="S" noDivider>
          <CardHeaderTitle text={t('VENDOR.PRODUCTS_PRICING.TITLE')} />
          <CardHeaderActions>
            <RequestBenchmarkingDataButton vendorId={vendorId} />
          </CardHeaderActions>
        </CardHeader>
        {isLoading ? (
          <Grid container spacing={4} px={6} pb={6}>
            {[1, 2, 3].map((key) => (
              <Grid item key={key} minHeight="150px" {...getLayoutBreakpoints(3)}>
                <SingleProductCardSkeleton />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={4} px={6} pb={6}>
            {vendorData?.products?.map((product) => (
              <Grid item key={product.id} minHeight="150px" {...getLayoutBreakpoints(numberOfProducts)}>
                <SingleProductCard vendorId={vendorId} product={product} />
              </Grid>
            ))}
          </Grid>
        )}
      </Card>
    </Collapse>
  );
};

export default ProductsPricingCard;
