import React from 'react';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAccount } from '@vertice/slices/src/slices/account';
import {
  MonitoringValue,
  useLatestOptimizationTestsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import Loader from '@verticeone/design-system/src/components/Loader';
import ToggleSwitch from '@verticeone/design-system/src/components/ToggleSwitch';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { OptimizeInstanceCard } from '@vertice/dashboard/src/modules/cloud/cards/OptimizeInstanceCard/OptimizeInstanceCard';
import EmptyStateError from '@vertice/dashboard/src/modules/cloud/components/EmptyStateError';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import Text from '@verticeone/design-system/src/components/Text';

import { TEST_CODE } from './constants';
import { productKeyToTitleMap } from '../../utils';
import { useEC2Data } from './instances/products/EC2/dataSource';
import { useRDSData } from './instances/products/RDS/dataSource';
import { CardsData } from './types';
import {
  calculateRDSRICoverage,
  calculateMonitoringValuesCoverage,
  sortCardsData,
  calculateEC2RICoverage,
} from './utils';

const CloudOptimizeTab = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE' });
  const { accountId } = useSelector(getAccount);
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const [showHiddenProducts, setShowHiddenProducts] = React.useState(false);
  const { data: rawData, isLoading } = useLatestOptimizationTestsQuery({
    accountId: accountId!,
  });

  const { data: ec2RICoverageData, isFetching: isEc2RiCoverageLoading } = useEC2Data();
  const { data: rdsRICoverageData, isFetching: isRDSRiCoverageLoading } = useRDSData();

  const navigateToDetail = (product: string) => {
    navigate(routes.CLOUD.RIO.OPTIMIZE.DETAIL, { product });
  };

  if (isLoading || isEc2RiCoverageLoading || isRDSRiCoverageLoading) {
    return <Loader color={AWS_BRAND_COLOR} />;
  }

  if (rawData?.monitoringLatestQuery?.__typename !== 'MonitoringResult') {
    return <EmptyStateError />;
  }

  const monitoringItems = rawData?.monitoringLatestQuery?.items;

  const redShiftData = monitoringItems?.find((test) => test.code === TEST_CODE.REDSHIFT.test);
  // TODO: replace with real data once implemented
  //const elcData = monitoringItems?.find((test) => test.code === TEST_CODE.ELC.test);
  //const ossData = monitoringItems?.find((test) => test.code === TEST_CODE.OSS.test);
  const elcData = monitoringItems?.find((test) => test.code === 'some code');
  const ossData = monitoringItems?.find((test) => test.code === 'some other code');

  const cardsData: CardsData[] = [
    {
      title: productKeyToTitleMap.ec2,
      data: ec2RICoverageData,
      onNavigate: () => navigateToDetail('ec2'),
      calculatedCoverage: calculateEC2RICoverage(ec2RICoverageData),
    },
    {
      title: productKeyToTitleMap.rds,
      data: rdsRICoverageData,
      onNavigate: () => navigateToDetail('rds'),
      calculatedCoverage: calculateRDSRICoverage(rdsRICoverageData),
    },
    {
      title: productKeyToTitleMap.redshift,
      disabled: true,
      onNavigate: () => navigateToDetail('redshift'),
      calculatedCoverage: calculateMonitoringValuesCoverage(redShiftData as MonitoringValue),
    },
    {
      title: productKeyToTitleMap.elc,
      disabled: true,
      onNavigate: () => navigateToDetail('elc'),
      calculatedCoverage: calculateMonitoringValuesCoverage(elcData as MonitoringValue),
    },
    {
      title: productKeyToTitleMap.oss,
      disabled: true,
      onNavigate: () => navigateToDetail('oss'),
      calculatedCoverage: calculateMonitoringValuesCoverage(ossData as MonitoringValue),
    },
  ].sort(sortCardsData);

  return (
    <Stack gap={8}>
      <Stack direction="row" gap={2} alignItems="center" justifyContent="right">
        <ToggleSwitch
          id="show-hidden-products"
          size="S"
          color="tertiary"
          checked={showHiddenProducts}
          onChange={() => setShowHiddenProducts((prev) => !prev)}
        />
        <Text variant="body-regular" tag="label" htmlFor="show-hidden-products">
          {t('SHOW_HIDDEN_PRODUCTS')}
        </Text>
      </Stack>
      <Stack gap={4}>
        {cardsData.map(({ title, calculatedCoverage, disabled, onNavigate }) => (
          <OptimizeInstanceCard
            key={title}
            title={title}
            disabled={disabled}
            onNavigate={onNavigate}
            calculatedCoverage={calculatedCoverage}
            showHiddenProducts={showHiddenProducts}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default CloudOptimizeTab;
