import { Stack } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import {
  GridRenderCellParams,
  GridRowId,
  useGridApiContext,
  GridExpandMoreIcon,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro';

import { RequestRow } from '../../types';
import { ExpandLess } from '@mui/icons-material';
import { LegacyRequestNameCell } from './LegacyRequestNameCell';
import { RequestLogo } from './RequestLogo';
import { AvatarStack } from '@verticeone/design-system/src/components/Avatar';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import { useUsersContext } from '@vertice/core/src/contexts/UsersContext';
import TwoRowCell from './TwoRowCell';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';
import { useTranslation } from 'react-i18next';

export const ParentGroupingCell: FC<GridRenderCellParams<RequestRow>> = ({ id, field, rowNode, row }) => {
  const isChild = rowNode.depth !== 0;

  return (
    <Stack direction="row" gap={1} alignItems="center" position="relative" width="-webkit-fill-available">
      <Stack position="absolute" left={0} width={20}>
        <ExpandIcon id={id} field={field} rowNode={rowNode} />
      </Stack>
      <Stack direction="row" gap={2} alignItems="center" sx={{ paddingLeft: 6 }} maxWidth="100%" width="100%">
        <RequestIcon row={row} isChild={isChild} />
        {row.isLegacy ? (
          <Stack direction="column" maxWidth="calc(100% - 50px)" width="100%">
            <LegacyRequestNameCell name={row.requestName} />
          </Stack>
        ) : (
          <TwoRowCell variant="heading" size="XS" title={row.requestName!} subtitle={row.requestType} />
        )}
      </Stack>
    </Stack>
  );
};

const RequestIcon: FC<{ row: RequestRow; isChild: boolean }> = ({ row, isChild }) => {
  const { getFullName } = useUsersContext();
  const { t } = useTranslation();

  const avatarItem = useMemo(() => {
    if (!row.ownerId || isChild) {
      return undefined;
    }

    return {
      personId: row.ownerId,
      personName: getFullName(row.ownerId),
      color: 'neutral' as const,
    };
  }, [isChild, row.ownerId, getFullName]);

  return (
    <Stack
      width={50}
      height={50}
      direction="row"
      sx={{
        position: 'relative',
        marginTop: isChild ? 1.5 : undefined,
        justifyContent: isChild ? 'end' : undefined,
      }}
    >
      <RequestLogo vendor={row.vendor} isChild={isChild} />
      {avatarItem && (
        <Tooltip
          size="S"
          placement="bottom"
          title={t('ENTITIES.CONTRACT.LABELS.REQUEST_OWNER')}
          content={avatarItem.personName}
        >
          <Stack sx={{ position: 'absolute', right: 0, bottom: 0 }}>
            <AvatarStack items={[avatarItem]} size="XXS" disableTooltip />
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
};

const ExpandIcon: FC<{ id: GridRowId; field: string; rowNode: GridTreeNodeWithRender }> = ({ id, field, rowNode }) => {
  const apiRef = useGridApiContext();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      if (rowNode.type !== 'group') {
        return;
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
      event.preventDefault();
    },
    [id, field, rowNode, apiRef]
  );

  if (rowNode.type !== 'group') {
    return null;
  }

  return (
    <IconButton
      onClick={handleClick}
      size="XXS"
      variant="plain"
      sx={{ zIndex: 50 }}
      icon={rowNode.childrenExpanded ? ExpandLess : GridExpandMoreIcon}
    />
  );
};
