import { Stack } from '@mui/material';
import { AvatarStack } from '@verticeone/design-system/src/components/Avatar';
import { useUsersContext } from '../../../../../../contexts/UsersContext';
import { parseUserRef } from '../../../../../../hooks/workflows/refUtils';
import Tooltip from '@verticeone/design-system/src/components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';

type AssigneesCellProps = {
  assignees?: string[];
};

export const AssigneesCell = ({ assignees }: AssigneesCellProps) => {
  const { getFullName } = useUsersContext();
  const { t } = useTranslation();

  const parsedAssignees = assignees?.map((assignee) => parseUserRef(assignee).userId);

  if (!parsedAssignees || parsedAssignees.length === 0) return '-';

  return (
    <Stack>
      <Tooltip
        title={t('ENTITIES.WORKFLOW_REQUEST.LABELS.ASSIGNEE_COUNT', { count: parsedAssignees.length })}
        size="S"
        placement="bottom"
        content={
          <Stack direction="column">
            {parsedAssignees.map((assignee) => (
              <span key={assignee}>{getFullName(assignee)}</span>
            ))}
          </Stack>
        }
      >
        <Stack width="fit-content">
          <AvatarStack
            size="S"
            shape="circle"
            maxItems={3}
            disableTooltip
            items={parsedAssignees.map((assignee) => ({
              personId: assignee,
              personName: getFullName(assignee),
              color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
            }))}
          />
        </Stack>
      </Tooltip>
    </Stack>
  );
};
