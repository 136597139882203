import React from 'react';
import { Value } from '@vertice/core/src/components/StatsBar';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';

type LoadingOrValueProps = {
  isLoading: boolean;
  value: React.ReactNode;
  height?: number;
};

const LoadingOrValue = ({ isLoading, value, height = 28 }: LoadingOrValueProps) => (
  <Value>{isLoading ? <Placeholder width="80px" height={height} /> : value}</Value>
);

export default LoadingOrValue;
