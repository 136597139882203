import React from 'react';
import { CardHeaderDescription, CardHeaderDescriptionProps } from '../Card/CardHeaderDescription';
import { useDrawer } from './DrawerContext';
import Placeholder from '../Placeholder';

const DrawerHeaderDescription = ({ children, ...otherProps }: CardHeaderDescriptionProps) => {
  const { isLoading } = useDrawer();

  if (isLoading) {
    return <Placeholder width="calc(100% - 50px)" />;
  }

  return <CardHeaderDescription {...otherProps}>{children}</CardHeaderDescription>;
};

export default DrawerHeaderDescription;
