import { addDays, format, startOfDay } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { type TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { DATE_FORMAT, getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

export type SummaryDataColumns = 'ewma_usage';
export type DailyDataColumns = 'timestamp' | 'sp_covered_cost' | 'on_demand_cost';

const FUTURE_PERIOD = 5;

const SPRIOOptimizeGraphQuery = graphql(`
  query SageMakerSPRIODaily($accountId: String!) {
    summaryQuery: athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_sprio_sage_maker_summary_v1", parameters: ["{accountId}"] }
    ) {
      __typename
      ... on DataTableResult {
        table(columns: ["ewma_usage"]) {
          columns
          data
          dataTypes
        }
      }
      ... on ErroredQueryResult {
        error
      }
      ... on DeferredQueryResult {
        __typename
      }
    }
    dailyUsageQuery: athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_sprio_sage_maker_daily_usage_v1", parameters: ["{accountId}"] }
    ) {
      __typename
      ... on DataTableResult {
        table(columns: ["timestamp", "sp_covered_cost", "on_demand_cost"]) {
          columns
          data
          dataTypes
        }
      }
      ... on ErroredQueryResult {
        error
      }
      ... on DeferredQueryResult {
        __typename
      }
    }
  }
`);

export const useGraphData = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['SPRIOOptimizeGraph'],
    queryFn: () => fetchCloudOptimization(SPRIOOptimizeGraphQuery, { accountId }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return [data.state.data?.dailyUsageQuery?.__typename, data.state.data?.summaryQuery?.__typename].includes(
        'DeferredQueryResult'
      )
        ? 2000
        : false;
    },
    select: ({ summaryQuery, dailyUsageQuery }) => {
      if (summaryQuery?.__typename === 'DataTableResult' && dailyUsageQuery?.__typename === 'DataTableResult') {
        const { ewma_usage } = getTableData(summaryQuery.table as TableType)?.[0] as Record<SummaryDataColumns, number>;
        const daily = getTableData(dailyUsageQuery.table as TableType) as Array<Record<DailyDataColumns, number>>;

        const presenceDays = daily?.map(({ timestamp, sp_covered_cost, on_demand_cost }) => ({
          date: format(new Date(timestamp), DATE_FORMAT),
          spCoveredUsage: sp_covered_cost,
          onDemandUsage: on_demand_cost,
          averageUsage: ewma_usage,
          spPurchaseRecommendation: null,
        }));

        const futureDays = Array.from({ length: FUTURE_PERIOD }, (_, i) => ({
          spCoveredUsage: null,
          onDemandUsage: null,
          averageUsage: null,
          date: format(startOfDay(addDays(new Date(), +i)), DATE_FORMAT),
          spPurchaseRecommendation: ewma_usage,
        }));

        return [...presenceDays, ...futureDays];
      }

      return null;
    },
  });
};
