import { Request, useListRequestsQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { Request as LegacyRequest } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isRequestRef, parseRequestRef, parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { RequestRow } from './types';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useCallback, useMemo } from 'react';
import { useListRequestsQuery as useListRequestsLegacyQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { getDataForWidget } from '../RequestDetailPage/widgets/shared/utils';
import { DETAILS_WIDGET_URN_PATTERN } from '../RequestDetailPage/widgets/Details/widgetDef';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';

export const addRequestsRows = (items: Request[]): RequestRow[] => {
  return items.map((request) => {
    const { requestId } = parseRequestRef(request.ref);
    const parentRequestId = request.parentRequestRef ? parseRequestRef(request.parentRequestRef).requestId : undefined;
    const vendor = getDataForWidget(request, DETAILS_WIDGET_URN_PATTERN)?.vendor ?? request.verticeReserved?.vendor;

    return {
      requestId: requestId,
      requestName: request.name,
      requestType: request.serviceName,
      resultType: request.resultType,
      createdAt: request.createdAt,
      lastActivityAt: request.updatedAt,
      closedAt: request.closedAt,
      status: request.status,
      parentId: parentRequestId,
      vendor: vendor,
      ownerId: request.owner ? parseUserRef(request.owner).userId : undefined,
      activeTasks: request.tasks
        ?.filter(({ status }) => status === 'ACTIVE')
        .sort(({ name: a }, { name: b }) => a.localeCompare(b)),
      completedTasks: request.tasks?.filter(({ status }) => status === 'COMPLETED').length,
      taskAssignees: request.taskAssignment,
      tasks: request.tasks,
    };
  });
};

const addLegacyRequestRows = (items: LegacyRequest[]) =>
  items.map((request) => {
    const { requestId } = parseRequestRef(request.ref);
    const vendor = request.vendor;

    return {
      requestId: request.contractId || requestId,
      requestName: request.name,
      createdAt: request.createdAt,
      lastActivityAt: request.lastActivityAt,
      closedAt: request.closedAt,
      status: request.status,
      isLegacy: true,
      vendor,
    };
  });

export const useRequests = () => {
  const { accountId } = useAccountContext();
  const { data: requests, isLoading: isLoadingRequests, refetch } = useListRequestsQuery({ accountId });
  const {
    data: legacyRequests,
    isLoading: isLoadingLegacyRequests,
    refetch: refetchLegacyRequests,
  } = useListRequestsLegacyQuery({ accountId });

  const isLoading = useMemo(
    () => isLoadingRequests || isLoadingLegacyRequests,
    [isLoadingRequests, isLoadingLegacyRequests]
  );
  const refetchAll = useCallback(async () => {
    await Promise.all([refetch(), refetchLegacyRequests()]);
  }, [refetch, refetchLegacyRequests]);

  useNotificationWebSocketSubscription({
    filter: isRequestRef,
    callback: () => {
      void refetchAll();
    },
  });

  const { rows, ids } = useMemo(() => {
    if (!requests?.items || !legacyRequests?.items) {
      return { rows: [], ids: new Set() };
    }

    const legacyRequestItems = addLegacyRequestRows(legacyRequests.items);
    const requestItems = addRequestsRows(requests.items);
    const requestRows: RequestRow[] = [...requestItems, ...legacyRequestItems];
    const requestIds = new Set(requestRows.map((row) => row.requestId));

    return { rows: requestRows, ids: requestIds };
  }, [requests?.items, legacyRequests?.items]);

  return {
    requests: rows,
    requestIds: ids,
    isLoadingRequests: isLoading,
    refetch: refetchAll,
  };
};
