import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderDescription,
  DrawerHeaderTitle,
} from '@verticeone/design-system/src/components/Drawer';

import { useSavingsPlanUtilizationData } from './useSavingsPlanUtilizationData';
import { useTranslation } from 'react-i18next';
import { SPInventoryItem } from '../../useSavingsPlanInventoryData';
import Divider from '@verticeone/design-system/src/components/Divider';
import Card, { CardCloseButton, CardHeaderActions } from '@verticeone/design-system/src/components/Card';
import Chart from './Chart';
import Stats from './Stats';

type RowDetailModalProps = {
  data: SPInventoryItem;
  isOpen: boolean;
  onClose: () => void;
};

const RowDetailDrawer = ({ data: rowData, isOpen, onClose }: RowDetailModalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.SP_INVENTORY.DRAWER' });
  const { data, isLoading, error, isEmpty } = useSavingsPlanUtilizationData(rowData);

  if (!rowData) {
    return null;
  }

  return (
    <Drawer
      testId="row-detail-drawer"
      isLoading={isLoading}
      width={'calc(100% / 12 * 9)'}
      open={isOpen}
      onClose={onClose}
      fullScrollbar
    >
      <DrawerHeader noDivider>
        <DrawerHeaderTitle text={t('TITLE', { type: rowData.savingsPlanType })} />
        <DrawerHeaderDescription>{rowData.savingsPlanId}</DrawerHeaderDescription>
        <CardHeaderActions>
          <CardCloseButton onClick={onClose} />
        </CardHeaderActions>
      </DrawerHeader>
      <DrawerBody>
        <Card>
          <Stats data={data?.statsData} />
          <Divider />
          <Chart data={data?.chartData} isLoading={isLoading} isEmpty={isEmpty} error={error} />
        </Card>
      </DrawerBody>
    </Drawer>
  );
};

export default RowDetailDrawer;
