import React from 'react';
import { ProductList, ProductListProps } from '../ProductList';
import { CostModelProductItem, RollingFrequency } from './types';
import { asCostModelProductItem } from './utils';
import { useExtraColumns } from './useExtraColumns';

export type CostModelItemListProps = Omit<ProductListProps<CostModelProductItem>, 'extraColumns' | 'getProductItem'> & {
  rollingFrequency: RollingFrequency;
};

export const CostModelProductList = ({ rollingFrequency, ...otherProps }: CostModelItemListProps) => {
  const extraColumns = useExtraColumns({ rollingFrequency });

  return (
    <ProductList<CostModelProductItem>
      extraColumns={extraColumns}
      getProductItem={asCostModelProductItem}
      {...otherProps}
    />
  );
};
