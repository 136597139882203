import React from 'react';
import { Stack, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Text from '@verticeone/design-system/src/components/Text';
import { LoadableComponent } from '@verticeone/design-system/src/components/Loader';
import { StatBar, StatBarDivider, StatBarItem } from '@verticeone/design-system/src/components/StatBar';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { useFormatPercentage } from '@vertice/core/src/utils/formatting/percentage';
import { useSPRIOOverviewWidgetData } from '../useSPRIOOverviewWidgetData';
import { type DataSource, useDataSource } from '../../../DataSourceProvider';

const dataSources: Record<DataSource, string> = {
  SageMaker: 'SAGE_MAKER',
};

const SavingStatBarItem = styled(StatBarItem)(({ theme }) => ({
  '.value': {
    color: theme.palette.tertiary.color1,
  },
}));

const Summary = () => {
  const { palette } = useTheme();
  const { datasource } = useDataSource();
  const { t } = useTranslation(undefined, {
    keyPrefix: `CLOUD.RIO_NEW.OVERVIEW.WIDGETS.${dataSources[datasource]}.SUMMARY`,
  });
  const { data, isFetching, isSuccess } = useSPRIOOverviewWidgetData();
  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();

  return (
    <Stack position="relative">
      <StatBar>
        <LoadableComponent isLoading={isFetching && !isSuccess}>
          <Text
            color="text2"
            variant="caption"
            size="XS"
            bgcolor={palette.core.bg}
            position="absolute"
            left="14px"
            top="-10px"
            p={1}
          >
            {t('LAST_DAYS', { count: 32 })}
          </Text>
          <StatBarItem
            title={t('RESOURCE_COST')}
            value={formatCurrency(data?.resource_cost ?? 0, { maximumFractionDigits: 0 })}
            tooltip={{
              title: t('RESOURCE_COST'),
              content: t('RESOURCE_COST_TOOLTIP'),
            }}
            testId="resource-cost"
          />
          <StatBarDivider />
          <StatBarItem
            title={t('SAVINGS_FOUND')}
            value={[
              formatCurrency(data?.savings_found_from ?? 0, { maximumFractionDigits: 0 }),
              formatCurrency(data?.savings_found_to ?? 0, { maximumFractionDigits: 0 }),
            ].join(' - ')}
            tooltip={{
              title: t('SAVINGS_FOUND'),
              content: t('SAVINGS_FOUND_TOOLTIP'),
            }}
            testId="savings-found"
          />
          <StatBarDivider />
          <StatBarItem
            title={t('SP_UTILIZATION')}
            value={formatPercentage(data?.sp_utilization_percentage ?? 0)}
            tooltip={{
              title: t('SP_UTILIZATION'),
              content: t('SP_UTILIZATION_TOOLTIP'),
            }}
            testId="sp-utilization"
          />
          <StatBarDivider />
          <SavingStatBarItem
            title={t('ACHIEVED_SAVINGS')}
            value={formatCurrency(data?.savings_achieved ?? 0, { maximumFractionDigits: 0 })}
            tooltip={{
              title: t('ACHIEVED_SAVINGS'),
              content: t('ACHIEVED_SAVINGS_TOOLTIP'),
            }}
            testId="achieved-savings"
          />
        </LoadableComponent>
      </StatBar>
    </Stack>
  );
};

export default Summary;
