import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { useTranslation } from 'react-i18next';
import { OfferType } from './types';
import { useOfferFooterValues } from './helpers/utils';
import { Offer } from '../../../../task/TaskDrawer/TaskDrawerForm/predefinedForms/shared/schemas';
import { useFormatCurrency } from '../../../../../../utils/formatting/currency';

type OfferFooterProps = {
  offer: Offer;
  baselineOffer?: Offer;
  type: OfferType;
};

type OfferFooterItemProps = {
  title: string;
  value: string;
  variant?: 'default' | 'saving' | 'additional';
};

const getFooterItemColor = (variant: 'default' | 'saving' | 'additional'): string => {
  switch (variant) {
    case 'saving':
      return 'success';
    case 'additional':
      return 'text3';
    default:
      return 'text1';
  }
};

const OfferFooterItem = ({ title, value, variant = 'default' }: OfferFooterItemProps) => {
  const isDefault = variant === 'default';
  const isAdditional = variant === 'additional';

  return (
    <Stack gap={3} direction={'row'}>
      <Text variant="heading" size={isDefault ? 'S' : 'XS'} color={isAdditional ? 'text3' : 'text1'} width={'50%'}>
        {title}
      </Text>
      <Text
        variant="heading"
        size={variant === 'default' ? 'S' : 'XS'}
        color={getFooterItemColor(variant)}
        width={'50%'}
        textAlign={{ xs: 'right', md: 'left' }}
      >
        {value}
      </Text>
    </Stack>
  );
};

export const OfferFooter = ({ offer, baselineOffer, type }: OfferFooterProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { getOfferFooterValues } = useOfferFooterValues({ offer, baselineOffer, type });

  const { annualCost, totalCost, savings } = getOfferFooterValues();

  const shouldShowConcessionSavings = type !== 'baseline' && savings && !!savings.concessionSavings;
  const shouldShowTotalSavings =
    type !== 'baseline' && savings && (savings.concessionSavings > 0 || savings.baseSavings > 0);

  return (
    <Stack py={4} px={6} gap={1}>
      <OfferFooterItem
        title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.OFFER_COST')}
        value={formatCurrency(totalCost, { currency: offer.baseCurrency })}
        variant="additional"
      />

      <OfferFooterItem
        title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.ANNUAL_COST')}
        value={formatCurrency(annualCost, { currency: offer.baseCurrency })}
      />

      {shouldShowConcessionSavings && (
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.CONCESSION_SAVINGS')}
          value={formatCurrency(savings.concessionSavings, { currency: offer.baseCurrency })}
          variant="saving"
        />
      )}
      {shouldShowTotalSavings && (
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.TOTAL_SAVINGS')}
          value={formatCurrency((savings.concessionSavings ?? 0) + (savings.baseSavings ?? 0), {
            currency: offer.baseCurrency,
          })}
          variant="saving"
        />
      )}
    </Stack>
  );
};
