import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { CompareArrowsOutlined } from '@mui/icons-material';
import { MAX_SELECTED_ROWS, MIN_SELECTED_ROWS } from './constants';
import { useComparisonDrawer } from '../../../components/ComparisonDrawer/ComparisonDrawerContext';

const ActionsRow = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.TABLE.ACTIONS' });
  const { selectionIds, open } = useComparisonDrawer();

  const selectedCount = selectionIds.length;
  const isCompareDisabled = selectedCount < MIN_SELECTED_ROWS || selectedCount > MAX_SELECTED_ROWS;

  return (
    <Stack gap={4} direction="row" alignItems="center" px={6} py={4}>
      <Text variant="caption" size="S">
        {t('SELECTED', { count: selectedCount })}
      </Text>
      <Button variant="outline" size="S" onClick={open} disabled={isCompareDisabled}>
        <IconWrapper icon={CompareArrowsOutlined} />
        {t('COMPARE')}
      </Button>
    </Stack>
  );
};

export default ActionsRow;
