import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import Button from '@verticeone/design-system/src/components/Button';
import { Link } from 'react-router-dom';
import { useCreateNewPurchaseRequestFlow } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useCreateNewPurchaseRequestFlow';

type RequestPurchaseButtonProps = { vendorId: string; vendorName?: string };

export const RequestPurchaseButton: FC<RequestPurchaseButtonProps> = (props) => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const { createPurchaseRequestFlow, isCreatingNewPurchaseRequest } = useCreateNewPurchaseRequestFlow();

  const handleCreateIWPurchaseRequestClick = async () => {
    await createPurchaseRequestFlow({
      vendor: {
        type: 'PREDEFINED',
        id: props.vendorId,
        name: props.vendorName,
      },
    });
  };

  return (
    <>
      {isEnabled(FEATURES.INTELLIGENT_WORKFLOWS) ? (
        <Button
          variant="solid"
          color="primary"
          disabled={isCreatingNewPurchaseRequest}
          isLoading={isCreatingNewPurchaseRequest}
          onClick={handleCreateIWPurchaseRequestClick}
        >
          {t('VENDOR.REQUEST')}
        </Button>
      ) : (
        <Button
          component={Link}
          to={generatePath(routes.VENDORS.VENDOR.NEW_PURCHASE.ABSOLUTE_PATH, { id: props.vendorId })}
          variant="solid"
          color="primary"
        >
          {t('VENDOR.REQUEST')}
        </Button>
      )}
    </>
  );
};
