import React, { useEffect, useState } from 'react';
import { useController, Validate } from 'react-hook-form';
import { useTheme } from '@mui/material';
import TextField from '@verticeone/design-system/src/components/TextField/TextField';
import InputAdornment from '@verticeone/design-system/src/components/InputAdornment/InputAdornment';
import Text from '@verticeone/design-system/src/components/Text';
import { CommonFormFieldProps } from '../../types';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { DesignSystemColor } from '@verticeone/design-system/src/types';
import { sanitizeFloatString, parseNumber } from './utils';

type FormNumberFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  validate?: Record<any, Validate<number, FormDataType>>;
  minValue?: number;
  maxValue?: number;
  unit?: string;
  unitPosition?: 'start' | 'end';
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  color?: DesignSystemColor;
  allowsFloat?: boolean;
};

const FormNumberField = <FormDataType extends FieldValues = never>({
  id,
  validate,
  required,
  disabled,
  name,
  minValue,
  maxValue,
  unit,
  unitPosition = 'end',
  placeholder,
  error,
  helperText,
  color,
  allowsFloat = false,
}: FormNumberFieldProps<FormDataType>) => {
  const { palette } = useTheme();

  const unitAdornment = unit ? (
    <InputAdornment position={unitPosition}>
      <Text variant="body-bold" size="M" sx={{ color: palette.text.color3 }}>
        {unit}
      </Text>
    </InputAdornment>
  ) : null;

  const {
    field: { onChange, value, ref, ...field },
    fieldState: { invalid },
  } = useController({
    name,
    rules: {
      required,
      validate: {
        ...validate,
        isInRange: (currentValue) =>
          !(
            currentValue !== null &&
            ((minValue !== undefined && currentValue < minValue) || (maxValue !== undefined && currentValue > maxValue))
          ),
      },
    },
  });

  const [localValue, setLocalValue] = useState<string>('');

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const floatStringValue = sanitizeFloatString(event.target.value);
    const numberValue = parseNumber(event.target.value);

    if (!allowsFloat) {
      setLocalValue(numberValue?.toString() ?? '');
      onChange(numberValue);
      return;
    }

    setLocalValue(floatStringValue ?? '');

    if (isNaN(numberValue!)) {
      if (floatStringValue === '') {
        onChange(null);
      }
      return;
    }

    onChange(numberValue);
  };

  useEffect(() => {
    setLocalValue((oldLocal) => (parseFloat(oldLocal) === value ? oldLocal : value));
  }, [value]);

  return (
    <TextField
      id={id}
      type="text" // text because: https://mui.com/material-ui/react-text-field/#type-quot-number-quot
      value={localValue}
      variant="outlined"
      inputRef={ref}
      hiddenLabel
      disabled={disabled}
      placeholder={placeholder}
      helperText={helperText}
      error={error || invalid}
      fullWidth
      color={color}
      {...field}
      onChange={onChangeHandler}
      onBlur={(event) => {
        const parsedValue = parseNumber(event.target.value);
        onChange(parsedValue);
        setLocalValue(parsedValue === null ? '' : parsedValue.toString());
      }}
      InputProps={{
        inputProps: {
          min: minValue,
          max: maxValue,
        },
        [`${unitPosition}Adornment`]: unitAdornment,
      }}
    />
  );
};

export default FormNumberField;
