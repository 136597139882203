import React, { forwardRef, useState } from 'react';
import styled from '@mui/material/styles/styled';
import { type TextFieldAreaProps } from './components/constants';
import TextFieldAreaWrapper from './components/TextFieldAreaWrapper';
import { TextFieldAreaStylesProps, getTextFieldAreaStyles } from './utils';
import { TextareaAutosize } from '@mui/material';

const StyledTextArea = styled('textarea')<TextFieldAreaStylesProps>(
  ({ theme, $size, $minHeight, $autosize, $variant }) =>
    getTextFieldAreaStyles({ theme, $size, $minHeight, $autosize, $variant })
);

const StyledTextAreaAutosize = styled(TextareaAutosize)<TextFieldAreaStylesProps>(
  ({ theme, $size, $minHeight, $autosize, $variant }) =>
    getTextFieldAreaStyles({ theme, $size, $minHeight, $autosize, $variant })
);

const TextFieldArea = forwardRef<HTMLTextAreaElement, TextFieldAreaProps>(
  ({ size = 'M', textVariant = 'body-regular', minHeight = 30, autosize = false, ...otherProps }, ref) => {
    const [hasValue, setHasValue] = useState<boolean>(!!otherProps.value || !!otherProps.defaultValue);
    const limitCheck = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (otherProps.textLimit && e.target.value.length > otherProps.textLimit) {
        e.target.value = e.target.value.slice(0, otherProps.textLimit);
      }
      setHasValue(!!e.target.value);
      otherProps.onChange?.(e);
    };

    const textAreaProps = {
      className: `textarea ${otherProps.className}`,
      placeholder: otherProps.placeholder,
      onChange: limitCheck,
      $size: size,
      $variant: textVariant,
      $minHeight: minHeight,
      $autosize: autosize,
      value: otherProps.value,
      disabled: otherProps.disabled,
    };

    return (
      <TextFieldAreaWrapper
        size={size}
        textVariant={textVariant}
        autosize={autosize}
        hasValue={hasValue}
        {...otherProps}
      >
        {autosize ? (
          <StyledTextAreaAutosize ref={ref} {...otherProps} {...textAreaProps} />
        ) : (
          <StyledTextArea ref={ref} {...otherProps} {...textAreaProps} />
        )}
      </TextFieldAreaWrapper>
    );
  }
);

export default TextFieldArea;
