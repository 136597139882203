import { ProductBenchmarkHistogramItem, RangePricingBenchmark, TierPricingBenchmark } from '@vertice/slices';
import { SeriesWordcloudOptions } from 'highcharts';
import { chain, isNil, keyBy } from 'lodash';
import { categories } from './constants';
import { isNotNil } from '@vertice/core/src/utils/validation/isNotNil';

export const getNumerosityValuesForAllCategories = (
  data: ProductBenchmarkHistogramItem[],
  { relevantCategories = categories }: { relevantCategories?: string[] }
) => {
  const dataByLabel = keyBy(data, 'label');
  return relevantCategories.map((category) => dataByLabel[category]?.value ?? null);
};

export const getBenchmarkingValuesForAllCategories = (
  ranges: RangePricingBenchmark[],
  {
    relevantCategories = categories,
    multiplier = 1,
    benchmarkingFeatureStage2,
  }: {
    relevantCategories?: string[];
    multiplier?: number;
    benchmarkingFeatureStage2: boolean;
  }
) => {
  const rangeMap = keyBy(ranges, 'label');
  return relevantCategories.map((category) => {
    const range = rangeMap[category];
    if (range) {
      return [
        range.priceLow * multiplier,
        range.priceMedian * multiplier,
        range.priceHigh * multiplier,
        benchmarkingFeatureStage2 && !isNil(range.priceAccount) ? range.priceAccount * multiplier : null,
      ];
    }

    return [null, null, null, null];
  });
};

export const getAccountPricingRangePerTier = (
  tiers: TierPricingBenchmark[],
  multiplier = 1
): Record<string, RangePricingBenchmark> =>
  chain(tiers)
    .map((tier) => {
      const accountRange = tier.ranges.find((range) => !isNil(range.priceAccount));
      if (!accountRange) {
        return null;
      }

      return [
        tier.label,
        {
          ...accountRange,
          priceMedian: accountRange.priceMedian * multiplier,
          priceLow: accountRange.priceLow * multiplier,
          priceHigh: accountRange.priceHigh * multiplier,
          priceAccount: accountRange.priceAccount! * multiplier,
        },
      ] as [string, RangePricingBenchmark];
    })
    .filter(isNotNil)
    .fromPairs()
    .value();

export const getSeries = (
  tiers: TierPricingBenchmark[],
  {
    availableColors,
    relevantCategories = categories,
    multiplier = 1,
    benchmarkingFeatureStage2,
  }: {
    availableColors: string[];
    relevantCategories?: string[];
    multiplier?: number;
    benchmarkingFeatureStage2: boolean;
  }
) => {
  return tiers.map(
    (tier, index) =>
      ({
        name: tier.label,
        color: availableColors.length ? availableColors[index % availableColors.length] : undefined,
        data: getBenchmarkingValuesForAllCategories(tier.ranges, {
          relevantCategories,
          multiplier,
          benchmarkingFeatureStage2,
        }),
      } as any as SeriesWordcloudOptions)
  );
};

export const isYouAreHereDataAvailable = (tiers: TierPricingBenchmark[]) =>
  tiers.some((tier) => tier.ranges.some((range) => !isNil(range.priceAccount)));

export const getRelevantCategories = (data: TierPricingBenchmark[]): string[] => {
  const responseCategories = data.flatMap((tier) => tier.ranges).map((x) => x.label);
  return categories.filter((value) => responseCategories.includes(value));
};
