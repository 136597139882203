import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useCreateNewPurchaseRequest } from './useCreateNewPurchaseRequest';
import { Vendor } from '../../vendor/types';

export const useCreateNewPurchaseRequestFlow = () => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { enqueueSnackbar } = useSnackbar();

  const { createNewPurchaseRequest, isCreatingNewPurchaseRequest } = useCreateNewPurchaseRequest();
  const createPurchaseRequestFlow = useCallback(
    async (params: { vendor: Vendor }) => {
      const pollingSuccessful = await createNewPurchaseRequest(params);
      if (pollingSuccessful) {
        enqueueSnackbar(t('ENTITIES.WORKFLOW_REQUEST.SNACKBARS.CREATED'), { variant: 'success' });

        navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
          requestId: pollingSuccessful,
        });
      } else {
        enqueueSnackbar(t('ENTITIES.WORKFLOW_REQUEST.SNACKBARS.FAILED_TO_CREATE'), { variant: 'error' });
      }
    },
    [createNewPurchaseRequest, enqueueSnackbar, navigate, routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, t]
  );

  return {
    createPurchaseRequestFlow,
    isCreatingNewPurchaseRequest,
  };
};
