import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import { Box, Stack, useTheme } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';
import { useTranslation } from 'react-i18next';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { Card } from '@verticeone/design-system/src/components/Card';
import { getTextVariantStyle } from '@verticeone/design-system/src/components/Text/utils/getTextVariantStyle';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import {
  cssObjectToString,
  dayInMonthFormatter,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { useGraphData } from './useGraphData';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';

highchartsAccessibility(Highcharts);
patternFill(Highcharts);

const useFormatDate = () => {
  const { locale } = useLocaleContext();

  return (value: string) =>
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(value));
};

const useGraphSeries = () => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.GRAPH' });
  const { data, isFetching } = useGraphData();

  return useMemo(
    () => ({
      isFetching,
      categories: data?.map((item) => item.date) ?? [],
      lastDataTimeIndex: data?.findIndex((item) => item.spPurchaseRecommendation !== null),
      series: [
        {
          id: 'averageUsage',
          type: 'spline' as const,
          name: t('AVERAGE_USAGE'),
          color: palette.text.color1,
          dashStyle: 'Dot' as const,
          data: data?.map((item) => item.averageUsage) ?? [],
          custom: {
            legendColor: getColorPattern(palette.text.color1),
          },
        },
        {
          id: 'spCoveredUsage',
          type: 'spline' as const,
          name: t('SAVINGS_PLANS_COVERED_USAGE'),
          color: palette.text.color1,
          data: data?.map((item) => item.spCoveredUsage) ?? [],
          custom: {
            legendColor: palette.text.color1,
          },
        },
        {
          id: 'onDemandUsage',
          type: 'column' as const,
          name: t('ON_DEMAND_USAGE'),
          color: palette.visualization.monochromatic.primary['80'],
          data: data?.map((item) => item.onDemandUsage) ?? [],
          states: {
            hover: {
              enabled: true,
              color: palette.visualization.monochromatic.primary['50'],
            },
          },
          custom: {
            legendColor: palette.visualization.monochromatic.primary['50'],
          },
        },
        {
          id: 'spPurchaseRecommendation',
          type: 'line' as const,
          name: t('SAVINGS_PLANS_PURCHASE_RECOMMENDATION'),
          color: palette.visualization.monochromatic.tertiary['50'],
          data: data?.map((item) => item.spPurchaseRecommendation) ?? [],
          custom: {
            legendColor: palette.visualization.monochromatic.tertiary['50'],
          },
        },
      ],
    }),
    [data, isFetching, palette, t]
  );
};

const Graph = ({ series, categories, lastDataTimeIndex }: Omit<ReturnType<typeof useGraphSeries>, 'isFetching'>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.GRAPH' });
  const { palette } = useTheme();
  const [chart, setChartRef] = useChartRef();
  const applyColumnHover = useStackedColumnHover();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const formatDate = useFormatDate();
  const legend = series.map(({ custom: { legendColor: color }, name: label, id }) => ({ id, color, label }));

  const options = buildOptions([
    applyStyledHighcharts,
    applyColumnHover,
    applyXAxisOffset,
    (opts) =>
      mergeOptions(
        {
          chart: { spacingTop: 12 },
          plotOptions: {
            column: {
              groupPadding: 0.1,
            },
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
            },
          },
          xAxis: {
            categories,
            minPadding: 0,
            maxPadding: 0,
            labels: { formatter: dayInMonthFormatter },
            plotLines: [
              {
                color: palette.core.color6,
                value: lastDataTimeIndex,
                width: 1,
                zIndex: 3,
              },
            ],
          },
          yAxis: {
            labels: {
              formatter: ({ value }: { value: number | string }) => {
                return `<span style="${cssObjectToString(yLabelStyles(palette))}">${value}</span>`;
              },
              x: 50,
            },
            title: {
              offset: -36,
              text: t('Y_AXIS_TITLE'),
              style: {
                ...(getTextVariantStyle({ variant: 'button', size: 'S' }) as Highcharts.CSSObject),
                color: palette.text.color2,
              },
            },
          },
          tooltip: {
            shared: true,
          },
          series,
        },
        opts
      ),
  ]);

  return (
    <Card>
      <Stack bgcolor={palette.core.color1} pb={4}>
        <Box marginLeft="auto" marginTop={4} marginRight={6}>
          {<Legend items={legend} />}
        </Box>
        <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
        <HighchartTooltip chart={chart}>
          {({ x, points }) => (
            <TooltipWrapper>
              <TooltipValueWithTimeInfo value="" timeInfo={formatDate(x!.toString())} />
              {points?.map((point) => (
                <TooltipSeriesValuePair
                  seriesColor={(point.series.userOptions.custom as any).legendColor}
                  seriesName={point.series.userOptions.name}
                  value={(Math.round(point!.y! * 100) / 100).toString()}
                  key={point.series.userOptions.id}
                />
              ))}
            </TooltipWrapper>
          )}
        </HighchartTooltip>
      </Stack>
    </Card>
  );
};

const GraphContainer = () => {
  const { isFetching, ...otherProps } = useGraphSeries();

  if (!isFetching) {
    return <Graph {...otherProps} />;
  }
};

export default GraphContainer;
