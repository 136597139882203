import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Stack } from '@mui/material';
import { UserForm } from './UserForm';
import { AccountUser } from '@vertice/slices';
import UserFormNotificationsTabContent from './UserFormNotificationsTabContent';
import useUserSettings from '@vertice/core/src/hooks/useUserSettings';
import { Dialog, DialogContent, DialogHeader } from '@verticeone/design-system/src/components/Dialog';
import Placeholder from '@verticeone/design-system/src/components/Placeholder';
import { FormEntrySkeleton } from '@vertice/core/src/modules/forms/fields/FormEntry/FormEntrySkeleton';
import { UpdatedItem } from './types';

interface ModalSettings {
  open: boolean;
  editedUser?: AccountUser;
  isAddingUser?: boolean;
}

const DialogContentSkeleton = () => (
  <DialogContent>
    <Stack direction="row" gap={4}>
      <Stack gap={4} flex={1}>
        <FormEntrySkeleton />
        <FormEntrySkeleton />
        <FormEntrySkeleton />
        <FormEntrySkeleton />
        <FormEntrySkeleton />
      </Stack>
      <Stack gap={4} flex={1}>
        <Placeholder height={150} />
        <Placeholder height={150} />
        <Placeholder height={150} />
      </Stack>
    </Stack>
  </DialogContent>
);

type UseAddEditInviteContactModalProps = {
  adminMode?: boolean;
  warningMessage?: string;
};

const useAddEditInviteContactModal = ({ adminMode, warningMessage }: UseAddEditInviteContactModalProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [modalSettings, setModalSettings] = useState<ModalSettings>({ open: false });
  const [addedContact, setAddedContact] = useState<UpdatedItem | null>(null);
  const [updatedContact, setUpdatedContact] = useState<UpdatedItem | null>(null);
  const [, setInvitedContact] = useState<UpdatedItem | null>(null);

  const { settings: userSettings } = useUserSettings({
    userId: modalSettings?.editedUser?.userId,
    skip: !modalSettings.open || !adminMode,
  });

  const handleAddRequest = () => {
    setModalSettings({ open: true, isAddingUser: true });
  };
  const handleEditRequest = (user: AccountUser) => {
    setModalSettings({ open: true, editedUser: user });
  };
  const handleModalClose = () => {
    setModalSettings({ open: false });
  };
  const handleUserSaved = (newUser: AccountUser) => {
    if (newUser?.userId && modalSettings) {
      if (modalSettings.editedUser) {
        // Refresh updated user in the list
        setUpdatedContact({ itemId: modalSettings.editedUser.userId });
      } else {
        // Add user into the list
        setAddedContact({ itemId: newUser.userId, selected: true });
      }
    } else {
      // Do nothing, just fire announce
      // this will be changed once the specification is completed
      setInvitedContact(null);
      enqueueSnackbar('User invited', { variant: 'success' });
    }
    handleModalClose();
  };

  const formId = 'new-form';

  const userFormComponent = (
    <UserForm
      formId={formId}
      userData={modalSettings?.editedUser}
      userSettings={userSettings}
      adminMode={adminMode}
      showTimeZone={adminMode && !!modalSettings?.editedUser}
      onUserSaved={handleUserSaved}
      setRequestInProgress={setLoading}
      isRequestInProgress={loading}
      warningMessage={warningMessage}
      isEditMode={!!modalSettings?.editedUser}
    />
  );

  const modal = (
    <Dialog size="M" open={modalSettings?.open} width={970} setOpen={handleModalClose}>
      <Stack minHeight={0}>
        <DialogHeader>
          {modalSettings?.editedUser
            ? t('PREFERENCES.DIRECTORY.USER_FORM.EDIT_TEAM_MEMBER')
            : t('PREFERENCES.DIRECTORY.USER_FORM.ADD_NEW_TEAM_MEMBER')}
        </DialogHeader>
        {adminMode && !modalSettings.isAddingUser && !userSettings ? (
          <DialogContentSkeleton />
        ) : (
          <Stack minHeight={0} px={6}>
            {adminMode && !!modalSettings?.editedUser ? (
              <UserFormNotificationsTabContent
                teamMemberComponent={userFormComponent}
                userId={modalSettings?.editedUser?.userId}
              />
            ) : (
              userFormComponent
            )}
          </Stack>
        )}
      </Stack>
    </Dialog>
  );

  return {
    modal,
    handleAddRequest,
    handleEditRequest,
    addedContact,
    updatedContact,
  };
};

export default useAddEditInviteContactModal;
