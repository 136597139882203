import React, { useMemo } from 'react';
import { ActionMeta, OnChangeValue, MenuListProps } from 'react-select';
import { FormControl, Stack } from '@mui/material';

import TextFieldCaption from '@verticeone/design-system/src/components/TextFieldCaption';
import Select from '@verticeone/design-system/src/components/Select';
import { useVirtualizer } from '@tanstack/react-virtual';

type Option = {
  label: string;
  value: string;
  count?: number;
};

type OnChangeType<isMultiple extends boolean> = (
  newValue: OnChangeValue<Option, isMultiple>,
  actionMeta: ActionMeta<Option>
) => void;

type FilterItemProps<isMultiple extends boolean> = {
  id: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti: isMultiple;
  minWidth: number;
  label: string;
  placeholder: string;
  options: Array<Option>;
  selectedItem: string | Array<string> | null;
  onChange: OnChangeType<isMultiple>;
};

const MenuList = (props: MenuListProps) => {
  const menuRef = React.useRef(null);
  const count = useMemo(() => React.Children.count(props.children), [props.children]);

  const rowVirtualizer = useVirtualizer({
    count,
    getScrollElement: () => menuRef.current,
    estimateSize: () => 35,
    overscan: 5,
  });

  return (
    <Stack ref={menuRef} maxHeight={400} minWidth={700} overflow="auto" bgcolor="white">
      <Stack height={rowVirtualizer.getTotalSize()} width="100%" position="relative">
        {rowVirtualizer.getVirtualItems().map((virtualItem) => (
          <Stack
            key={virtualItem.key}
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height={virtualItem.size}
            sx={{ transform: `translateY(${virtualItem.start}px)` }}
          >
            {React.Children.toArray(props.children)[virtualItem.index]}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

const FilterItem = <isMultiple extends boolean = false>({
  id,
  isDisabled,
  isLoading,
  isMulti,
  minWidth,
  label,
  placeholder,
  options,
  selectedItem,
  onChange,
}: FilterItemProps<isMultiple>) => {
  const defaultValue = isMulti
    ? options.filter((option) => (selectedItem as string[])?.includes(option.value))
    : options.find((option) => option.value === selectedItem);

  return (
    <FormControl variant="outlined">
      <Stack direction="row" alignItems="center" gap={2}>
        <TextFieldCaption htmlFor={id} label={label} size="S" />
        <Stack minWidth={minWidth}>
          <Select<(typeof options)[number], isMultiple>
            variant="outlined"
            size="S"
            color="tertiary"
            placeholder={placeholder}
            id={id}
            isDisabled={isDisabled}
            isLoading={isLoading}
            value={defaultValue}
            components={{ MenuList } as any}
            getOptionValue={(option) => option.value.toString()}
            getOptionLabel={(option) => option.label}
            isMulti={isMulti}
            maxMultiChips={1}
            options={options}
            onChange={onChange}
          />
        </Stack>
      </Stack>
    </FormControl>
  );
};

export default FilterItem;
