import { useMemo } from 'react';
import { mapKeys, mapValues, toLower } from 'lodash';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import testsByCodeRaw from '@vertice/i18n/src/i18n/cloud/testsByCode_en.json';
import recommendationsByCodeRaw from '@vertice/i18n/src/i18n/cloud/recommendationsByCode_en.json';
import type { DesignSystemColor } from '@verticeone/design-system/src/types';
import {
  type OptimizationRecommendation,
  type OptimizationTest,
  type TestResultTooltip,
  useOptimizationsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { basicColorGetter, useDataVisibility, useTransformToObfuscated, useValueFormatter } from './utils';

type TestCode = keyof typeof testsByCodeRaw;
type RecommendationsCode = keyof typeof recommendationsByCodeRaw;

type TOptimizationTestRow = {
  code: string;
  name: string;
  description?: string;
};

type TOptimizationRecommendationRow = {
  code: string;
  name: string;
  description?: string;
  effort: 'LOW' | 'MEDIUM' | 'HIGH';
};

export type OptimizationTestRow = {
  recommendations: Array<
    Omit<OptimizationRecommendation, keyof TOptimizationRecommendationRow> & TOptimizationRecommendationRow
  >;
  result: {
    value: string;
    color: DesignSystemColor;
    tooltip: TestResultTooltip;
  };
} & OptimizationTest &
  TOptimizationTestRow;

const transformKeys = <K extends string, V extends any>(object: Record<K, any>): Record<K, V> => {
  return mapValues(object, (item) => mapKeys(item, (_, key) => toLower(key))) as Record<K, V>;
};

const useOptimizationTestsData = () => {
  const { accountId } = useAccountContext();
  const transformToObfuscated = useTransformToObfuscated();
  const valueFormatter = useValueFormatter();
  const { canViewFullVersion } = useDataVisibility();

  const dataTestsByCode = useMemo(() => transformKeys<TestCode, TOptimizationTestRow>(testsByCodeRaw), []);
  const dataRecommendationsByCode = useMemo(
    () => transformKeys<RecommendationsCode, TOptimizationRecommendationRow>(recommendationsByCodeRaw),
    []
  );

  return useOptimizationsQuery(
    { accountId },
    {
      skip: !accountId,
      selectFromResult: (result) => {
        if (result?.data?.optimizationsQuery?.__typename === 'OptimizationsResult') {
          const data = result.data.optimizationsQuery.tests.map((test) => ({
            ...dataTestsByCode[test.code as TestCode],
            ...test,
            recommendations: test.recommendations.map((recommendation) => ({
              ...dataRecommendationsByCode[recommendation.code as RecommendationsCode],
              ...recommendation,
            })),
            result: {
              value: valueFormatter(test.testSummary.value, test.testSummary.type),
              color: basicColorGetter(test.breaching),
              tooltip: test.testSummary.tooltip,
            },
          })) as Array<OptimizationTestRow>;

          return {
            ...result,
            data: canViewFullVersion ? data : transformToObfuscated(data),
          };
        }
        return { ...result, data: [] };
      },
    }
  );
};

export default useOptimizationTestsData;
