import {
  CloudInventoryProvider,
  Tab,
} from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/useCloudInventory';
import React from 'react';

import CloudInventoryContent from '../components/CloudInventoryContent';
import { useCloudContext } from '@vertice/dashboard/src/modules/cloud/CloudContext';
import Ec2RIUtilizationCard from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/Ec2/Ec2RIUtilizationCard';
import RdsRIUtilizationCard from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/Rds/RdsRIUtilizationCard';
import { useRoutes } from '@verticeone/router/src';
import { ROUTES } from '@vertice/utils/src/constants/cfa/constants';

const CloudInventoryTab = () => {
  const { subFeatures } = useCloudContext();
  const routes = useRoutes();

  const tabs: Tab[] = [];

  if (subFeatures.sprioEC2RI) {
    tabs.push({
      id: 'ec2',
      instanceTypePosition: { instanceFamily: 0, size: 1 },
      component: Ec2RIUtilizationCard,
    });
  }

  if (subFeatures.sprioRDSRI) {
    tabs.push({
      id: 'rds',
      instanceTypePosition: { instanceFamily: 1, size: 2 },
      component: RdsRIUtilizationCard,
    });
  }

  const routeConfig = {
    path: ROUTES.CLOUD_RIO_RESERVED_INSTANCES_DETAIL,
    route: routes.CLOUD.RIO.RI.INVENTORY.DETAIL,
  };

  return (
    <CloudInventoryProvider tabs={tabs} routeConfig={routeConfig}>
      <CloudInventoryContent showToggleSwitch />
    </CloudInventoryProvider>
  );
};

export default CloudInventoryTab;
