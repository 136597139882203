import React, { ComponentType, SVGProps, createContext, useContext, useEffect, useState } from 'react';
import { Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { type SvgIconComponent } from '@mui/icons-material';
import Text from '@verticeone/design-system/src/components/Text';
import Button, { BaseButton } from '@verticeone/design-system/src/components/Button';
import Accordion, { AccordionSummary, AccordionDetails } from '@verticeone/design-system/src/components/Accordion';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

type NavItemProps = {
  icon: SvgIconComponent | ComponentType<SVGProps<SVGSVGElement>>;
  label: string;
  to?: string;
  onClick?: () => void;
  visible?: boolean;
};

type NavDropdownProps = {
  children: React.ReactNode;
  title: string;
  expanded: boolean;
  onChange: () => void;
  visible?: boolean;
};

const NavWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1.5),
  [BaseButton]: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(2),
    '&, &:hover': {
      padding: theme.spacing(2),
    },
    svg: {
      fill: theme.palette.core.color5,
      opacity: 0.6,
    },
  },
}));

export const Nav = ({ children }: { children: React.ReactNode }) => <NavWrapper>{children}</NavWrapper>;

export const NavItem = ({ icon: Icon, label, visible = true, onClick, to }: NavItemProps) => {
  if (!visible) {
    return null;
  }

  return (
    <Button
      component={to ? Link : undefined}
      startIcon={<Icon height={20} width={20} />}
      size="M"
      variant="plain"
      to={to ?? ''}
      onClick={onClick}
    >
      <Text variant="button" size="S" color="text2">
        {label}
      </Text>
    </Button>
  );
};

export const NavDropdown = ({ children, title, expanded, onChange, visible = true }: NavDropdownProps) => {
  if (!visible) {
    return null;
  }

  return (
    <Accordion size="S" variant="solid" expanded={expanded} onChange={onChange}>
      <AccordionSummary title={title} />
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export type NavigationContextType = {
  expanded: string | false;
  setExpanded: (key: string) => void;
};

const NavigationContext = createContext<NavigationContextType>({} as NavigationContextType);

export const NavigationProvider = ({ children }: { children: React.ReactNode }) => {
  const STORAGE_KEY = 'NAVIGATION_SECTION_EXPANDED';
  const [expanded, setExpanded] = useState<string | false>(localStorage.getItem(STORAGE_KEY) ?? false);
  const handleExpanded = (key: string) => setExpanded((prev) => (prev !== key ? key : false));

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, expanded.toString());
  }, [expanded]);

  return (
    <NavigationContext.Provider value={{ expanded, setExpanded: handleExpanded }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  const { expanded, setExpanded } = useContext(NavigationContext);

  return [expanded, setExpanded] as [NavigationContextType['expanded'], NavigationContextType['setExpanded']];
};

export const useFeatureEnabled = () => {
  type FEATURE = keyof typeof FEATURES;
  const { isEnabled, isLoading: featureIsLoading } = useFeatures();
  return (features: FEATURE | Array<FEATURE>) => {
    if (featureIsLoading) {
      return false;
    }

    return [features].flat().some((feature) => isEnabled(feature));
  };
};
