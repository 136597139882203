import React, { FC, useCallback, useEffect, useState } from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { get } from 'lodash';

import Button from '@verticeone/design-system/src/components/Button';
import Alert, { AlertButton } from '@verticeone/design-system/src/components/Alert';

import { COMMON_BUTTON_PROPS } from '../../../constants';
import { useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import { FormSchemaProvider } from '@vertice/core/src/modules/forms/schema/FormSchemaContext';
import { useTaskContext } from '../TaskContext';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useDraftUserTaskMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { enqueueSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';
import { usePredefinedFormDef } from './usePredefinedFormDef';
import { TaskFormContextProvider, useTaskFormContext } from './predefinedForms/shared/TaskFormContext';
import Divider from '@verticeone/design-system/src/components/Divider';
import { useScrollBoundaries } from '../../../../../hooks/useScrollBoundaries';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';

type TaskDrawerFormProps = {
  onSubmit: (resultBody: object) => Promise<void>;
  onCancel: () => void;
  formConfig: { [p: string]: any };
};

const TaskDrawerForm: FC<TaskDrawerFormProps> = ({ onSubmit, onCancel, formConfig }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const formDef = usePredefinedFormDef(formConfig);

  const taskContextData = useTaskContext();
  const { accountId } = useAccountContext();

  const { scrollBoundaries, onScrollHandler } = useScrollBoundaries();

  // We need to pass an empty error map to zodResolver, because we don't want to show any error messages
  const schemaOptions = { errorMap: () => ({ message: '' }) };

  const getTaskDataValue = useCallback(
    (path: string, defaultValue: any) =>
      get(taskContextData.taskOverview?.task?.draft, path) ??
      get(taskContextData.taskOverview?.task?.input, path) ??
      defaultValue,
    [taskContextData.taskOverview?.task?.draft, taskContextData.taskOverview?.task?.input]
  );

  const formMethods = useForm({
    mode: 'all', // Validate on change, blur, submit
    resolver: formDef?.schema ? zodResolver(formDef?.schema, schemaOptions) : undefined,
    defaultValues: formDef?.getDefaultValues?.(getTaskDataValue, taskContextData),
  });
  const { handleSubmit } = useGoodFormUtils(formMethods);

  const submitTransformed = async (formData: object) => {
    await onSubmit(formDef?.transformOutput ? formDef.transformOutput(formData) : formData);
  };

  const watch = formMethods.watch;
  const [updateDraftUserTask] = useDraftUserTaskMutation();
  const [, setDraftSaving] = useState<boolean>(false);

  const saveDraftDebounced = useDebouncedCallback(
    (formValues) => {
      setDraftSaving(true);
      updateDraftUserTask({
        accountId: accountId,
        taskId: taskContextData.task.id,
        body: {
          ...formValues,
        },
      })
        .catch((e) => {
          enqueueSnackbar(t('PREFERENCES.USER.SNACKBAR.ERROR'), {
            variant: 'error',
          });
        })
        .finally(() => {
          setDraftSaving(false);
        });
    },
    500,
    { maxWait: 1000 }
  );

  useEffect(() => {
    if (!formDef?.draftEnabled) return;
    const { unsubscribe } = watch((formValues) => {
      saveDraftDebounced(formValues);
    });
    return () => unsubscribe();
  }, [watch, formDef?.draftEnabled, saveDraftDebounced]);

  return (
    <FormSchemaProvider value={formDef?.schema ?? null}>
      <TaskFormContextProvider>
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(submitTransformed)}
            style={{ display: 'flex', minHeight: 0, height: '100%', minWidth: 0 }}
          >
            <Stack minHeight={0} width="100%" height="100%">
              <Stack padding={6} overflow="auto" minHeight={0} height="100%" onScroll={onScrollHandler}>
                {formDef ? (
                  <formDef.component />
                ) : (
                  <Alert
                    size="S"
                    variant="ghost"
                    color="error"
                    title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.UNKNOWN_FORM')}
                    subtitle={formConfig.formUrn}
                  >
                    <AlertButton onClick={onCancel}>{t('DIALOG.BUTTONS.CLOSE')}</AlertButton>
                  </Alert>
                )}
              </Stack>
              <Stack
                sx={{
                  boxShadow: scrollBoundaries.bottom
                    ? `${palette.global.getShadow({
                        color: 'core',
                        type: 'soft',
                        depth: '3z',
                        distance: '80',
                      })}, ${palette.global.getShadow({ color: 'core', type: 'soft', depth: '3z', distance: '70' })}`
                    : 'none',
                  transition: 'box-shadow 0.3s ease-in-out',
                }}
              >
                <Stack marginX={-6}>
                  <Divider />
                </Stack>
                <Stack direction="row" padding={6} gap={2}>
                  {Boolean(formDef) && <SubmitButton />}
                </Stack>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </TaskFormContextProvider>
    </FormSchemaProvider>
  );
};

// We keep this as a separate component, because it subscribes to the whole formState and we don't want the root
// useForm to subscribe to it (it would cause re-renders of the whole form)
const SubmitButton = () => {
  const { t } = useTranslation();
  const formState = useFormState();
  const { isProcessing } = useTaskFormContext();
  const { disabled, isLoading } = getPropsForSubmitButton(formState, { disableWhenFormUntouched: false });

  return (
    <Tooltip
      title={t('ENTITIES.WORKFLOW_TASK.ACTIONS.SUBMIT_AND_COMPLETE_TOOLTIP')}
      size="S"
      disableHoverListener={!disabled || isProcessing || isLoading}
    >
      <Stack width="100%">
        <Button
          type="submit"
          variant="solid"
          {...COMMON_BUTTON_PROPS}
          disabled={disabled || isProcessing}
          isLoading={isLoading || isProcessing}
        >
          {t('ENTITIES.WORKFLOW_TASK.ACTIONS.SUBMIT_AND_COMPLETE')}
        </Button>
      </Stack>
    </Tooltip>
  );
};

export default TaskDrawerForm;
