import { useFormatDate } from '@vertice/core/src/utils/formatting/date';
import TwoRowCell from './TwoRowCell';
import useFormattedTimeAgo from '@vertice/core/src/utils/formatting/useFormattedTimeAgo';

type DateCellProps = {
  date: string;
};

export const DateCell = ({ date: dateString }: DateCellProps) => {
  const formatDate = useFormatDate();
  const { getFullFormattedTimeAgo } = useFormattedTimeAgo();

  const formattedDate = formatDate(dateString);
  const formattedTimeAgo = getFullFormattedTimeAgo(new Date(dateString));

  return <TwoRowCell subtitle={formattedDate} title={formattedTimeAgo} />;
};
