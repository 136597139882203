import { TaskAssignmentConfiguration } from '../../../../definitionsTypes';
import { EXPRESSION_ALIASES } from '../../../../task/ExpressionAliases';
import isEqual from 'lodash/isEqual';
import { DEFINITION_VERSION } from '../../../../definitions/constants';
import { formatUserRef, parseUserRef } from '../../../../../../hooks/workflows/refUtils';
import { useTranslation } from 'react-i18next';
import { useAccountUsersById } from '../../../../../../hooks/useAccountUsersById';
import { useEffect, useMemo, useState } from 'react';
import { getFullName } from '../../../../../../utils/formatting/user';

// convert task assignment configuration to assignment select values
export const getAssigneesFromAssignmentConfiguration = (
  assignmentConfiguration: TaskAssignmentConfiguration
): string[] => {
  if (assignmentConfiguration.type === 'STATIC') {
    return assignmentConfiguration.assignment?.map((a) => parseUserRef(a).userId) || [];
  } else if (assignmentConfiguration.type === 'REQUEST_OWNER') {
    return ['REQUEST_OWNER'];
  } else if (assignmentConfiguration.type === 'EXPRESSION') {
    if (
      assignmentConfiguration.expression_type === EXPRESSION_ALIASES.WorkflowSettings.expressionType &&
      isEqual(assignmentConfiguration.assignment, EXPRESSION_ALIASES.WorkflowSettings.assignment)
    ) {
      return ['WORKFLOWS_CONFIG'];
    } else if (
      assignmentConfiguration.expression_type === EXPRESSION_ALIASES.ContractOwner.expressionType &&
      isEqual(assignmentConfiguration.assignment, EXPRESSION_ALIASES.ContractOwner.assignment)
    ) {
      return ['CONTRACT_OWNER'];
    } else if (
      assignmentConfiguration.expression_type === EXPRESSION_ALIASES.DepartmentOwner.expressionType &&
      isEqual(assignmentConfiguration.assignment, EXPRESSION_ALIASES.DepartmentOwner.assignment)
    ) {
      return ['DEPARTMENT_OWNER'];
    }
  }

  return [];
};

// convert assignment select values to task assignment configuration
export const getAssignmentConfigFromAssignees = (assignees: string[]): TaskAssignmentConfiguration => {
  if (assignees.includes('REQUEST_OWNER')) {
    return {
      kind: 'Tasks:Assignment',
      version: DEFINITION_VERSION,
      type: 'REQUEST_OWNER',
    };
  }

  if (assignees.includes('WORKFLOWS_CONFIG')) {
    return {
      kind: 'Tasks:Assignment',
      version: DEFINITION_VERSION,
      type: 'EXPRESSION',
      expression_type: EXPRESSION_ALIASES.WorkflowSettings.expressionType,
      assignment: EXPRESSION_ALIASES.WorkflowSettings.assignment,
    };
  }

  if (assignees.includes('DEPARTMENT_OWNER')) {
    return {
      kind: 'Tasks:Assignment',
      version: DEFINITION_VERSION,
      type: 'EXPRESSION',
      expression_type: EXPRESSION_ALIASES.DepartmentOwner.expressionType,
      assignment: EXPRESSION_ALIASES.DepartmentOwner.assignment,
    };
  }

  if (assignees.includes('CONTRACT_OWNER')) {
    return {
      kind: 'Tasks:Assignment',
      version: DEFINITION_VERSION,
      type: 'EXPRESSION',
      expression_type: EXPRESSION_ALIASES.ContractOwner.expressionType,
      assignment: EXPRESSION_ALIASES.ContractOwner.assignment,
    };
  }

  return {
    kind: 'Tasks:Assignment',
    version: DEFINITION_VERSION,
    type: 'STATIC',
    assignment: assignees.map(formatUserRef),
  };
};

export type Option = {
  readonly value: string;
  readonly label: string;
};

export type GroupOption = {
  label: string;
  options: Option[];
  singleOption: boolean;
  checkedFirst: boolean;
};

// get grouped options for the assignee select
export const useAssignmentOptions = (
  currentAssignees: string[],
  sortAssignees: boolean,
  allowContractOwnerAssignment?: boolean
): GroupOption[] => {
  const { t } = useTranslation();
  const { usersById } = useAccountUsersById();

  const options = useMemo(() => {
    const fixedOptions: Option[] = [
      { value: 'REQUEST_OWNER', label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.REQUEST_OWNER') },
      { value: 'DEPARTMENT_OWNER', label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.DEPARTMENT_OWNER') },
      ...(allowContractOwnerAssignment
        ? [{ value: 'CONTRACT_OWNER', label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.CONTRACT_OWNER') }]
        : []),
      { value: 'WORKFLOWS_CONFIG', label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.WORKFLOWS_CONFIG') },
    ];

    const userOptions: Option[] = Object.values(usersById)
      .map((user) => ({
        value: user.userId,
        label: getFullName(user),
      }))
      .sort((u1, u2) => u1.label.localeCompare(u2.label));

    return [
      {
        label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.DYNAMIC_USERS_GROUP'),
        options: fixedOptions,
        singleOption: true,
        checkedFirst: false,
      },
      {
        label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK.STATIC_USERS_GROUP'),
        options: userOptions,
        singleOption: false,
        checkedFirst: true,
      },
    ];
  }, [allowContractOwnerAssignment, t, usersById]);

  const [sortedOptions, setSortedOptions] = useState(options);

  useEffect(() => {
    if (sortAssignees) {
      setSortedOptions(sortOptions(options, currentAssignees));
    }
  }, [currentAssignees, options, sortAssignees]);

  return sortedOptions;
};

// sort assignee select groups options to move checked options to the top
export const sortOptions = (groupOptions: GroupOption[], checkedAssignees: string[]): GroupOption[] => {
  return groupOptions.slice().map((groupOption) => {
    if (!groupOption.checkedFirst) {
      return groupOption;
    }
    const sortedOptions = groupOption.options.slice().sort((o1, o2) => {
      const isChecked1 = checkedAssignees.includes(o1.value);
      const isChecked2 = checkedAssignees.includes(o2.value);
      let checkValue = 0;
      if (groupOption.checkedFirst && isChecked1 !== isChecked2) {
        checkValue = isChecked1 && !isChecked2 ? -1 : 1;
      }
      const x = checkValue || o1.label.localeCompare(o2.label);
      return x;
    });
    return {
      ...groupOption,
      options: sortedOptions,
    };
  });
};
