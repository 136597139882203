import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@verticeone/design-system/src/components/Button';
import { Overview, Summary, Graph, Body, ActionBar, ButtonGroup, Title } from './components';
import DataSourceProvider from '../../DataSourceProvider';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';

const SageMaker = () => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OVERVIEW.WIDGETS.SAGE_MAKER.ACTION_BAR' });

  const toInventory = () => navigate(routes.CLOUD.RIO.SP.INVENTORY);
  const toOptimize = () => navigate(routes.CLOUD.RIO.SP.OPTIMIZE);

  return (
    <DataSourceProvider datasource="SageMaker">
      <Overview>
        <Body>
          <Title />
          <Graph />
          <Summary />
        </Body>
        <ActionBar>
          <ButtonGroup>
            <Button variant="ghost" size="S" color="neutral" onClick={toInventory}>
              {t('INVENTORY')}
            </Button>
            <Button variant="ghost" size="S" color="tertiary" onClick={toOptimize}>
              {t('OPTIMIZE')}
            </Button>
          </ButtonGroup>
        </ActionBar>
      </Overview>
    </DataSourceProvider>
  );
};

export default SageMaker;
