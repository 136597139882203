import { Stack } from '@mui/material';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import {
  gridPageSelector,
  gridPageSizeSelector,
  gridPaginationRowRangeSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import i18n from '../../../translate';
import { DesignSystemSize } from '../../../types';
import { baseSizes, reducedSizes } from '../../../guidelines/Sizing/sizings';
import Select from '../../Select';
import Text from '../../Text';
import IconButton from '../../IconButton';
import { useDataGridContext } from '../DataGridContext';
import { useTranslation } from 'react-i18next';

const RowsPerPageSelect = () => {
  const { t } = useTranslation(undefined, { i18n });
  const { size, testId } = useDataGridContext();
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const { pageSizeOptions } = useGridRootProps();

  const options: { value: number; label: string }[] = pageSizeOptions.map((option) =>
    typeof option === 'number' ? { value: option, label: option.toString() } : option
  );

  return (
    <Stack direction="row" alignItems="center" gap={reducedSizes[size]}>
      <Text variant="body-regular" size={size} color="text2">
        {t('DATA_GRID.PAGINATION_FOOTER.ROWS_PER_PAGE')}
      </Text>
      <Select
        variant="solid"
        size={size}
        value={{ value: pageSize, label: pageSize.toString() }}
        onChange={(newValue) => newValue && apiRef.current.setPageSize(newValue.value)}
        options={options}
        isMulti={false}
        testId={`${testId}-rows-per-page-select`}
        isSearchable={false}
      />
    </Stack>
  );
};

const PaginationActions = () => {
  const { t } = useTranslation(undefined, { i18n });
  const { size, testId } = useDataGridContext();
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const paginationRowRange = useGridSelector(apiRef, gridPaginationRowRangeSelector);
  const { rows } = useGridRootProps();

  const firstRowIndex = (paginationRowRange?.firstRowIndex ?? 0) + 1;
  const lastRowIndex = (paginationRowRange?.lastRowIndex ?? 0) + 1;

  const hasPreviousPage = page > 0;
  const hasNextPage = lastRowIndex < rows.length;

  return (
    <Stack direction="row" gap={baseSizes[size]} alignItems="center">
      <Stack direction="row" gap={1}>
        <Text variant="body-bold" size={size} color="text2" data-testid="rowindex">
          {firstRowIndex}-{lastRowIndex}
        </Text>
        <Text variant="body-regular" size={size} color="text3">
          {t('DATA_GRID.PAGINATION_FOOTER.OF')}
        </Text>
        <Text variant="body-regular" size={size} color="text2">
          {rows.length}
        </Text>
      </Stack>
      <Stack direction="row" gap={1}>
        <IconButton
          icon={ChevronLeftOutlined}
          variant="ghost"
          color="neutral"
          size={size}
          onClick={() => apiRef.current.setPage(page - 1)}
          disabled={!hasPreviousPage}
          testId={`${testId ? `${testId}-` : ''}previous-page-button`}
        />
        <IconButton
          icon={ChevronRightOutlined}
          variant="ghost"
          color="neutral"
          size={size}
          onClick={() => apiRef.current.setPage(page + 1)}
          disabled={!hasNextPage}
          testId={`${testId ? `${testId}-` : ''}next-page-button`}
        />
      </Stack>
    </Stack>
  );
};

const paddingSizes: Record<DesignSystemSize, string> = {
  XL: '15px',
  L: '13.5px',
  M: '12px',
  S: '10.5px',
  XS: '9px',
  XXS: '7.5px',
};

const GridPagination = () => {
  const { size } = useDataGridContext();

  return (
    <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center" padding={paddingSizes[size]}>
      <RowsPerPageSelect />
      <PaginationActions />
    </Stack>
  );
};

export default GridPagination;
