import React from 'react';
import SageMaker from './products/SageMaker';
import { useCloudContext } from '../../../../../modules/cloud/CloudContext';

const CloudOptimizeTab = () => {
  const { subFeatures } = useCloudContext();

  return <>{!!subFeatures.sprioSagemakerSP && <SageMaker />}</>;
};

export default CloudOptimizeTab;
