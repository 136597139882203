import { bffeSaasAPI as api } from '../../api/bffeSaasAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    exportContractsAsCsv: build.query<ExportContractsAsCsvApiResponse, ExportContractsAsCsvApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/contracts/export` }),
    }),
    listRequests: build.query<ListRequestsApiResponse, ListRequestsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    createRequest: build.mutation<CreateRequestApiResponse, CreateRequestApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/requests`,
        method: 'POST',
        body: queryArg.createRequest,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    listContracts: build.query<ListContractsApiResponse, ListContractsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
          lineageId: queryArg.lineageId,
          vendorId: queryArg.vendorId,
          consumer: queryArg.consumer,
        },
      }),
    }),
    createContract: build.mutation<CreateContractApiResponse, CreateContractApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts`,
        method: 'POST',
        body: queryArg.createContractRequest,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
          lineageId: queryArg.lineageId,
          vendorId: queryArg.vendorId,
          consumer: queryArg.consumer,
        },
      }),
    }),
    updateContract: build.mutation<UpdateContractApiResponse, UpdateContractApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}`,
        method: 'POST',
        body: queryArg.updateContractRequest,
        params: { changeNumber: queryArg.changeNumber },
      }),
    }),
    deleteContract: build.mutation<DeleteContractApiResponse, DeleteContractApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}`,
        method: 'DELETE',
        params: { changeNumber: queryArg.changeNumber },
      }),
    }),
    getContract: build.query<GetContractApiResponse, GetContractApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}`,
        params: { changeNumber: queryArg.changeNumber },
      }),
    }),
    listContractChangeLog: build.query<ListContractChangeLogApiResponse, ListContractChangeLogApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/changelog`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    resolveUserPermissionsOnContract: build.query<
      ResolveUserPermissionsOnContractApiResponse,
      ResolveUserPermissionsOnContractApiArg
    >({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/permissions` }),
    }),
    listContractTasks: build.query<ListContractTasksApiResponse, ListContractTasksApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/tasks`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    listContractTasksForUser: build.query<ListContractTasksForUserApiResponse, ListContractTasksForUserApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/users/${queryArg.userId}/tasks`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    fulfillUserTask: build.mutation<FulfillUserTaskApiResponse, FulfillUserTaskApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tasks/${queryArg.taskId}/fulfill`,
        method: 'POST',
        body: queryArg.userTaskResult,
      }),
    }),
    listContractAttachments: build.query<ListContractAttachmentsApiResponse, ListContractAttachmentsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/files` }),
    }),
    readContractFileV2: build.query<ReadContractFileV2ApiResponse, ReadContractFileV2ApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/files/${queryArg.fileName}`,
      }),
    }),
    scanContractFileV2: build.mutation<ScanContractFileV2ApiResponse, ScanContractFileV2ApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/files/${queryArg.fileName}`,
        method: 'HEAD',
      }),
    }),
    uploadContractFileV2: build.mutation<UploadContractFileV2ApiResponse, UploadContractFileV2ApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/files/${queryArg.fileName}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteContractFileV2: build.mutation<DeleteContractFileV2ApiResponse, DeleteContractFileV2ApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}/files/${queryArg.fileName}`,
        method: 'DELETE',
      }),
    }),
    getContractsStatistics: build.query<GetContractsStatisticsApiResponse, GetContractsStatisticsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/statistics/contracts` }),
    }),
    listTasksForUser: build.query<ListTasksForUserApiResponse, ListTasksForUserApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/users/${queryArg.userId}/tasks`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    listDepartments: build.query<ListDepartmentsApiResponse, ListDepartmentsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/departments`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    createDepartment: build.mutation<CreateDepartmentApiResponse, CreateDepartmentApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/departments`,
        method: 'POST',
        body: queryArg.createDepartmentRequest,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    getDepartment: build.query<GetDepartmentApiResponse, GetDepartmentApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/departments/${queryArg.departmentId}` }),
    }),
    updateDepartment: build.mutation<UpdateDepartmentApiResponse, UpdateDepartmentApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/departments/${queryArg.departmentId}`,
        method: 'POST',
        body: queryArg.updateDepartmentRequest,
      }),
    }),
    deleteDepartment: build.mutation<DeleteDepartmentApiResponse, DeleteDepartmentApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/departments/${queryArg.departmentId}`,
        method: 'DELETE',
      }),
    }),
    updateDepartmentOwnership: build.mutation<UpdateDepartmentOwnershipApiResponse, UpdateDepartmentOwnershipApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/departments/${queryArg.departmentId}/ownership`,
        method: 'PUT',
        body: queryArg.updateDepartmentOwnershipRequest,
      }),
    }),
    adminGetBuyersBoardData: build.query<AdminGetBuyersBoardDataApiResponse, AdminGetBuyersBoardDataApiArg>({
      query: (queryArg) => ({
        url: `/admin/buyersboard/contracts`,
        params: {
          nextToken: queryArg.nextToken,
          category: queryArg.category,
          maxResults: queryArg.maxResults,
          filters: queryArg.filters,
        },
      }),
    }),
    adminGetContractAssociations: build.query<
      AdminGetContractAssociationsApiResponse,
      AdminGetContractAssociationsApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/buyersboard/${queryArg.accountId}/contracts/${queryArg.contractId}/associations`,
      }),
    }),
    adminStoreContractAssociations: build.mutation<
      AdminStoreContractAssociationsApiResponse,
      AdminStoreContractAssociationsApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/buyersboard/${queryArg.accountId}/contracts/${queryArg.contractId}/associations`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    adminGetUpcomingContracts: build.query<AdminGetUpcomingContractsApiResponse, AdminGetUpcomingContractsApiArg>({
      query: (queryArg) => ({
        url: `/admin/upcoming/contracts`,
        params: { threshold: queryArg.threshold, includeAll: queryArg.includeAll },
      }),
    }),
    getSavingsDashboard: build.query<GetSavingsDashboardApiResponse, GetSavingsDashboardApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/savings`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    listVendorsPageContracts: build.query<ListVendorsPageContractsApiResponse, ListVendorsPageContractsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/vendors/contracts`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults, vendorId: queryArg.vendorId },
      }),
    }),
    getCustomContractFields: build.query<GetCustomContractFieldsApiResponse, GetCustomContractFieldsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/customizations/contractfields` }),
    }),
    updateCustomContractFields: build.mutation<UpdateCustomContractFieldsApiResponse, UpdateCustomContractFieldsApiArg>(
      {
        query: (queryArg) => ({
          url: `/accounts/${queryArg.accountId}/customizations/contractfields`,
          method: 'POST',
          body: queryArg.updateCustomContractFieldsRequest,
        }),
      }
    ),
    listCustomizationViews: build.query<ListCustomizationViewsApiResponse, ListCustomizationViewsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/customizations/views`,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    createCustomizationView: build.mutation<CreateCustomizationViewApiResponse, CreateCustomizationViewApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/customizations/views`,
        method: 'POST',
        body: queryArg.createCustomizationViewRequest,
        params: { nextToken: queryArg.nextToken, maxResults: queryArg.maxResults },
      }),
    }),
    getCustomizationView: build.query<GetCustomizationViewApiResponse, GetCustomizationViewApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/customizations/views/${queryArg.viewId}`,
        params: { changeNumber: queryArg.changeNumber },
      }),
    }),
    updateCustomizationView: build.mutation<UpdateCustomizationViewApiResponse, UpdateCustomizationViewApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/customizations/views/${queryArg.viewId}`,
        method: 'POST',
        body: queryArg.updateCustomizationViewRequest,
        params: { changeNumber: queryArg.changeNumber },
      }),
    }),
    deleteCustomizationView: build.mutation<DeleteCustomizationViewApiResponse, DeleteCustomizationViewApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/customizations/views/${queryArg.viewId}`,
        method: 'DELETE',
        params: { changeNumber: queryArg.changeNumber },
      }),
    }),
    getCustomListFiltersConfiguration: build.query<
      GetCustomListFiltersConfigurationApiResponse,
      GetCustomListFiltersConfigurationApiArg
    >({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/user/${queryArg.userId}/customizations/filters` }),
    }),
    updateCustomListFiltersConfiguration: build.mutation<
      UpdateCustomListFiltersConfigurationApiResponse,
      UpdateCustomListFiltersConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/user/${queryArg.userId}/customizations/filters`,
        method: 'POST',
        body: queryArg.updateCustomListFiltersConfigurationRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bffeSaasAPICodegen };
export type ExportContractsAsCsvApiResponse = /** status 200 Export contracts as CSV response */ string;
export type ExportContractsAsCsvApiArg = {
  accountId: string;
};
export type ListRequestsApiResponse = /** status 200 List of requests */ {
  nextToken?: NextToken;
  items?: Request[];
};
export type ListRequestsApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type CreateRequestApiResponse = /** status 201 Object reference urn */ {
  ref: string;
};
export type CreateRequestApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
  /** Request create request */
  createRequest: CreateRequest;
};
export type ListContractsApiResponse = /** status 200 List of contracts */ {
  contracts: ViewOnContract[];
  nextToken?: NextToken;
};
export type ListContractsApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
  lineageId?: string;
  vendorId?: string;
  consumer?: string;
};
export type CreateContractApiResponse = /** status 201 Newly created contract */ {
  viewOnContract: ViewOnContract;
};
export type CreateContractApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
  lineageId?: string;
  vendorId?: string;
  consumer?: string;
  /** Create contract request */
  createContractRequest: {
    contract: ContractCreate;
  };
};
export type UpdateContractApiResponse = /** status 200 Updated contract */ {
  viewOnContract: ViewOnContract;
};
export type UpdateContractApiArg = {
  accountId: string;
  contractId: string;
  changeNumber?: number;
  /** Update contract request */
  updateContractRequest: {
    contract: ContractUpdate;
  };
};
export type DeleteContractApiResponse = /** status 200 Deleted contract */ object;
export type DeleteContractApiArg = {
  accountId: string;
  contractId: string;
  changeNumber?: number;
};
export type GetContractApiResponse = /** status 200 Existing contract */ {
  viewOnContract: ViewOnContract;
};
export type GetContractApiArg = {
  accountId: string;
  contractId: string;
  changeNumber?: number;
};
export type ListContractChangeLogApiResponse = /** status 200 Contract change log - list of change log items */ {
  changelog: ViewOnContractChangeLogItem[];
  nextToken?: NextToken;
};
export type ListContractChangeLogApiArg = {
  accountId: string;
  contractId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type ResolveUserPermissionsOnContractApiResponse = /** status 200 Contract permissions */ {
  permissions: Permissions;
};
export type ResolveUserPermissionsOnContractApiArg = {
  accountId: string;
  contractId: string;
};
export type ListContractTasksApiResponse = /** status 200 List of contract tasks */ {
  items?: Task[];
  nextToken?: NextToken;
};
export type ListContractTasksApiArg = {
  accountId: string;
  contractId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type ListContractTasksForUserApiResponse = /** status 200 List of contract tasks */ {
  items?: Task[];
  nextToken?: NextToken;
};
export type ListContractTasksForUserApiArg = {
  accountId: string;
  contractId: string;
  userId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type FulfillUserTaskApiResponse = /** status 200 Object reference urn */ {
  ref: string;
};
export type FulfillUserTaskApiArg = {
  accountId: string;
  taskId: string;
  /** User task result content
   */
  userTaskResult: UserTaskResult;
};
export type ListContractAttachmentsApiResponse = /** status 200 List of attachments */ {
  attachments: AttachmentReference[];
};
export type ListContractAttachmentsApiArg = {
  accountId: string;
  contractId: string;
};
export type ReadContractFileV2ApiResponse = /** status 200 File contents */ string;
export type ReadContractFileV2ApiArg = {
  accountId: string;
  contractId: string;
  fileName: string;
};
export type ScanContractFileV2ApiResponse = /** status 200 Empty, read headers */ void;
export type ScanContractFileV2ApiArg = {
  accountId: string;
  contractId: string;
  fileName: string;
};
export type UploadContractFileV2ApiResponse = /** status 200 File updated response */ string;
export type UploadContractFileV2ApiArg = {
  accountId: string;
  contractId: string;
  fileName: string;
  /** ...
   */
  body: Blob;
};
export type DeleteContractFileV2ApiResponse = /** status 204 Empty, read headers */ void;
export type DeleteContractFileV2ApiArg = {
  accountId: string;
  contractId: string;
  fileName: string;
};
export type GetContractsStatisticsApiResponse = /** status 200 Contracts statistics for given account */ {
  stats: ContractsStatistics;
};
export type GetContractsStatisticsApiArg = {
  accountId: string;
};
export type ListTasksForUserApiResponse = /** status 200 List of contract tasks */ {
  items?: Task[];
  nextToken?: NextToken;
};
export type ListTasksForUserApiArg = {
  accountId: string;
  userId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type ListDepartmentsApiResponse = /** status 200 List of departments response */ {
  departments: Department[];
  nextToken?: NextToken;
};
export type ListDepartmentsApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type CreateDepartmentApiResponse = /** status 201 Created department response */ {
  department: Department;
};
export type CreateDepartmentApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
  /** Create department request */
  createDepartmentRequest: {
    department: DepartmentCreate;
  };
};
export type GetDepartmentApiResponse = /** status 200 Updated department response */ {
  department: Department;
};
export type GetDepartmentApiArg = {
  accountId: string;
  departmentId: string;
};
export type UpdateDepartmentApiResponse = /** status 200 Updated department response */ {
  department: Department;
};
export type UpdateDepartmentApiArg = {
  accountId: string;
  departmentId: string;
  /** Update department request */
  updateDepartmentRequest: {
    department: DepartmentUpdate;
  };
};
export type DeleteDepartmentApiResponse = /** status 200 Deleted department response */ object;
export type DeleteDepartmentApiArg = {
  accountId: string;
  departmentId: string;
};
export type UpdateDepartmentOwnershipApiResponse = /** status 200 Updated department ownership response */ {
  owner?: User;
  watchers?: User[];
};
export type UpdateDepartmentOwnershipApiArg = {
  accountId: string;
  departmentId: string;
  /** Update department ownership request */
  updateDepartmentOwnershipRequest: {
    owner?: NullableUser;
    watchers?: User[];
  };
};
export type AdminGetBuyersBoardDataApiResponse = /** status 200 Contracts for buyers board */ {
  contracts?: BuyersBoardItem[];
  nextToken?: NextToken;
};
export type AdminGetBuyersBoardDataApiArg = {
  nextToken?: string | null;
  category?: 'LIVE' | 'NOT_LIVE_YET' | 'EXPIRED' | 'TERMINATED' | 'WORKFLOW_IN_PROGRESS';
  maxResults?: number;
  /** Filters for buyers board.
    Supported filters:
      - includeSmallContracts - boolean - include contracts bellow 5k USD. Default value is false. This filter applies only on LIVE, NOT_LIVE_YET and ROLLING contract states
      - minPrice - number - minimal value of contract to display. The currency is ignored here
      - maxPrice - number - maximal value of contract to display. The currency is ignored here
      - csm - string - filter contracts by specified Customer Success Manager
     */
  filters?: string;
};
export type AdminGetContractAssociationsApiResponse = /** status 200 Contract associations response */ {
  associations?: {
    buyer?: string;
  };
};
export type AdminGetContractAssociationsApiArg = {
  accountId: string;
  contractId: string;
};
export type AdminStoreContractAssociationsApiResponse = /** status 201 Contract associations response */ {
  associations?: {
    buyer?: string;
  };
};
export type AdminStoreContractAssociationsApiArg = {
  accountId: string;
  contractId: string;
  /** Update contract associations */
  body: {
    associations?: {
      buyer?: string | null;
    };
  };
};
export type AdminGetUpcomingContractsApiResponse = /** status 200 Upcoming contracts response */ {
  contracts?: UpcomingContractItem[];
};
export type AdminGetUpcomingContractsApiArg = {
  threshold?: string;
  includeAll?: boolean;
};
export type GetSavingsDashboardApiResponse = /** status 200 Get savings dashboard table response */ {
  contracts: ViewOnContract[];
  nextToken?: NextToken;
};
export type GetSavingsDashboardApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type ListVendorsPageContractsApiResponse = /** status 200 List of contracts in vendors page */ {
  contracts: ViewOnContractForVendorsPage[];
  nextToken?: NextToken;
};
export type ListVendorsPageContractsApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
  vendorId?: string;
};
export type GetCustomContractFieldsApiResponse = /** status 200 Custom contract fields
 */ {
  changeNumber: number;
  customFields: CustomContractFieldSpec[];
};
export type GetCustomContractFieldsApiArg = {
  accountId: string;
};
export type UpdateCustomContractFieldsApiResponse = /** status 200 Custom contract fields
 */ {
  changeNumber: number;
  customFields: CustomContractFieldSpec[];
};
export type UpdateCustomContractFieldsApiArg = {
  accountId: string;
  /** Update custom contract fields request */
  updateCustomContractFieldsRequest: {
    changeNumber: number;
    customFields: CustomContractFieldSpec[];
  };
};
export type ListCustomizationViewsApiResponse = /** status 200 List of customization views */ {
  views: CustomizationView[];
  nextToken?: NextToken;
};
export type ListCustomizationViewsApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
};
export type CreateCustomizationViewApiResponse = /** status 201 Newly created customization view */ {
  view: CustomizationView;
};
export type CreateCustomizationViewApiArg = {
  accountId: string;
  nextToken?: string | null;
  maxResults?: number;
  /** Create customization view request */
  createCustomizationViewRequest: {
    view: CustomizationViewInput;
  };
};
export type GetCustomizationViewApiResponse = /** status 200 Existing customization view */ {
  view: CustomizationView;
};
export type GetCustomizationViewApiArg = {
  accountId: string;
  viewId: Identifier;
  changeNumber?: number;
};
export type UpdateCustomizationViewApiResponse = /** status 200 Updated customization view */ {
  view: CustomizationView;
};
export type UpdateCustomizationViewApiArg = {
  accountId: string;
  viewId: Identifier;
  changeNumber?: number;
  /** Update customization view request */
  updateCustomizationViewRequest: {
    view: CustomizationViewInput;
  };
};
export type DeleteCustomizationViewApiResponse = /** status 200 Deleted customization view */ object;
export type DeleteCustomizationViewApiArg = {
  accountId: string;
  viewId: Identifier;
  changeNumber?: number;
};
export type GetCustomListFiltersConfigurationApiResponse = /** status 200 Custom contract list filters configuration
 */ {
  filters?: CustomListFilterSpec[] | null;
};
export type GetCustomListFiltersConfigurationApiArg = {
  accountId: string;
  userId: string;
};
export type UpdateCustomListFiltersConfigurationApiResponse = /** status 200 Custom contract list filters configuration
 */ {
  filters?: CustomListFilterSpec[] | null;
};
export type UpdateCustomListFiltersConfigurationApiArg = {
  accountId: string;
  userId: string;
  /** Update custom list filters configuration request */
  updateCustomListFiltersConfigurationRequest: {
    filters: CustomListFilterSpec[];
  };
};
export type NextToken = string | null;
export type Request = {
  ref: string;
  name: string;
  type: string;
  status: 'PENDING' | 'ACTIVE' | 'COMPLETED' | 'CANCELLED' | 'TERMINATED';
  createdAt: string;
  createdBy: string;
  lastActivityAt?: string;
  closedAt?: string;
  /** Contract's ID if is available in request input.
   */
  contractId?: string;
  vendor?:
    | {
        type: 'PREDEFINED';
        id: string;
        name?: string;
      }
    | {
        type: 'INLINE';
        id?: string;
        name: string;
      };
};
export type RequestType = 'PURCHASE' | 'RENEWAL';
export type PurchaseRequestInput = {
  /** * `PREDEFINED` - Vendor comes from Vertice database of vendors, referenced by required property `vendorId`.
   * `INLINE` - Vendor is defined by customer during contract creation and currently unknown to Vertice. `vendorId` is missing but `vendorName` is required.
   */
  vendorType: 'PREDEFINED' | 'INLINE';
  /** Vendor id. Should be provided if `vendorType` is `PREDEFINED`. */
  vendorId?: string | null;
  /** Vendor name. Should be provided if `vendorType` is `INLINE`. */
  vendorName?: string | null;
  [key: string]: any;
};
export type RenewalRequestInput = {
  parentContractId: string;
  [key: string]: any;
};
export type CreateRequest = {
  type: RequestType;
  name: string;
  input: PurchaseRequestInput | RenewalRequestInput;
};
export type ContractCategory = 'SAAS' | 'NON_SAAS';
export type ContractOrigin =
  | 'EXISTING'
  | 'RENEWAL_WITH_VERTICE'
  | 'RENEWAL_WITHOUT_VERTICE'
  | 'PURCHASE_WITH_VERTICE'
  | 'PURCHASE_WITHOUT_VERTICE'
  | 'UNKNOWN';
export type Tag = {
  tagId?: string;
  tagLabel?: string;
  value?: string;
};
export type RecordStatus = 'ACTIVE' | 'INACTIVE' | 'ARCHIVED' | 'DELETED';
export type ContractRecord = {
  accountId?: string;
  contractId: string;
  lineageId?: string;
  contractType: string;
  contractCategory?: ContractCategory;
  contractOrigin?: ContractOrigin;
  tags?: Tag[];
  status: RecordStatus;
  createdAt?: string;
  createdBy?: string;
  changeNumber?: number;
  /** Urn of managing entity */
  managedBy?: string;
};
export type ContractLifecycle = 'INTAKE' | 'NOT_YET_LIVE' | 'LIVE' | 'KAPUT';
export type RollFrequency = 'MONTHLY' | 'QUARTERLY' | 'ANNUALLY' | 'BIANNUALLY' | 'NO';
export type CancellationReason =
  | 'BUDGET'
  | 'LEGAL'
  | 'SECURITY'
  | 'LACK_OF_FEATURES'
  | 'MOVING_TO_COMPETITOR'
  | 'OTHER';
export type ContractPartContractualLifecycle = {
  startDate?: string;
  endDate?: string;
  renewalDate?: string;
  autoRenewalDeadline?: string;
  stage?: ContractLifecycle;
  rollFrequency?: RollFrequency;
  cancellationReason?: CancellationReason;
  cancellationNote?: string;
  cancellationDate?: string;
};
export type ContractPartContractualRenewal = {
  markedForExpiration?: boolean;
  autoRenewalEnabled?: boolean;
  autoRenewalWaived?: boolean | null;
};
export type BillingFrequency = 'MONTHLY' | 'QUARTERLY' | 'ANNUALLY' | 'BIANNUALLY' | 'ONE_OFF' | 'OTHER';
export type ContactPerson = {
  name?: string;
  email?: string;
  phone?: string;
  role?: string;
};
export type PaymentTerms = 'NET_15' | 'NET_30' | 'NET_45' | 'NET_60' | 'NET_90' | 'OTHER';
export type ContractPartContractualFinancial = {
  baseCurrency?: string;
  billingFrequency?: BillingFrequency;
  billingFrequencyOther?: string;
  billingContact?: ContactPerson;
  paymentTerms?: PaymentTerms;
  paymentTermsOther?: string;
};
export type Address = {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};
export type ContractPartContractualSignatorySigningEntity = {
  name?: string;
  address?: Address;
};
export type ContractPartContractualSignatory = {
  signer?: ContactPerson;
  signingEntity?: ContractPartContractualSignatorySigningEntity;
  signingDate?: string;
};
export type VendorType = 'PREDEFINED' | 'INLINE';
export type ContractPartContractualVendor = {
  vendorType: VendorType;
  vendorId?: string;
  vendorName?: string;
  vendorContact?: ContactPerson;
  purchasedFromReseller?: boolean;
  resellerName?: string;
};
export type Identifier = string;
export type ProductLicenseType = {
  id: Identifier;
  label?: string | null;
};
export type ContractPartContractualProducts = {
  productId?: string;
  productName?: string | null;
  licenseCount?: number;
  licenseType?: ProductLicenseType;
  annualCost?: number;
}[];
export type ContractPartContractual = {
  lifecycle?: ContractPartContractualLifecycle;
  renewal?: ContractPartContractualRenewal;
  financial?: ContractPartContractualFinancial;
  signatory?: ContractPartContractualSignatory;
  vendor?: ContractPartContractualVendor;
  products?: ContractPartContractualProducts;
};
export type ContractPartOverview = {
  contractLength?: number;
  annualCostWithoutVertice?: number;
  totalCostValueWithoutVertice?: number;
  annualCostWithVertice?: number;
  totalCostValueWithVertice?: number;
  effectiveAnnualCost?: number;
  effectiveTotalCostValue?: number;
  realizedConcessions?: number;
  unrealizedConcessions?: number;
  scopeReductionSavings?: number;
  annualSavings?: number;
  totalCostValueSavings?: number;
  totalSavings?: number;
};
export type ContractModel = {
  id: string;
  class: string;
  version: string;
  /** Check supported model schemas and their specifications.
    This is a generic definition of a workflow element.
    Full definition is provided as JSONSchema Draft v07 and can be found in the same repository.
    This object should be fully replaced by the JSONSchema definition, so /kind = JSONModel:/kind, etc.
    Do not start from /model, start from root.
     */
  model?: {
    [key: string]: any;
  } | null;
};
export type ContractPartModelCostConfiguration = {
  withoutVertice?: ContractModel;
  withVertice?: ContractModel;
};
export type ContractPartModelCost = {
  configuration?: ContractPartModelCostConfiguration;
};
export type ContractPartModel = {
  cost?: ContractPartModelCost;
};
export type ContractParts = {
  contractual?: ContractPartContractual;
  overview?: ContractPartOverview;
  model?: ContractPartModel;
};
export type VendorColabDetailsRagStatus = ('RED' | 'AMBER' | 'GREEN') | null;
export type VendorColabDetailsOutcome =
  | ('SIGNED_NDA' | 'SUPPLIER_BACKED_DOWN' | 'PENDING' | 'BACKGROUND_SUPPORT')
  | null;
export type VendorColabDetails = {
  ragStatus?: VendorColabDetailsRagStatus;
  outcome?: VendorColabDetailsOutcome;
  /** Customer sentiment of the vendor collaboration
   */
  customerSentiment?: number;
  /** Notes of the vendor collaboration
   */
  notes?: string;
};
export type ContractAdditionalDetails = {
  /** RESTRICTED */
  internal?: {
    notes?: string;
    vendorColabDetails?: VendorColabDetails;
  };
  external?: {
    notes?: string;
  };
};
export type ContractEntityCategory = 'SAAS' | 'OTHER';
export type ContractClassification = {
  region?: string;
  departmentId?: string;
  category?: ContractEntityCategory;
  departmentOwner?: {
    userId: string;
  };
  departmentWatchers?: {
    userId: string;
  }[];
  contractOwner?: {
    userId: string;
  };
  contractWatchers?: {
    userId: string;
  }[];
  assignedPuMa?: {
    userId: string;
  };
};
export type LinkType = 'PREV' | 'NEXT';
export type LinkRecord = {
  linkedContractId: string;
  linkType: LinkType;
};
export type RenewalType = 'FLAT' | 'UPGRADE' | 'DOWNGRADE';
export type ContractPartWorkflow = {
  hasActiveRenewalRequest?: boolean;
  stage?: string;
  cancellationReason?: CancellationReason;
  cancellationNote?: string;
  /** RESTRICTED */
  internalDocuments?: {
    openDate?: string;
    closeDate?: string;
    introducedInsideAutoRenewal?: boolean;
    listPrice?: number;
    estimatedManHours?: number;
    estimatedNumberOfTouchesCall?: number;
    estimatedNumberOfTouchesEmailMessage?: number;
    mostSeniorContactEngaged?: string;
    contactRole?: string;
    knownContact?: boolean;
    numberOfOffersReceived?: number;
    leversUsed?: string;
    competitionUsed?: string;
    [key: string]: any;
  };
  externalDocuments?: {
    renewalType?: RenewalType;
    targetSignDate?: string;
    desiredContractLength?: number;
    plannedBudget?: number;
    requestOwner?: string;
    requestOwnerEmail?: string;
    legalReviewRequired?: boolean;
    securityReviewRequired?: boolean;
    piiExpected?: boolean;
    contactVendorDirectly?: boolean;
    multiYear?: boolean;
    caseStudies?: boolean;
    plannedGrowth?: boolean;
    plannedGrowthNotes?: string;
    discussCompetitors?: boolean;
    [key: string]: any;
  };
  [key: string]: any;
};
export type DeadlineOrigin = 'USERDEADLINE' | 'RENEWALDATE' | 'AUTORENEWALDEADLINE' | 'FOLLOWINGVERSIONUSERDEADLINE';
export type SimplifiedStage =
  | (
      | 'DELETED'
      | 'DRAFT'
      | 'NOT_YET_LIVE'
      | 'LIVE'
      | 'EXPIRED_NATURAL'
      | 'EXPIRED_PLANNED'
      | 'EXPIRED_UNINTENTIONAL'
      | 'TERMINATED'
      | 'CANCELLED_FIXED_TERM'
      | 'CANCELLED_REQUEST'
      | 'WORKFLOW_IN_PROGRESS'
    )
  | null;
export type ContractMaterializedLinkedContract = {
  contractId?: Identifier;
  contractOrigin?: ContractOrigin;
  status?: RecordStatus;
  simplifiedStage?: SimplifiedStage;
  workflowStage?: string;
  signingDate?: string;
  renewalDate?: string;
  autoRenewalDeadline?: string;
  autoRenewalWaived?: boolean | null;
  rollFrequency?: string;
  billingFrequency?: string;
  baseCurrency?: string | null;
  overview?: ContractPartOverview;
} | null;
export type MaterializedFlags = {
  alreadyRenewed?: boolean | null;
};
export type ContractMaterialized = {
  renewalDeadline?: {
    deadlineDate?: string;
    origin?: DeadlineOrigin;
  } | null;
  simplifiedStage?: SimplifiedStage;
  next?: ContractMaterializedLinkedContract;
  prev?: ContractMaterializedLinkedContract;
  flags?: MaterializedFlags;
};
export type ContractCustomFields = {
  checkbox01?: boolean | null;
  checkbox02?: boolean | null;
  checkbox03?: boolean | null;
  checkbox04?: boolean | null;
  checkbox05?: boolean | null;
  date01?: string | null;
  date02?: string | null;
  date03?: string | null;
  date04?: string | null;
  date05?: string | null;
  decimal01?: number | null;
  decimal02?: number | null;
  decimal03?: number | null;
  decimal04?: number | null;
  decimal05?: number | null;
  dropdown01?: string | null;
  dropdown02?: string | null;
  dropdown03?: string | null;
  dropdown04?: string | null;
  dropdown05?: string | null;
  text01?: string | null;
  text02?: string | null;
  text03?: string | null;
  text04?: string | null;
  text05?: string | null;
  textarea01?: string | null;
  textarea02?: string | null;
};
export type Contract = {
  record: ContractRecord;
  parts: ContractParts;
  additionalDetails?: ContractAdditionalDetails;
  classification?: ContractClassification;
  lineage?: LinkRecord[];
  workflow?: ContractPartWorkflow;
  materialized?: ContractMaterialized;
  customFields?: ContractCustomFields;
};
export type ContractComputedFieldsUser = {
  type?: 'DEPARTMENT' | 'CONTRACT';
  userId?: string;
};
export type ContractComputedFields = {
  labels?: {
    [key: string]: any;
  };
  owners?: ContractComputedFieldsUser[];
  watchers?: ContractComputedFieldsUser[];
  assignedPuMa?: {
    userId?: string;
  };
  chronologicalLineageOrder?: number;
  costModels?: ContractPartModelCostConfiguration;
};
export type ViewOnContract = {
  contract: Contract;
  next?: Contract;
  prev?: Contract;
  computed?: ContractComputedFields;
};
export type ContractRecordCreate = {
  contractType?: string;
  contractCategory?: ContractCategory;
  contractOrigin?: ContractOrigin;
  status?: RecordStatus;
  /** Urn of managing entity */
  managedBy?: string;
};
export type ContractPartsCreate = {
  contractual?: ContractPartContractual;
  model?: ContractPartModel;
};
export type ContractClassificationCreate = {
  region?: string;
  departmentId?: string;
  category?: ContractEntityCategory;
  contractOwner?: {
    userId: string;
  };
  contractWatchers?: {
    userId: string;
  }[];
  assignedPuMa?: {
    userId: string;
  };
};
export type ContractCreate = {
  record?: ContractRecordCreate;
  parts?: ContractPartsCreate;
  additionalDetails?: ContractAdditionalDetails;
  classification?: ContractClassificationCreate;
  lineage?: LinkRecord[];
  workflow?: ContractPartWorkflow;
  customFields?: ContractCustomFields;
};
export type ContractRecordUpdate = {
  contractType?: string;
  contractCategory?: ContractCategory;
  contractOrigin?: ContractOrigin;
};
export type ContractStageUpdate = ('INTAKE' | 'LIVE' | 'NOT_YET_LIVE' | 'KAPUT') | null;
export type RollFrequencyUpdate = ('MONTHLY' | 'QUARTERLY' | 'ANNUALLY' | 'BIANNUALLY' | 'NO') | null;
export type CancellationReasonUpdate =
  | ('BUDGET' | 'LEGAL' | 'SECURITY' | 'LACK_OF_FEATURES' | 'MOVING_TO_COMPETITOR' | 'OTHER')
  | null;
export type ContractPartContractualLifecycleUpdate = {
  startDate?: string | null;
  endDate?: string | null;
  renewalDate?: string | null;
  autoRenewalDeadline?: string | null;
  stage?: ContractStageUpdate;
  rollFrequency?: RollFrequencyUpdate;
  cancellationReason?: CancellationReasonUpdate;
  cancellationNote?: string | null;
  cancellationDate?: string | null;
};
export type BillingFrequencyUpdate = ('MONTHLY' | 'QUARTERLY' | 'ANNUALLY' | 'BIANNUALLY' | 'ONE_OFF' | 'OTHER') | null;
export type ContactPersonUpdate = {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  role?: string | null;
};
export type PaymentTermsUpdate = ('NET_15' | 'NET_30' | 'NET_45' | 'NET_60' | 'NET_90' | 'OTHER') | null;
export type ContractPartContractualFinancialUpdate = {
  baseCurrency?: string | null;
  billingFrequency?: BillingFrequencyUpdate;
  billingFrequencyOther?: string | null;
  billingContact?: ContactPersonUpdate;
  paymentTerms?: PaymentTermsUpdate;
  paymentTermsOther?: string | null;
};
export type AddressUpdate = {
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
};
export type ContractPartContractualSignatorySigningEntityUpdate = {
  name?: string | null;
  address?: AddressUpdate;
};
export type ContractPartContractualSignatoryUpdate = {
  signer?: ContactPersonUpdate;
  signingEntity?: ContractPartContractualSignatorySigningEntityUpdate;
  signingDate?: string | null;
};
export type ContractPartContractualVendorUpdate = {
  vendorType?: VendorType;
  vendorId?: string | null;
  vendorName?: string | null;
  vendorContact?: ContactPersonUpdate;
  purchasedFromReseller?: boolean | null;
  resellerName?: string | null;
};
export type ContractPartContractualUpdate = {
  lifecycle?: ContractPartContractualLifecycleUpdate;
  renewal?: ContractPartContractualRenewal;
  financial?: ContractPartContractualFinancialUpdate;
  signatory?: ContractPartContractualSignatoryUpdate;
  vendor?: ContractPartContractualVendorUpdate;
  products?: ContractPartContractualProducts;
};
export type ContractPartsUpdate = {
  contractual?: ContractPartContractualUpdate;
  model?: ContractPartModel;
};
export type ContractAdditionalDetailsUpdate = {
  /** RESTRICTED */
  internal?: {
    notes?: string | null;
    vendorColabDetails?: VendorColabDetails;
  };
  external?: {
    notes?: string | null;
  };
};
export type ContractClassificationUpdate = {
  region?: string | null;
  departmentId?: string | null;
  category?: ContractEntityCategory;
  contractOwner?: {
    userId: string;
  };
  contractWatchers?: {
    userId: string;
  }[];
  assignedPuMa?: {
    userId: string;
  };
};
export type RenewalTypeUpdate = ('FLAT' | 'UPGRADE' | 'DOWNGRADE') | null;
export type ContractPartWorkflowUpdate = {
  stage?: string;
  cancellationReason?: CancellationReasonUpdate;
  cancellationNote?: string | null;
  /** RESTRICTED */
  internalDocuments?: {
    openDate?: string | null;
    closeDate?: string | null;
    introducedInsideAutoRenewal?: boolean | null;
    listPrice?: number | null;
    estimatedManHours?: number | null;
    estimatedNumberOfTouchesCall?: number | null;
    estimatedNumberOfTouchesEmailMessage?: number | null;
    mostSeniorContactEngaged?: string | null;
    contactRole?: string | null;
    knownContact?: boolean | null;
    numberOfOffersReceived?: number | null;
    leversUsed?: string | null;
    competitionUsed?: string | null;
  };
  externalDocuments?: {
    renewalType?: RenewalTypeUpdate;
    targetSignDate?: string | null;
    desiredContractLength?: number | null;
    plannedBudget?: number | null;
    requestOwner?: string | null;
    requestOwnerEmail?: string | null;
    legalReviewRequired?: boolean | null;
    securityReviewRequired?: boolean | null;
    piiExpected?: boolean | null;
    contactVendorDirectly?: boolean | null;
    multiYear?: boolean | null;
    caseStudies?: boolean | null;
    plannedGrowth?: boolean | null;
    plannedGrowthNotes?: string | null;
    discussCompetitors?: boolean | null;
  };
  [key: string]: any;
};
export type ContractUpdate = {
  record?: ContractRecordUpdate;
  parts?: ContractPartsUpdate;
  additionalDetails?: ContractAdditionalDetailsUpdate;
  classification?: ContractClassificationUpdate;
  lineage?: LinkRecord[];
  workflow?: ContractPartWorkflowUpdate;
  customFields?: ContractCustomFields;
};
export type ContractChangeLogItem = {
  accountId: string;
  contractId: string;
  correlationId: string;
  principalId: string;
  timestamp: string | null;
  intent: string;
  payload?: {
    [key: string]: any;
  };
  previousData?: {
    [key: string]: any;
  };
};
export type ContractChangeLogItemComputedFields = {
  labels?: {
    [key: string]: any;
  };
};
export type ViewOnContractChangeLogItem = {
  changeLogItem: ContractChangeLogItem;
  computed?: ContractChangeLogItemComputedFields;
};
export type Permissions = {
  r: boolean;
  w: boolean;
  x: boolean;
};
export type Task = {
  /** Reference to the task.
    Example: `123456`
     */
  ref: string;
  /** Status of the task. */
  status: 'PENDING' | 'ACTIVE' | 'COMPLETED' | 'TERMINATED' | 'FAILED';
  /** Task assignees. */
  assignment: string[];
  /** Task name. */
  name?: string;
  /** Workflow instance name. Can */
  workflowInstanceName?: string;
  createdAt?: string;
  /** Tasks input. */
  input?: {
    [key: string]: any;
  };
};
export type UserTaskResult = {
  [key: string]: any;
};
export type DateTime = string | null;
export type AttachmentReference = {
  size: number;
  fileName: string;
  lastModified: DateTime;
  uploadedBy: Identifier;
};
export type Currency = string;
export type Money = {
  amount: number;
  currency: Currency;
};
export type ContractsStatsCounts = {
  /** Total number of contracts */
  total: number;
  /** An object which provides contracts counts for each status. Statues are omitted if there isn't
    any contract with such a status.
     */
  perStage?: {
    [key: string]: number;
  };
};
export type ContractsStatsDepartments = {
  departmentId?: string;
  totalAnnualCost?: Money;
}[];
export type ContractsStatsCategoryItem = {
  counts: ContractsStatsCounts;
  spendUploaded: Money;
  spendExpected?: Money;
  concessionalSavings: Money;
  scopeReductionSavings: Money;
  departments: ContractsStatsDepartments;
};
export type ContractsStatsCategories = {
  all?: ContractsStatsCategoryItem;
  saas: ContractsStatsCategoryItem;
  other?: ContractsStatsCategoryItem;
  [key: string]: any;
};
export type ContractsStatistics = {
  totalSavings: Money;
  /** Average savings over time in % (rounded up without decimals) */
  averageSavingsPercentage: number;
  categories: ContractsStatsCategories;
};
export type DepartmentId = string;
export type DepartmentLabel = string;
export type User = {
  userId: string;
};
export type AssignedContractCount = number;
export type Department = {
  id?: DepartmentId;
  label?: DepartmentLabel;
  owner?: User;
  watchers?: User[];
  assignedContractCount?: AssignedContractCount;
};
export type DepartmentCreate = {
  label?: DepartmentLabel;
};
export type DepartmentUpdate = {
  label?: DepartmentLabel;
};
export type NullableUser = {
  userId: string;
} | null;
export type BuyersBoardContract = {
  lineage?: {
    linkedContractId?: string;
    linkType?: 'PREV' | 'NEXT';
  }[];
  additionalInfo?: string;
  markedForExpiration?: boolean;
  classification?: {
    departmentId?: string;
    [key: string]: any;
  };
  financial?: {
    saving?: number;
    annualCost?: number;
    currency?: string;
    vendorProposedCost?: number;
    [key: string]: any;
  };
  targetGoals?: {
    userDeadline?: string;
    appointmentSchedule?: {
      endDateTime?: string;
      startDateTime?: string;
    };
  };
  terms?: {
    autoRenewalDeadline?: string;
    renewalDate?: string;
    rollingFrequency?: string;
    signDate?: string;
    startDate?: string;
    contractLength?: number;
  };
  vendor?: {
    vendorId?: string;
    vendorProducts?: {
      productId?: string;
      numberOfLicenses?: number;
    }[];
  };
  materialized?: ContractMaterialized;
  [key: string]: any;
};
export type BuyersBoardItem = {
  accountId: string;
  contractChangeNumber?: number;
  accountName?: string;
  accountManager?: string;
  successManager?: string;
  accountSettings?: {
    [key: string]: any;
  };
  contractId: string;
  vendorId?: string;
  vendorName?: string;
  associations?: {
    [key: string]: any;
  };
  contractCategory?: 'SAAS' | 'OTHER';
  state:
    | 'TERMINATED'
    | 'ROLLING'
    | 'CONTRACTING'
    | 'NEGOTIATING'
    | 'LIVE'
    | 'NOT_LIVE_YET'
    | 'APPROVAL'
    | 'EXPIRED'
    | 'REQUIREMENTS_GATHERING'
    | 'PURCHASED'
    | 'REGISTERED'
    | 'RENEWED'
    | 'READY_FOR_LIFECYCLE';
  type: 'EXISTING' | 'PURCHASE' | 'RENEWAL';
  contract?: BuyersBoardContract;
};
export type UpcomingContractItem = {
  accountId: string;
  accountName?: string;
  pumaId?: string;
  vendorId?: string;
  vendorName?: string;
  contractId: string;
  upcomingTimestamp?: string;
  linkedRequest?: string;
};
export type VendorsPageVendor = {
  vendorId?: string;
  vendorName?: string;
};
export type VendorsPageProduct = {
  productId?: string;
  productName?: string | null;
};
export type VendorsPageMaterializedLinkedContract = {
  contractId?: Identifier;
  contractOrigin?: ContractOrigin;
  status?: RecordStatus;
  simplifiedStage?: SimplifiedStage;
} | null;
export type VendorsPageComputedFields = {
  labels?: {
    [key: string]: any;
  };
  owners?: ContractComputedFieldsUser[];
  chronologicalLineageOrder?: number;
};
export type ViewOnContractForVendorsPage = {
  accountId?: Identifier;
  contractId?: Identifier;
  lineageId?: Identifier;
  departmentId?: string;
  contractOrigin?: ContractOrigin;
  markedForExpiration?: boolean;
  workflowStage?: string;
  lineage?: LinkRecord[];
  vendor?: VendorsPageVendor;
  products?: VendorsPageProduct[];
  materialized?: {
    simplifiedStage?: SimplifiedStage;
    next?: VendorsPageMaterializedLinkedContract;
    prev?: VendorsPageMaterializedLinkedContract;
  };
  computed?: VendorsPageComputedFields;
  visibility?: boolean;
};
export type CustomContractFieldModel = {
  /** Id of the field (checkbox01, checkbox02, ...) */
  id: string;
  /** Type of the field */
  type: 'CHECKBOX' | 'TEXT' | 'DECIMAL' | 'DATE' | 'TEXTAREA' | 'DROPDOWN';
  /** Label of the field
   */
  label: string;
  /** Visibility of the field (on the contract detail page) */
  isVisible: boolean;
};
export type CustomContractFieldCheckBoxType = CustomContractFieldModel & {
  type: 'CHECKBOX';
};
export type CustomContractFieldTextType = CustomContractFieldModel & {
  type: 'TEXT';
};
export type CustomContractFieldDecimalType = CustomContractFieldModel & {
  type: 'DECIMAL';
};
export type CustomContractFieldDateType = CustomContractFieldModel & {
  type: 'DATE';
};
export type CustomContractFieldTextAreaType = CustomContractFieldModel & {
  type: 'TEXTAREA';
};
export type CustomContractFieldDropdownOption = {
  /** Id of the option */
  id: string;
  /** Label of the option */
  label: string;
  /** Whether the option is enabled or disabled */
  enabled: boolean;
  /** Order of the option in the `options` list */
  order: number;
};
export type CustomContractFieldDropdownType = CustomContractFieldModel & {
  type: 'DROPDOWN';
} & {
  /** List of options for the dropdown field
   */
  options?: CustomContractFieldDropdownOption[];
};
export type CustomContractFieldSpec =
  | CustomContractFieldCheckBoxType
  | CustomContractFieldTextType
  | CustomContractFieldDecimalType
  | CustomContractFieldDateType
  | CustomContractFieldTextAreaType
  | CustomContractFieldDropdownType;
export type CustomizationViewFilter = {
  key: string;
  value: string[];
};
export type CustomizationViewColumn = {
  key: string;
  order: number;
};
export type CustomizationView = {
  id: Identifier;
  label: string;
  filtersVersion: string;
  filters: CustomizationViewFilter[];
  columns: CustomizationViewColumn[];
};
export type CustomizationViewInput = {
  label: string;
  filtersVersion: string;
  filters: CustomizationViewFilter[];
  columns: CustomizationViewColumn[];
};
export type CustomListFilterSpec = string;
export const {
  useExportContractsAsCsvQuery,
  useLazyExportContractsAsCsvQuery,
  useListRequestsQuery,
  useLazyListRequestsQuery,
  useCreateRequestMutation,
  useListContractsQuery,
  useLazyListContractsQuery,
  useCreateContractMutation,
  useUpdateContractMutation,
  useDeleteContractMutation,
  useGetContractQuery,
  useLazyGetContractQuery,
  useListContractChangeLogQuery,
  useLazyListContractChangeLogQuery,
  useResolveUserPermissionsOnContractQuery,
  useLazyResolveUserPermissionsOnContractQuery,
  useListContractTasksQuery,
  useLazyListContractTasksQuery,
  useListContractTasksForUserQuery,
  useLazyListContractTasksForUserQuery,
  useFulfillUserTaskMutation,
  useListContractAttachmentsQuery,
  useLazyListContractAttachmentsQuery,
  useReadContractFileV2Query,
  useLazyReadContractFileV2Query,
  useScanContractFileV2Mutation,
  useUploadContractFileV2Mutation,
  useDeleteContractFileV2Mutation,
  useGetContractsStatisticsQuery,
  useLazyGetContractsStatisticsQuery,
  useListTasksForUserQuery,
  useLazyListTasksForUserQuery,
  useListDepartmentsQuery,
  useLazyListDepartmentsQuery,
  useCreateDepartmentMutation,
  useGetDepartmentQuery,
  useLazyGetDepartmentQuery,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useUpdateDepartmentOwnershipMutation,
  useAdminGetBuyersBoardDataQuery,
  useLazyAdminGetBuyersBoardDataQuery,
  useAdminGetContractAssociationsQuery,
  useLazyAdminGetContractAssociationsQuery,
  useAdminStoreContractAssociationsMutation,
  useAdminGetUpcomingContractsQuery,
  useLazyAdminGetUpcomingContractsQuery,
  useGetSavingsDashboardQuery,
  useLazyGetSavingsDashboardQuery,
  useListVendorsPageContractsQuery,
  useLazyListVendorsPageContractsQuery,
  useGetCustomContractFieldsQuery,
  useLazyGetCustomContractFieldsQuery,
  useUpdateCustomContractFieldsMutation,
  useListCustomizationViewsQuery,
  useLazyListCustomizationViewsQuery,
  useCreateCustomizationViewMutation,
  useGetCustomizationViewQuery,
  useLazyGetCustomizationViewQuery,
  useUpdateCustomizationViewMutation,
  useDeleteCustomizationViewMutation,
  useGetCustomListFiltersConfigurationQuery,
  useLazyGetCustomListFiltersConfigurationQuery,
  useUpdateCustomListFiltersConfigurationMutation,
} = injectedRtkApi;
