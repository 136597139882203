import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card, {
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
} from '@verticeone/design-system/src/components/Card';
import ToggleSwitch from '@verticeone/design-system/src/components/ToggleSwitch';
import { Stack } from '@mui/material';
import { BenchmarkingPeriods } from '../shared/pricingBenchmark/useGetBenchmarkData';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import Text from '@verticeone/design-system/src/components/Text';
import { PricingBenchmarkCardContent } from './PricingBenchmarkCardContent';

export type PricingBenchmarkCardProps = {
  vendorId: string;
  productId: string;
};

const PricingBenchmarkCard = ({ vendorId, productId }: PricingBenchmarkCardProps) => {
  const { t } = useTranslation();
  const [isMonthly, setIsMonthly] = useState<boolean>(true);
  const period: BenchmarkingPeriods = isMonthly ? BenchmarkingPeriods.MONTHLY : BenchmarkingPeriods.ANNUALLY;

  return (
    <Card minWidth={374} height="100%">
      <CardHeader size="S">
        <CardHeaderTitle
          text={t('PRODUCT.PRICING_BENCHMARK_CARD.TITLE')}
          after={
            <ChipButton size="S" variant="ghost" color="primary" isActive={false}>
              <IconWrapper icon={SettingsSuggestOutlinedIcon} />
              {t('PRODUCT.PRICING_BENCHMARK_CARD.CHIP_TITLE')}
            </ChipButton>
          }
          right={
            <Stack direction="row" gap={2} flexWrap="wrap">
              <Text size="S" color={isMonthly ? 'inactive2' : ''} variant="body-bold">
                {t('PRODUCT.PRICING_BENCHMARK_CARD.ANNUAL_COST')}
              </Text>
              <ToggleSwitch
                checked={isMonthly}
                size="S"
                onChange={(event) => setIsMonthly(event.target.checked)}
                data-testid="pricing-frequency-switch"
              />
              <Text size="S" color={!isMonthly ? 'inactive2' : ''} variant="body-bold">
                {t('PRODUCT.PRICING_BENCHMARK_CARD.MONTHLY_COST')}
              </Text>
            </Stack>
          }
        />
        <CardHeaderDescription>{t('PRODUCT.PRICING_BENCHMARK_CARD.DESCRIPTION')}</CardHeaderDescription>
      </CardHeader>
      <PricingBenchmarkCardContent vendorId={vendorId} productId={productId} period={period} withNumerosity={true} />
    </Card>
  );
};

export default PricingBenchmarkCard;
