import React, { FC } from 'react';
import { RequestRow } from '../../types';
import Text from '@verticeone/design-system/src/components/Text';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';

export const LegacyRequestNameCell: FC<{ name: RequestRow['requestName'] }> = ({ name }) => {
  const { t } = useTranslation();
  return (
    // TODO: ml={2} should be removed when we get the contract owner property for legacy requests
    <Stack direction="row" gap={2} width="100%" alignItems="center" ml={2}>
      <Tooltip title={name} size="S" placement="bottom">
        <Text
          flexShrink={1}
          display={'block'}
          variant="heading"
          size="XS"
          color="text1"
          textOverflow={'ellipsis'}
          overflow={'hidden'}
        >
          {name}
        </Text>
      </Tooltip>
      <Stack>
        <ChipButton variant="ghost" size="S">
          {t('ENTITIES.WORKFLOW_REQUEST.LABELS.LEGACY_REQUEST')}
        </ChipButton>
      </Stack>
    </Stack>
  );
};
