import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import React, { useCallback, useMemo, useState } from 'react';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import {
  dayInMonthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import Highcharts, { Chart as HighchartsChart, SeriesOptionsType } from 'highcharts';
import { LegendItemData } from '@vertice/core/src/components/charts/components/Legend/types';
import extractLegendItemsFromSeries from '@vertice/core/src/components/charts/highcharts-specific/utils/extractLegendItemsFromSeries';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import HighchartsReact from 'highcharts-react-official';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import { ChartData } from './useSavingsPlanUtilizationData';
import { sortTooltipPointsFunction } from '@vertice/core/src/components/charts/components/Tooltip/sortTooltipPointsUtils';
import extractTooltipContent, {
  TooltipItem,
} from '@vertice/core/src/components/charts/components/Tooltip/extractTooltipContent';
import { currencyFormatter } from '@vertice/core/src/components/charts/components/Tooltip/valueFormatters';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import EmptyStateError from '@vertice/dashboard/src/modules/cloud/components/EmptyStateError';
import Loader from '@verticeone/design-system/src/components/Loader';
import EmptyStateNoData from '@vertice/dashboard/src//modules/cloud/components/EmptyStateNoData';

const CHART_HEIGHT = 350;

type LoadableContentWrapperProps = {
  error: unknown;
  isEmpty: boolean;
  isLoading: boolean;
  children: React.ReactNode;
};

const LoadableContentWrapper = ({ error, isEmpty, isLoading, children }: LoadableContentWrapperProps) => {
  const { palette } = useTheme();

  return (
    <Stack
      bgcolor={isEmpty || !!error ? 'transparent' : palette.core.color1}
      padding={6}
      justifyContent="center"
      minHeight={CHART_HEIGHT}
    >
      {!!error ? (
        <EmptyStateError />
      ) : isLoading ? (
        <Loader color={AWS_BRAND_COLOR} />
      ) : isEmpty ? (
        <EmptyStateNoData />
      ) : (
        children
      )}
    </Stack>
  );
};

type InnerChartProps = {
  data: ChartData;
};

type ChartProps = Omit<LoadableContentWrapperProps, 'children'> & {
  data?: ChartData;
};

const InnerChart = ({ data }: InnerChartProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.SP_INVENTORY.DRAWER.CHART.LEGEND' });
  const { palette } = useTheme();
  const { locale } = useLocaleContext();

  const applyXAxisOffset = useXAxisOffset();
  const applyColumnHover = useStackedColumnHover();
  const applyStyledHighcharts = useStyledHighcharts();

  const { usedCommitment, unusedCommitment, dates, usedCategories } = data;

  const series = useMemo(
    () => [
      {
        id: 'usedCommitment',
        name: t('USED_COMMITMENT'),
        type: 'column',
        color: palette.visualization.divergent.secondary['+20'],
        states: {
          hover: { color: palette.visualization.divergent.secondary['+50'] },
        },
        data: usedCommitment,
      },
      {
        id: 'unusedCommitment',
        name: t('UNUSED_COMMITMENT'),
        type: 'column',
        color: palette.visualization.divergent.secondary['-20'],
        states: {
          hover: { color: palette.visualization.divergent.secondary['-50'] },
        },
        data: unusedCommitment,
      },
    ],
    [palette, t, unusedCommitment, usedCommitment]
  );

  const options = useMemo(
    () =>
      buildOptions([
        applyColumnHover,
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: { type: 'column', height: 280, backgroundColor: 'transparent' },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: { fillColor: palette.core.color6 },
                },
              },
            },
          },
          yAxis: {
            labels: { formatter: yLabelCurrencyFormatter(palette, locale, AWS_DEFAULT_CURRENCY, 2, 2) },
          },
          xAxis: {
            categories: dates,
            labels: { formatter: dayInMonthFormatter },
          },
          tooltip: { shared: true },
          series: getSeriesWithBorderRadius(series, 8) as SeriesOptionsType[],
        }),
      ]),
    [applyColumnHover, applyStyledHighcharts, applyXAxisOffset, palette, locale, dates, series]
  );

  const [chart, setChart] = useState<HighchartsChart | null>(null);
  const saveChartRef = useCallback((ch: HighchartsChart) => setChart(ch), []);

  const legendItems = useMemo<LegendItemData[]>(
    () => extractLegendItemsFromSeries(options, usedCategories),
    [options, usedCategories]
  );

  const tooltipSort = sortTooltipPointsFunction(usedCategories, true);
  const tooltipExtractor = extractTooltipContent(tooltipSort, currencyFormatter(locale, AWS_DEFAULT_CURRENCY, 2));

  return (
    <Stack height={CHART_HEIGHT} bgcolor={palette.core.color1}>
      <Box marginLeft="auto" marginRight={6} marginTop={4}>
        {legendItems.length > 0 && <Legend items={legendItems} />}
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} callback={saveChartRef} />
      <HighchartTooltip chart={chart}>
        {(context) => {
          const tooltipContent = tooltipExtractor(context);
          return (
            <TooltipWrapper>
              {tooltipContent.items?.map((item: TooltipItem, index: number) => (
                <TooltipSeriesValuePair
                  seriesColor={item.color}
                  seriesName={item.name}
                  value={item.value}
                  key={item.name || index}
                />
              ))}
              <TooltipTotal value={tooltipContent.total} />
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </Stack>
  );
};

const Chart = ({ data, error, isEmpty, isLoading }: ChartProps) => {
  return (
    <LoadableContentWrapper isLoading={isLoading} isEmpty={isEmpty} error={error}>
      <InnerChart data={data!} />
    </LoadableContentWrapper>
  );
};

export default Chart;
