import { PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import { ListItem, ListItemText } from '@mui/material';

import Text from '@verticeone/design-system/src/components/Text';

type SetupStepProps = {
  i18nKey: string;
  components?: Record<string, React.ReactElement>;
} & PropsWithChildren;

export const SetupStep = ({ i18nKey, components, children }: SetupStepProps) => (
  <ListItem sx={{ display: 'list-item' }}>
    <ListItemText>
      <Text variant="body-regular" size="S">
        <Trans i18nKey={i18nKey} components={components} />
      </Text>
      {children}
    </ListItemText>
  </ListItem>
);
