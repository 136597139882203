import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import DataGrid, { GridColDef } from '@verticeone/design-system/src/components/DataGrid';
import type { GridRowModel } from '@mui/x-data-grid-pro';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { SPInventoryItem } from '../useSavingsPlanInventoryData';
import { getTableHeight, useDateFormat } from '../utils';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import RowDetailDrawer from './RowDetailDrawer';

const useSPInventoryTableColumns = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.SP_INVENTORY' });
  const dateFormatter = useDateFormat();
  const formatCurrency = useFormatCurrency();

  return [
    {
      field: 'savingsPlanId',
      headerName: t('COLUMN.SAVINGS_PLAN_ID'),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'accountId',
      headerName: t('COLUMN.ACCOUNT_ID'),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'termLengthYears',
      headerName: t('COLUMN.TERM_LENGTH'),
      renderCell: ({ value }) => t('VALUE.YEAR', { count: value }),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'paymentOption',
      headerName: t('COLUMN.PAYMENT_OPTION'),
      renderCell: ({ value }) => t(`VALUE.${value.toUpperCase()}`),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'totalCommitment',
      headerName: t('COLUMN.TOTAL_COMMITMENT'),
      renderCell: ({ value }) => formatCurrency(value, { maximumFractionDigits: 0 }),
      align: 'right',
      headerAlign: 'right',
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'hourlyCommitment',
      headerName: t('COLUMN.HOURLY_COMMITMENT'),
      renderCell: ({ value }) => formatCurrency(value, { maximumFractionDigits: 3 }),
      align: 'right',
      headerAlign: 'right',
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: t('COLUMN.START_DATE'),
      renderCell: ({ value }) => dateFormatter(value, { dateStyle: 'medium' }),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      minWidth: 130,
    },
    {
      field: 'endDate',
      headerName: t('COLUMN.EXPIRATION_DATE'),
      renderCell: ({ value }) => dateFormatter(value, { dateStyle: 'medium' }),
      align: 'left',
      headerAlign: 'left',
      editable: false,
      minWidth: 160,
    },
  ] as GridColDef<SPInventoryItem>[];
};

const HEADER_HEIGHT = 48;
const ITEM_ROW_HEIGHT = 60;

type SPInventoryTableProps = {
  data: SPInventoryItem[];
};

const SPInventoryTable = ({ data }: SPInventoryTableProps) => {
  const [drawerData, setDrawerData] = useState<SPInventoryItem | null>(null);
  const columns = useSPInventoryTableColumns();
  const { palette } = useTheme();

  const getRowId = (row: GridRowModel) => row.savingsPlanId;

  const height = getTableHeight(data);

  return (
    <Box bgcolor={palette.core.color1} height={height}>
      {drawerData && <RowDetailDrawer data={drawerData!} onClose={() => setDrawerData(null)} isOpen={true} />}
      <DataGrid
        rows={data || []}
        noBorder
        noBorderRadius
        columns={columns}
        getRowId={getRowId}
        sortingMode="client"
        hideFooter
        onRowClick={(props) => {
          setDrawerData(props.row);
        }}
        getRowClassName={() => 'pointer'}
        rowHeight={ITEM_ROW_HEIGHT}
        columnHeaderHeight={HEADER_HEIGHT}
        showCellVerticalBorder
        showColumnVerticalBorder
        disableRowSelectionOnClick
        autoHeight={false}
        color={AWS_BRAND_COLOR}
      />
    </Box>
  );
};

export default SPInventoryTable;
