import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useCallback, useState } from 'react';
import { formatUserRef, parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useCreateRequestMutation } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useWaitForRequestReady } from './useWaitForRequestReady';
import { useLoggedUser } from '@verticeone/auth/src';

export type RenewalVendorType = 'PREDEFINED' | 'INLINE';

export type RenewalVendor = {
  /** Vendor id. Should be provided if `vendorType` is `PREDEFINED`. */
  id?: string | null;
  /** Vendor name. Should be provided if `vendorType` is `INLINE`. */
  name?: string | null;
  type?: RenewalVendorType;
};

export type RenewalProduct = {
  id?: string;
  name?: string;
  productId?: string;
  annualCost?: number;
  numberOfLicences?: number;
};

export type CreateRenewalRequestParams = {
  contractId: string;
  products?: RenewalProduct[];
  vendor: RenewalVendor;
  source: string;
  department?: string;
};

export const useCreateRenewalRequest = () => {
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const [inProgress, setInProgress] = useState(false);
  const [createRequestMutation] = useCreateRequestMutation();
  const waitForRequestReady = useWaitForRequestReady();

  const createRenewalRequest = useCallback(
    async ({ source, ...renewalInput }: CreateRenewalRequestParams): Promise<string | undefined> => {
      try {
        setInProgress(true);

        const createRequestResponse = await createRequestMutation({
          accountId,
          createRequest: {
            sourceRef: source,
            serviceRef: `urn:verticeone:vertice:${accountId}:services:service/saas/renewal/generic/v4`,
            name: `Renewal - ${renewalInput.vendor.name}`,
            input: renewalInput,
            owner: formatUserRef(userId),
          },
        });

        const requestReference = 'data' in createRequestResponse ? createRequestResponse.data?.ref : undefined;
        if (requestReference) {
          const requestId = parseRequestRef(requestReference).requestId;
          await waitForRequestReady(accountId, requestId);
          return requestId;
        }
        return undefined;
      } finally {
        setInProgress(false);
      }
    },
    [userId, createRequestMutation, accountId, waitForRequestReady]
  );

  return {
    createRenewalRequest,
    isCreatingRenewalRequest: inProgress,
  };
};
