import React from 'react';
import { upperFirst } from 'lodash';
import { useTheme } from '@mui/material';
import ChipButton from '@verticeone/design-system/src/components/ChipButton';

type Effort = 'LOW' | 'MEDIUM' | 'HIGH';

type EffortChipProps = {
  effort?: Effort;
};

const EffortChip = ({ effort }: EffortChipProps) => {
  const { palette } = useTheme();

  const colorByEffort: Record<Effort, string> = {
    LOW: palette.global.color.magenta['50'].main,
    MEDIUM: palette.global.color.magenta['70'].main,
    HIGH: palette.global.color.magenta['90'].main,
  } as const;

  if (effort) {
    return (
      <ChipButton
        isActive={false}
        size="S"
        variant="solid"
        color="tertiary"
        sx={{ backgroundColor: colorByEffort[effort] }}
      >
        {upperFirst(effort.toLowerCase())}
      </ChipButton>
    );
  }

  return null;
};

export default EffortChip;
