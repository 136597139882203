import { Divider, Stack, useTheme } from '@mui/material';
import ColorSquare from '@vertice/core/src/components/charts/components/ColorSquare';
import Text from '@verticeone/design-system/src/components/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { BENCHMARKING_CURRENCY } from './constants';
import { isNil } from 'lodash';
import { BenchmarkingPeriods } from './useGetBenchmarkData';

export type TooltipContentProps = {
  context: Highcharts.TooltipFormatterContextObject;
  period: BenchmarkingPeriods;
};

type CurrencyItemProps = {
  label: string;
  value?: number;
};

const CurrencyItem = ({ label, value }: CurrencyItemProps) => {
  const formatCurrency = useFormatCurrency();
  const formattedValue = !isNil(value) ? formatCurrency(value, { currency: BENCHMARKING_CURRENCY }) : '-';

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Text variant="body-regular" size="M">
        {label}
      </Text>
      <Text variant="body-regular" size="M" sx={{ opacity: 0.6 }}>
        {formattedValue}
      </Text>
    </Stack>
  );
};

const TooltipContent = ({ context, period }: TooltipContentProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Stack minWidth={250} gap={2}>
      <Stack direction="row" gap={2} alignItems="center">
        <ColorSquare color={context.color as string} />
        <Text variant="body-bold" size="M">
          {context.series.name}
        </Text>
      </Stack>
      <Divider sx={{ borderColor: palette.core.color4 }} />
      <Stack gap={1}>
        <Text variant="body-regular" size="M" sx={{ opacity: 0.6 }}>
          {t(
            'PRODUCT.PRICING_BENCHMARK_CARD.Y_AXIS_TITLE_' +
              (period === BenchmarkingPeriods.MONTHLY ? 'MONTHLY' : 'ANNUALLY')
          )}
        </Text>
        <CurrencyItem label={t('PRODUCT.PRICING_BENCHMARK_CARD.MIN')} value={context.point.low} />
        <CurrencyItem label={t('PRODUCT.PRICING_BENCHMARK_CARD.MOST_COMMON')} value={(context.point as any).median} />
        <CurrencyItem label={t('PRODUCT.PRICING_BENCHMARK_CARD.MAX')} value={context.point.high} />
      </Stack>
      {!isNil((context.point as any).youAreHere) && (
        <>
          <Divider sx={{ borderColor: palette.core.color4 }} />
          <CurrencyItem
            label={t('PRODUCT.PRICING_BENCHMARK_CARD.YOU_ARE_PAYING')}
            value={(context.point as any).youAreHere}
          />
        </>
      )}
    </Stack>
  );
};

export default TooltipContent;
