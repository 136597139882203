import { Stack } from '@mui/material';
import { chain } from 'lodash';

import { isNotNil } from '@vertice/core/src/utils/validation/isNotNil';
import {
  AlarmLastDataUpdate,
  AlarmDay,
  AlarmStatus,
} from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { LastFetchedTooltipItem } from './LastFetchedTooltipItem';

type LastFetchedTooltipProps = {
  accessLogs: AlarmLastDataUpdate[];
  alarmsPerDay?: AlarmDay[];
  providers?: string[];
};

export const LastFetchedTooltip = ({ accessLogs, alarmsPerDay, providers }: LastFetchedTooltipProps) => {
  const providersWithFailedAlarms = chain(alarmsPerDay)
    .flatMap(({ alarms }) =>
      alarms?.filter((alarm) => alarm.status === AlarmStatus.Failed).map((alarm) => alarm.providerName)
    )
    .filter(isNotNil)
    .uniq()
    .value();

  providers?.forEach((provider) => {
    const isProviderInAccessLogs = accessLogs.some((logItem) => logItem.providerName === provider);
    if (!isProviderInAccessLogs) accessLogs.push({ providerName: provider, updatedAt: '' });
  });

  return (
    <Stack gap={1}>
      {accessLogs.map((logItem) => (
        <LastFetchedTooltipItem
          logItem={logItem}
          providersWithFailedAlarms={providersWithFailedAlarms}
          key={logItem.providerName}
        />
      ))}
    </Stack>
  );
};
