import React, { FC } from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '../../../../../utils/formatting/currency';
import { useFormatDate } from '../../../../../utils/formatting/date';
import { Stack } from '@mui/material';
import Text, { EllipsisText } from '@verticeone/design-system/src/components/Text';
import VendorLogo from '../../../../../components/VendorLogo';
import { ContractOption } from './types';

const getContractVendorId = (contract: Contract): string | undefined => {
  return contract.parts.contractual?.vendor?.vendorId;
};

export const ContractSelectItem: FC<{ option: ContractOption }> = ({ option: { contract, dateToDisplay } }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const formatDate = useFormatDate();
  const annualCost = contract.parts.overview?.effectiveAnnualCost;

  return (
    <Stack direction="row" gap={4} alignItems="center">
      <Stack width="20px" flexShrink={0}>
        <VendorLogo vendorId={getContractVendorId(contract)} />
      </Stack>
      <Stack width="250px" flexGrow={0}>
        <Stack direction="row" gap={1}>
          <Text variant="body-regular" color="color1">
            {contract.parts.contractual?.vendor?.vendorName}
          </Text>
        </Stack>
        <EllipsisText variant="body-bold" size="S" color="text3">
          {contract.parts.contractual?.products?.map((p) => p.productName).join(', ')}
        </EllipsisText>
      </Stack>
      <Stack width="150px" flexGrow={0}>
        {dateToDisplay.date ? (
          <>
            <Text variant="body-regular">{formatDate(dateToDisplay.date)}</Text>
            <Text variant="body-bold" color="text3" size="S">
              {t(`ENTITIES.CONTRACT.LABELS.${dateToDisplay.translationKey}`)}
            </Text>
          </>
        ) : (
          <Text variant="body-regular">-</Text>
        )}
      </Stack>
      <Stack width="150px" flexGrow={0}>
        <Text variant="body-regular">
          {' '}
          {annualCost
            ? formatCurrency(annualCost, { currency: contract.parts.contractual?.financial?.baseCurrency })
            : '-'}
        </Text>
        <Text variant="body-bold" color="text3" size="S">
          {t('ENTITIES.CONTRACT.LABELS.ANNUAL_COST')}
        </Text>
      </Stack>
    </Stack>
  );
};
