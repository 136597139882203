import { useQuery } from '@tanstack/react-query';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { type TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { type DataSource, useDataSource } from '../../DataSourceProvider';

export type DataColumns =
  | 'resource_cost'
  | 'sp_usage_percentage'
  | 'on_demand_usage_percentage'
  | 'sp_utilization_percentage'
  | 'savings_achieved'
  | 'savings_found_from'
  | 'savings_found_to';

const dataSources: Record<DataSource, string> = {
  SageMaker: 'cco_view_sprio_sage_maker_summary_v1',
};

const SPRIOOverviewWidgetQuery = graphql(`
  query SageMakerSPRIO($accountId: String!, $dataSource: String!) {
    athenaViewQuery(params: { accountId: $accountId, name: $dataSource, parameters: ["{accountId}"] }) {
      __typename
      ... on DataTableResult {
        table(
          columns: [
            "resource_cost"
            "sp_usage_percentage"
            "on_demand_usage_percentage"
            "sp_utilization_percentage"
            "savings_achieved"
            "savings_found_from"
            "savings_found_to"
          ]
        ) {
          columns
          data
          dataTypes
        }
      }
      ... on ErroredQueryResult {
        error
      }
      ... on DeferredQueryResult {
        __typename
      }
    }
  }
`);

export const useSPRIOOverviewWidgetData = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();
  const { datasource } = useDataSource();

  return useQuery({
    queryKey: ['SPRIOOverviewWidget'],
    queryFn: () => fetchCloudOptimization(SPRIOOverviewWidgetQuery, { accountId, dataSource: dataSources[datasource] }),
    enabled: !!accountId,
    refetchInterval: (data) => (data.state.data?.athenaViewQuery?.__typename === 'DeferredQueryResult' ? 2000 : false),
    select: (data) => {
      if (data.athenaViewQuery?.__typename === 'DataTableResult') {
        return (getTableData(data.athenaViewQuery.table as TableType)?.[0] as Record<DataColumns, number>) ?? null;
      }

      return null;
    },
  });
};
