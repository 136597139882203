import { ResourceThumbnail } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ChipButton from '@verticeone/design-system/src/components/ChipButton';

import SaasRenewalThumbnail from './predefinedThumbnails/saas-renewal.svg';
import SaasNewPurchaseThumbnail from './predefinedThumbnails/saas-new-purchase.svg';
import NdaAgreementThumbnail from './predefinedThumbnails/nda-agreement.svg';
import ItSecurityReviewThumbnail from './predefinedThumbnails/it-security-review.svg';
import RfxPlaybookThumbnail from './predefinedThumbnails/rfx-playbook.svg';
import HireExternalContractorThumbnail from './predefinedThumbnails/hire-external-contractor.svg';
import TriageThumbnail from './predefinedThumbnails/triage.svg';
import NegotiationThumbnail from './predefinedThumbnails/negotiation.svg';

type ThumbnailDefinition = ResourceThumbnail | undefined;

const ThumbnailIdImageMap: Record<string, string> = {
  'saas-renewal': SaasRenewalThumbnail,
  'saas-purchase': SaasNewPurchaseThumbnail,
  'nda-agreement': NdaAgreementThumbnail,
  'it-security-review': ItSecurityReviewThumbnail,
  'rfx-playbook': RfxPlaybookThumbnail,
  'hire-external-contractor': HireExternalContractorThumbnail,
  negotiation: NegotiationThumbnail,
  triage: TriageThumbnail,
};

const StyledThumbnail = styled(Stack)<{ $thumbnail: ThumbnailDefinition }>(({ $thumbnail }) => ({
  borderRadius: 8,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '100%',
  ...($thumbnail?.Type === 'PREDEFINED'
    ? {
        backgroundImage: `url(${ThumbnailIdImageMap[$thumbnail.Id]})`,
      }
    : {
        backgroundColor: 'lightgray',
      }),
}));

type ThumbnailProps = {
  thumbnail: ThumbnailDefinition;
  inactive?: boolean;
  chipText?: string;
};

export const Thumbnail: FC<ThumbnailProps> = ({ thumbnail, inactive }) => {
  const { t } = useTranslation();
  return (
    <Stack height={160} position="relative">
      <StyledThumbnail $thumbnail={thumbnail}></StyledThumbnail>
      {inactive && (
        <Stack left={8} bottom={8} position="absolute">
          <ChipButton isActive={false} color="info" variant="solid" size="XS">
            {t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.TEMPLATES.COMING_SOON')}
          </ChipButton>
        </Stack>
      )}
    </Stack>
  );
};
