import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ProductColDef, ProductItem, ProductListColumn } from '../types';
import assertExhausted from '../../../../../../utils/assertExhausted';
import Text from '@verticeone/design-system/src/components/Text';
import { AnnualCostCell, EditableAnnualCostCell, EditableLicensesCell } from '../cells';
import TierCell from '../cells/TierCell';
import EditableTierCell from '../cells/EditableTierCell';
import { valueComparator, tierComparator } from '../utils';

export const useProductExtraColumnBuilder = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTAKE_FORM.PRODUCTS_TABLE' });

  const buildColumn = (column: ProductListColumn): ProductColDef | null => {
    switch (column) {
      case ProductListColumn.LICENSE_TYPE:
        return {
          field: 'licenseType',
          headerName: t('TIER'),
          renderCell: (params: GridRenderCellParams<ProductItem>) => <TierCell {...params} />,
          renderEditCell: (params: GridRenderCellParams) => <EditableTierCell {...params} />,
          sortComparator: tierComparator,
          disableColumnMenu: true,
          editable: true,
          flex: 1.3,
        };
      case ProductListColumn.ANNUAL_COST:
        return {
          field: 'annualCost',
          headerName: t('ANNUAL_COST'),
          renderCell: (params: GridRenderCellParams) => <AnnualCostCell {...params} />,
          renderEditCell: (params: GridRenderCellParams) => <EditableAnnualCostCell {...params} />,
          sortComparator: valueComparator,
          disableColumnMenu: true,
          editable: true,
          flex: 1.3,
        };
      case ProductListColumn.NUMBER_OF_LICENSES:
        return {
          field: 'numberOfLicences',
          headerName: t('LICENSES'),
          renderCell: (params) => <Text variant="caption">{params.row.numberOfLicences ?? ''}</Text>,
          renderEditCell: (params) => <EditableLicensesCell {...params} />,
          sortComparator: valueComparator,
          disableColumnMenu: true,
          editable: true,
          flex: 1,
        };
      default:
        assertExhausted(column);
    }
    return null;
  };

  return { buildColumn };
};
