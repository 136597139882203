import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { testProps } from '@verticeone/design-system/src/utils/testProperties';
import Text from '@verticeone/design-system/src/components/Text';
import { useOptimizationsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { LoadableComponent } from '@verticeone/design-system/src/components/Loader';
import { AWS_BRAND_COLOR } from '../../../../../modules/cloud/constants';

type StatsItemProps = {
  title: string;
  value: number;
};

const StatsItem = ({ title, value, ...otherProps }: StatsItemProps) => {
  const { palette } = useTheme();

  return (
    <Stack direction="column" flex={1} {...otherProps} bgcolor={palette.core.bg} px={6} py={0.5}>
      <Text variant="body-bold" size="S" color="text2">
        {title}
      </Text>
      <Text variant="heading" size="M" color="text1">
        {value}
      </Text>
    </Stack>
  );
};

const ScoreBarStats = () => {
  const { palette } = useTheme();
  const { accountId } = useAccountContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.SCORE_BAR' });
  const { data, isFetching } = useOptimizationsQuery(
    { accountId },
    {
      skip: !accountId,
      selectFromResult: (result) => {
        if (result?.data?.optimizationsQuery?.__typename === 'OptimizationsResult') {
          return {
            ...result,
            data: {
              total: result.data.optimizationsQuery.summary.recsTotal,
              toDo: result.data.optimizationsQuery.summary.recsBreaching,
              wontDo: result.data.optimizationsQuery.summary.recsCancelled,
              wellOptimized: result.data.optimizationsQuery.summary.recsNonBreaching,
            },
          };
        }

        return { ...result, data: undefined };
      },
    }
  );

  return (
    <Stack bgcolor={palette.core.bg} flex={1} pt={6} {...testProps('recommendations-overview')}>
      <LoadableComponent isLoading={isFetching} color={AWS_BRAND_COLOR}>
        <Stack direction="row" gap={0.25} bgcolor={palette.core.color3}>
          <StatsItem
            title={t('TOTAL_RECOMMENDATIONS')}
            value={data?.total ?? 0}
            {...testProps('totalRecommendations')}
          />
          <StatsItem title={t('TO_DO')} value={data?.toDo ?? 0} {...testProps('toDo')} />
          <StatsItem title={t('WONT_DO')} value={data?.wontDo ?? 0} {...testProps('wontDo')} />
          <StatsItem title={t('WELL_OPTIMIZED')} value={data?.wellOptimized ?? 0} {...testProps('wellOptimized')} />
        </Stack>
      </LoadableComponent>
    </Stack>
  );
};

export default ScoreBarStats;
