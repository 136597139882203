import { FC } from 'react';
import { TaskRow, TaskRowAssignee } from '../types';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';
import Text, { EllipsisText } from '@verticeone/design-system/src/components/Text';
import useLoggedUser from '@verticeone/auth/src/authentication/useLoggedUser';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import IconButton from '@verticeone/design-system/src/components/IconButton';
import { EditOutlined } from '@mui/icons-material';

export const splitAssignees = (assignees: TaskRow['assignees'], currentUserId: string) => {
  const assigneesCurrentUserFirst = assignees.slice().sort((a) => (a.id === currentUserId ? -1 : 1));
  const firstAssignee = assigneesCurrentUserFirst.splice(0, 1)[0];
  return {
    firstAssignee,
    otherAssignees: assigneesCurrentUserFirst,
  };
};

type TaskAssigneesProps = {
  assignees: TaskRowAssignee[];
  short?: boolean;
  setEdit?: (edit: boolean) => void;
};

export const TaskAssignees: FC<TaskAssigneesProps> = ({ assignees, short, setEdit }) => {
  const { t } = useTranslation();
  const { userId } = useLoggedUser();

  const validAssignees = assignees.filter((assignee) => assignee.valid);
  const hasOnlyInvalidAssignees = validAssignees.length === 0 && assignees.length > 0;
  const { firstAssignee, otherAssignees } = splitAssignees(validAssignees, userId);

  return (
    <Stack direction="row" alignItems="center" minWidth={0} gap={0.5}>
      {firstAssignee ? (
        <EllipsisText variant="body-regular" size="S" title={firstAssignee.fullName}>
          {firstAssignee.fullName || '–'}
          {firstAssignee.id === userId && t('INTELLIGENT_WORKFLOWS.TASK_MODAL.YOU_SUFFIX')}
        </EllipsisText>
      ) : (
        <EllipsisText variant="body-bold" size="S" color="error1">
          {t(
            hasOnlyInvalidAssignees
              ? 'ENTITIES.WORKFLOW_TASK.LABELS.INVALID_ASSIGNEE'
              : 'ENTITIES.WORKFLOW_TASK.LABELS.NO_ASSIGNEE'
          )}
        </EllipsisText>
      )}

      {otherAssignees?.length > 0 && (
        <>
          <Tooltip
            content={otherAssignees.map((assignee) => assignee.fullName.replaceAll(' ', '\u00A0')).join(', ')}
            minified
          >
            <Text variant="body-regular" size="S" color="text2">
              {t(`INTELLIGENT_WORKFLOWS.TASK_MODAL.PLUS_MORE_SUFFIX${short ? '_SHORT' : ''}`, {
                count: otherAssignees.length,
              })}
            </Text>
          </Tooltip>
        </>
      )}
      {setEdit && (
        <IconButton
          icon={EditOutlined}
          size="M"
          variant="plain"
          color="transparent"
          onClick={() => setEdit(true)}
          sx={{ marginLeft: '-4px' }}
        />
      )}
    </Stack>
  );
};
