import React, { ReactNode } from 'react';
import { Stack, styled } from '@mui/material';
import DashboardTwoTone from '@mui/icons-material/DashboardTwoTone';
import Text from '../Text';
import { testProps } from '../../utils/testProperties';
import { TestProps } from '../../types';
import { isString } from 'lodash';

type NoDataProps = {
  icon?: ReactNode;
  header: ReactNode;
  content?: string | ReactNode;
  button?: ReactNode;
  backgroundImageSrc?: string;
  backgroundColor?: string;
  className?: string;
} & TestProps;

const StyledDashboardIcon = styled(DashboardTwoTone)(({ theme }) => ({
  color: theme.palette.error.color2,
}));

export const StyledNoDataContent = styled(Stack)<{ $backgroundImageSrc?: string; $backgroundColor?: string }>(
  ({ theme, $backgroundImageSrc, $backgroundColor }) => ({
    width: '100%',
    height: '100%',
    backgroundImage: $backgroundImageSrc ? `url("${$backgroundImageSrc}")` : undefined,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: $backgroundColor ? $backgroundColor : theme.palette.background.default,
  })
);

const NoData = ({
  header,
  content,
  button,
  icon = <StyledDashboardIcon />,
  backgroundImageSrc,
  backgroundColor,
  testId,
  className,
}: NoDataProps) => (
  <StyledNoDataContent
    {...testProps(testId, 'no-data')}
    className={className}
    $backgroundImageSrc={backgroundImageSrc}
    $backgroundColor={backgroundColor}
  >
    <Stack
      gap={8}
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      sx={{ pointerEvents: 'auto', backdropFilter: 'blur(4px)' }}
    >
      {icon}
      <Stack gap={2}>
        <Text tag="h3" variant="body-bold" size="M" textAlign="center" color="text1">
          {header}
        </Text>
        {isString(content) && (
          <Text tag="p" variant="body-regular" size="M" textAlign="center" color="text1">
            {content}
          </Text>
        )}
        {!isString(content) && content}
      </Stack>
      {button}
    </Stack>
  </StyledNoDataContent>
);

export default NoData;
