import React from 'react';
import { Grid, Stack, styled, useTheme } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { StatsBody, Title } from '@vertice/core/src/components/StatsBar';
import Text from '@verticeone/design-system/src/components/Text';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { useTranslation } from 'react-i18next';
import { useCloudAnalytics } from '../../../CloudAnalyticsContext';
import { format } from 'date-fns';
import { CloudAnalyticsStats } from './type';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';
import { useDateRangeFormatter } from '../utils';
import PercentageChip from '../../../components/PercentageChip';
import LoadingOrValue from '../../../components/LoadingOrValue';

interface StatsProps {
  data?: CloudAnalyticsStats;
  isLoading: boolean;
}

const StatsContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.core.bg,
}));

const BaseCloudAnalyticsStats = ({ data, isLoading }: StatsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.ANALYTICS.STATS' });
  const { palette } = useTheme();
  const formatCurrency = useFormatCurrency();
  const formatDateRange = useDateRangeFormatter();
  const {
    period: { periodOneStart, periodOneEnd, periodTwoStart, periodTwoEnd },
  } = useCloudAnalytics();

  const getFormattedCurrency = (value?: number) => {
    if (value === undefined) {
      return t('NA');
    }

    return formatCurrency(value, {
      maximumFractionDigits: 2,
      currency: AWS_DEFAULT_CURRENCY,
    });
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing="1px"
      sx={{
        backgroundColor: palette.core.color3,
      }}
    >
      <Grid item md={6} xs={12}>
        <StatsContainer padding={6}>
          <StatsBody
            gap={4}
            isLoading={false}
            title={<Title tooltip={{ content: t('CURRENT_COST.TOOLTIP_INFO') }}>{t('CURRENT_COST.TITLE')}</Title>}
            value={
              <Stack gap={2}>
                <Stack direction="row" gap={2} alignItems="center">
                  <LoadingOrValue isLoading={isLoading} value={getFormattedCurrency(data?.cost)} />
                  <Stack gap={2} direction="row" alignItems="center">
                    <Tooltip
                      content={t('VS_PERIOD', {
                        period: `${format(new Date(periodTwoStart), 'PP')} - ${format(new Date(periodTwoEnd), 'PP')}`,
                      })}
                      size="S"
                    >
                      <PercentageChip isLoading={isLoading} percentage={data?.percentage} />
                    </Tooltip>
                  </Stack>
                </Stack>
                <Stack direction="row" gap={2} alignItems="center">
                  <IconWrapper icon={CalendarTodayOutlined} size="S" htmlColor={palette.text.color2} />
                  <Text variant="body-bold" size="S" color="text2" testId="stats-bar-current-period">
                    {formatDateRange(periodOneStart, periodOneEnd)}
                  </Text>
                </Stack>
              </Stack>
            }
          />
        </StatsContainer>
      </Grid>
      <Grid item md={6} xs={12}>
        <StatsContainer padding={6}>
          <StatsBody
            gap={4}
            isLoading={false}
            title={<Title tooltip={{ content: t('PREVIOUS_COST.TOOLTIP_INFO') }}>{t('PREVIOUS_COST.TITLE')}</Title>}
            value={
              <Stack gap={2}>
                <LoadingOrValue isLoading={isLoading} value={getFormattedCurrency(data?.previousCost)} />
                <Stack direction="row" gap={2} alignItems="center">
                  <IconWrapper icon={CalendarTodayOutlined} size="S" htmlColor={palette.text.color2} />
                  <Text variant="body-bold" size="S" color="text2" testId="stats-bar-previous-period">
                    {formatDateRange(periodTwoStart, periodTwoEnd)}
                  </Text>
                </Stack>
              </Stack>
            }
          />
        </StatsContainer>
      </Grid>
    </Grid>
  );
};

export default BaseCloudAnalyticsStats;
