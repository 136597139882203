import { type GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';
import differenceWith from 'lodash/differenceWith';
import { useVendorProductsContext } from '../VendorProductsContextProvider';
import { ProductItem } from '../types';

import { GridSelect } from '@verticeone/design-system/src/components/DataGrid';
import { Product } from '@vertice/slices';
import { keyBy } from 'lodash';

type EditableNameCellProps = GridCellParams<any, string> & {
  selectedProducts: ProductItem[];
};

const EditableNameCell: FC<EditableNameCellProps> = ({ id, value, field, selectedProducts }) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  const { vendorProducts, color } = useVendorProductsContext();
  const vendorProductsMap = keyBy(vendorProducts, 'id');

  // all products except the used ones and the original selected value of this field
  const remainingProducts = useMemo(
    () =>
      sortBy(
        differenceWith(
          vendorProducts,
          selectedProducts,
          (vendorProduct, usedProduct) => vendorProduct.id === usedProduct.productId && vendorProduct.id !== value
        ),
        'name'
      ),
    // the hook should not take into account the current selected value
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProducts, vendorProducts]
  );

  return (
    <GridSelect
      value={value ? vendorProductsMap[value] : undefined}
      options={remainingProducts}
      isMulti={false}
      isClearable={false}
      placeholder={t('INTAKE_FORM.PRODUCTS_TABLE.PRODUCT')}
      menuPlacement="auto"
      getOptionValue={(option) => (option as Product).id!}
      getOptionLabel={(option) => (option as Product).name ?? ''}
      onChange={(newValue) => {
        void apiRef.current.setEditCellValue({ id, field, value: (newValue as Product).id });
      }}
      color={color}
    />
  );
};

export default EditableNameCell;
