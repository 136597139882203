import React, { FC } from 'react';
import {
  Contract,
  type CreateContractApiResponse,
  useCreateContractMutation,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { useTranslation } from 'react-i18next';
import Button from '@verticeone/design-system/src/components/Button';
import { VerticeVWhiteIcon } from '@verticeone/design-system/src/assets';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { Link as RouterLink } from 'react-router-dom';
import { Stack } from '@mui/material';
import Divider from '@verticeone/design-system/src/components/Divider';
import Text from '@verticeone/design-system/src/components/Text';
import {
  ContractTab,
  useContractTabNavigationContext,
} from '@vertice/core/src/modules/saas/contract/ContractTabNavigationContext';
import { deriveRenewedOutsideContractFromCurrent } from './ContractNotYetRenewedCard';
import { NonSaasRenewalAlert } from '@vertice/core/src/modules/saas/contract/components/NonSaasRenewalAlert';
import { useSimpleDialogContext } from '@verticeone/design-system/src/components/Dialog/SimpleDialog';
import { useCreateRenewalRequestFlow } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useCreateRenewalRequestFlow';
import { contractVendorToVendor } from '@vertice/core/src/modules/vendor/utils';

export const ContractNotYetRenewedContentActions: FC<{ contract: Contract }> = ({ contract }) => {
  const { t } = useTranslation();

  const routes = useRoutes();
  const { navigate, generatePath } = useRouteNavigate();
  const tabNavigationContext = useContractTabNavigationContext();

  const { accountId, isFeatureEnabled } = useAccountContext();
  const { createRenewalRequestFlow, isCreatingRenewalRequest } = useCreateRenewalRequestFlow();

  const [createContract, { isLoading: isCreatingRenewalOutsideVertice }] = useCreateContractMutation();

  const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);

  const { getConfirmation } = useSimpleDialogContext();

  const renewalIntakePath = generatePath(routes.CONTRACTS.DETAIL.RENEWAL_INTAKE.ABSOLUTE_PATH, {
    contractId: contract.record.contractId,
  });

  const createRenewalRequest = () => {
    void createRenewalRequestFlow({
      contractId: contract.record.contractId!,
      vendor: contractVendorToVendor(contract.parts.contractual?.vendor!),
      products: contract.parts.contractual?.products,
      department: contract.classification?.departmentId,
    });
  };

  const handleRenewOutsideVerticeButtonClick = async () => {
    const confirmResult = await getConfirmation({
      title: t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_OUTSIDE_VERTICE_QUESTION'),
      description: t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_OUTSIDE_VERTICE_CONFIRMATION'),
      okButton: { label: t('DIALOG.BUTTONS.CONFIRM') },
    });

    if (confirmResult.status !== 'ok') {
      return;
    }

    const creationResult = await createContract({
      accountId,
      createContractRequest: {
        contract: deriveRenewedOutsideContractFromCurrent(contract),
      },
    });

    const successResult = (creationResult as { data: CreateContractApiResponse }).data;
    if (successResult) {
      tabNavigationContext.setTabId(ContractTab.REQUEST_DETAILS);
      navigate(routes.CONTRACTS.DETAIL.EDIT, {
        contractId: successResult.viewOnContract.contract.record.contractId,
      });
    }
  };

  return (
    <>
      <Stack gap={4} width="100%">
        {isIWEnabled ? (
          <Button
            fullWidth
            variant="solid"
            color="primary"
            isCaption
            size="S"
            onClick={createRenewalRequest}
            disabled={isCreatingRenewalRequest}
            isLoading={isCreatingRenewalRequest}
          >
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.INITIATE_RENEWAL')}
          </Button>
        ) : (
          <Stack gap={1}>
            <Button
              fullWidth
              variant="solid"
              color="primary"
              isCaption
              size="S"
              component={RouterLink}
              to={renewalIntakePath}
              disabled={isCreatingRenewalOutsideVertice}
            >
              <VerticeVWhiteIcon />
              {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_WITH_VERTICE')}
            </Button>
            <NonSaasRenewalAlert contract={contract} />
          </Stack>
        )}
        <Divider>
          <Text variant="label" color="text3" size="XXS">
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.OR')}
          </Text>
        </Divider>
        <Stack direction="column" alignItems="center" gap={1}>
          {isIWEnabled && (
            <Text variant="body-regular" size="S">
              {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.ALREADY_FULFILLED')}
            </Text>
          )}
          <Button
            fullWidth
            variant="plain"
            color="primary"
            size="M"
            onClick={handleRenewOutsideVerticeButtonClick}
            disabled={isCreatingRenewalOutsideVertice}
            isLoading={isCreatingRenewalOutsideVertice}
          >
            {isIWEnabled
              ? t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.ADD_RENEWED_CONTRACT')
              : t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_OUTSIDE_VERTICE_BUTTON')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
