import { BaseSyntheticEvent, ReactNode } from 'react';
import { Stack } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { GridColumnHeaderParams, GridColumnHeaderTitle } from '@mui/x-data-grid-pro';
import { GridValidRowModel } from '@mui/x-data-grid';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';

import Tooltip, { TooltipInfoTrigger } from '../../Tooltip';
import IconButton from '../../IconButton';
import Text from '../../Text';
import { useDataGridContext } from '../DataGridContext';
import { HEADER_SIZES } from '../constants';

type TooltipIconProps = {
  title?: ReactNode;
  content?: ReactNode;
};

type ExpandableHeaderProps = {
  isExpanded?: boolean;
  toggle: () => void;
};

export type GridHeaderCellProps<T extends GridValidRowModel> = GridColumnHeaderParams<T> & {
  subtitle?: ReactNode;
  tooltip?: TooltipIconProps;
  expandable?: ExpandableHeaderProps;
};

const StyledTooltipInfoTrigger = styled(TooltipInfoTrigger)(({ theme }) => ({
  color: theme.palette.neutral.color1,
  opacity: 0.4,
  transition: 'opacity 0.2s',
  '&:hover': {
    opacity: 1,
  },
}));

const TooltipIcon = ({ title, content }: TooltipIconProps) => {
  const { headerSize } = useDataGridContext();
  const size = HEADER_SIZES[headerSize].tooltipSize;

  return (
    <Tooltip title={title} content={content} size={size}>
      <StyledTooltipInfoTrigger />
    </Tooltip>
  );
};

type HeaderTextProps = {
  headerName: string;
  description: string;
  subtitle?: ReactNode;
};

const HeaderText = ({ headerName, description, subtitle }: HeaderTextProps) => {
  const { headerSize } = useDataGridContext();
  const size = HEADER_SIZES[headerSize].fontSize;

  const title = (
    <GridColumnHeaderTitle label={headerName} description={description} aria-label={headerName} columnWidth={0} />
  );

  if (subtitle) {
    return (
      <Stack direction="column" alignItems="flex-end" minWidth={0}>
        {title}
        {subtitle && (
          <Text variant="body-regular" size={size} color="neutral1" minWidth={0}>
            {subtitle}
          </Text>
        )}
      </Stack>
    );
  }

  return title;
};

export const GridHeaderCell = <T extends GridValidRowModel = any>({
  tooltip,
  expandable,
  subtitle,
  ...params
}: GridHeaderCellProps<T>) => {
  const { headerName = '', description = '' } = params.colDef;

  return (
    <Stack direction="row" gap={4} alignItems="center" minWidth={0}>
      <Stack direction="row" alignItems="center" gap={1} minWidth={0}>
        <HeaderText headerName={headerName} description={description} subtitle={subtitle} />
        {tooltip && <TooltipIcon {...tooltip} />}
      </Stack>
      {expandable && (
        <Stack>
          <IconButton
            size="XXS"
            variant="solid"
            color="neutral"
            onClick={(e: BaseSyntheticEvent) => {
              e.stopPropagation();
              expandable.toggle();
            }}
            icon={expandable.isExpanded ? ArrowBackIosOutlined : ArrowForwardIosOutlined}
          />
        </Stack>
      )}
    </Stack>
  );
};
