import React, { useCallback, useEffect } from 'react';
import { FieldPathByValue, FieldValues, useFormContext } from 'react-hook-form';

import { OfferProduct, Offer, joinFormPath, useWatchNested } from '../schemas';
import { useTaskFormContext } from '../TaskFormContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../constants';
import {
  CostModelProductItem,
  CostModelProductList,
} from '../../../../../../../saas/contract/components/CostModelProductList';

type OfferFormProductListProps<
  V extends FieldValues,
  P extends FieldPathByValue<V, Offer> = FieldPathByValue<V, Offer>
> = {
  name: P;
};

export const OfferFormProductList = <T extends FieldValues>({ name: offerName }: OfferFormProductListProps<T>) => {
  const { setValue } = useFormContext<T>();
  const { readOnly } = useTaskFormContext();

  const products = useWatchNested({ offerName, fieldName: 'products' })?.map(toCostModelProductItem);
  const vendor = useWatchNested({ offerName, fieldName: 'vendor' });
  const baseCurrency = useWatchNested({ offerName, fieldName: 'baseCurrency' });
  const rollingFrequency = useWatchNested({ offerName, fieldName: 'rollingFrequency' });

  const setProducts = useCallback(
    (productsToSet: CostModelProductItem[]) =>
      setValue(joinFormPath<T>(offerName, 'products'), productsToSet as any, { shouldValidate: true }),
    [setValue, offerName]
  );

  useEffect(() => {
    if (rollingFrequency !== 'NO' && products?.some((product) => !!product.allocationSpan)) {
      setProducts(products?.map((product) => ({ ...product, allocationSpan: undefined })));
    }
  }, [products, rollingFrequency, setProducts]);

  return (
    <CostModelProductList
      rollingFrequency={rollingFrequency}
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      withActions={!readOnly}
      vendorId={vendor?.id}
      currency={baseCurrency}
      selectedProducts={products || []}
      setSelectedProducts={setProducts}
    />
  );
};

const toCostModelProductItem = (product: OfferProduct): CostModelProductItem => ({
  ...product,
  annualCost: product.annualCost ?? null,
  numberOfLicences: product.numberOfLicences ?? null,
});
