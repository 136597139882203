import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridRowId, GridRowModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import { useSimpleDialogContext } from '@verticeone/design-system/src/components/Dialog/SimpleDialog';
import { Product } from '@vertice/slices';
import { ProductItem } from '../types';
import { asProductItem } from '../utils';
import useSelectedProducts from './useSelectedProducts';

export type UseProductListActionsParams<T extends GridValidRowModel> = {
  selectedProducts: T[];
  setSelectedProducts: (newProducts: T[]) => void;
  getProductItem?: (r: GridRowModel, p: Product[]) => T;
  vendorProducts: Product[];
};

export type UseProductListActionsReturn<T extends GridValidRowModel> = {
  validateRow?: (row: T) => { isValid: true; row?: T } | { isValid: false; message: string };
  onErrorsChanged?: (errors: string[]) => void;
  onAddRow?: (row: T) => void;
  onUpdateRow?: (row: T) => void;
  onBeforeDeleteRow?: (row: T) => Promise<boolean>;
  onDeleteRow?: (id: GridRowId) => void;
};

export const useProductListActions = <T extends ProductItem>({
  selectedProducts,
  setSelectedProducts,
  getProductItem,
  vendorProducts,
}: UseProductListActionsParams<T>): UseProductListActionsReturn<T> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTAKE_FORM.PRODUCTS_TABLE' });
  const { addProduct, updateProduct, removeProduct } = useSelectedProducts<T>({
    selectedProducts,
    setSelectedProducts,
  });

  const { getConfirmation } = useSimpleDialogContext();

  const validateRow = useCallback(
    (row: GridRowModel): { isValid: true } | { isValid: false; message: string } => {
      if (!row.productId) {
        return { isValid: false, message: t('ERROR_DIALOG.MESSAGE') };
      }
      return { isValid: true };
    },
    [t]
  );

  const onAddRow = useCallback(
    (row: GridRowModel) => {
      const convertRowToItem = getProductItem ? getProductItem : asProductItem;
      addProduct(convertRowToItem(row, vendorProducts) as T);
    },
    [addProduct, getProductItem, vendorProducts]
  );

  const onUpdateRow = useCallback(
    (row: GridRowModel) => {
      const convertRowToItem = getProductItem ? getProductItem : asProductItem;
      updateProduct(convertRowToItem(row, vendorProducts) as T);
    },
    [updateProduct, getProductItem, vendorProducts]
  );

  const onBeforeDeleteRow = useCallback(async () => {
    const confirmation = await getConfirmation({
      title: t('REMOVE_PRODUCT_DIALOG.TITLE'),
      description: t('REMOVE_PRODUCT_DIALOG.MESSAGE'),
      okButton: {
        label: t('REMOVE_PRODUCT_DIALOG.YES'),
      },
    });

    return confirmation.status === 'ok';
  }, [getConfirmation, t]);

  const onDeleteRow = useCallback(
    (id: GridRowId) => {
      removeProduct(id as string);
    },
    [removeProduct]
  );

  const onErrorsChanged = useCallback(
    (errors: string[]) => {
      if (errors.length) {
        void getConfirmation({
          title: t('ERROR_DIALOG.TITLE'),
          description: errors.join(', '),
          cancelButton: {
            hidden: true,
          },
        });
      }
    },
    [getConfirmation, t]
  );

  return {
    validateRow,
    onAddRow,
    onUpdateRow,
    onBeforeDeleteRow,
    onDeleteRow,
    onErrorsChanged,
  };
};
