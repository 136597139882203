import { GridRowModel } from '@mui/x-data-grid-pro';
import { Product } from '@vertice/slices';
import { asProductItem } from '../ProductList/utils';
import { CostModelProductItem } from './types';

export const asCostModelProductItem = (newRow: GridRowModel, vendorProducts: Product[]): CostModelProductItem => {
  const hasSpan =
    newRow.allocationSpan?.start_date || newRow.allocationSpan?.end_date || newRow.allocationSpan?.rollingFrequency;

  return {
    ...asProductItem(newRow, vendorProducts),
    licenseCost: newRow.licenseCost,
    allocationSpan: hasSpan
      ? {
          start_date: newRow.allocationSpan?.start_date,
          end_date: newRow.allocationSpan?.end_date,
          rolling_frequency: newRow.allocationSpan?.rollingFrequency,
        }
      : undefined,
  };
};
