import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import Highcharts, { OptionsStackingValue, SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import ColorSquare from '@vertice/core/src/components/charts/components/ColorSquare';
import Text from '@verticeone/design-system/src/components/Text';
import Card from '@verticeone/design-system/src/components/Card';
import { testProps } from '@verticeone/design-system/src/utils/testProperties';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';
import getTextVariantStyle from '@verticeone/design-system/src/components/Text/utils/getTextVariantStyle';
import { useSPRIOOverviewWidgetData } from '../useSPRIOOverviewWidgetData';
import { type DataSource, useDataSource } from '../../../DataSourceProvider';

const dataSources: Record<DataSource, string> = {
  SageMaker: 'SAGE_MAKER',
};

const useOptimizationSeries = () => {
  const { palette } = useTheme();
  const { datasource } = useDataSource();
  const { t } = useTranslation(undefined, {
    keyPrefix: `CLOUD.RIO_NEW.OVERVIEW.WIDGETS.${dataSources[datasource]}.GRAPH`,
  });
  const { data, isFetching } = useSPRIOOverviewWidgetData();

  return useMemo(
    () => ({
      isFetching,
      series: [
        {
          id: 'SAVINGS_PLANS_COVERAGE',
          type: 'bar' as const,
          data: [data ? data.sp_usage_percentage * 100 : 0],
          color: palette.visualization.monochromatic.primary['80'],
          name: t('SAVINGS_PLANS_COVERAGE'),
          dataLabels: [
            {
              style: {
                ...(getTextVariantStyle({ variant: 'body-bold', size: 'S' }) as any),
                color: palette.visualization.monochromatic.primary['30'],
              },
            },
          ],
          states: {
            hover: {
              enabled: true,
              color: palette.visualization.monochromatic.primary['50'],
            },
          },
        },
        {
          id: 'ON_DEMAND_USAGE',
          type: 'bar' as const,
          data: [data ? data.on_demand_usage_percentage * 100 : 0],
          color: getColorPattern(palette.text.color3),
          name: t('ON_DEMAND_USAGE'),
          dataLabels: [
            {
              style: {
                ...(getTextVariantStyle({ variant: 'body-bold', size: 'S' }) as any),
                color: palette.text.color1,
              },
            },
          ],
          states: {
            hover: {
              color: getColorPattern(palette.text.color4),
            },
          },
        },
      ],
    }),
    [data, isFetching, palette, t]
  );
};

const Chart = ({ series }: Pick<ReturnType<typeof useOptimizationSeries>, 'series'>) => {
  const { palette } = useTheme();
  const applyStyledHighcharts = useStyledHighcharts();
  const applyXAxisOffset = useXAxisOffset();

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        applyXAxisOffset,
        mergeOptions({
          chart: {
            type: 'bar',
            height: 54,
            spacing: [0, 0, 0, 0],
            marginLeft: 0,
            marginRight: 0,
            backgroundColor: palette.core.bg,
            plotBackgroundColor: palette.core.bg,
          },
          plotOptions: {
            bar: {
              borderRadius: 8,
            },
            series: {
              stacking: 'normal' as OptionsStackingValue,
              dataLabels: {
                enabled: true,
                inside: true,
                format: '{y}%',
              },
              borderWidth: 2,
              borderColor: palette.core.bg,
            },
          },
          xAxis: {
            visible: false,
          },
          yAxis: {
            visible: false,
            tickInterval: 0.1,
          },
          series: series.toReversed() as Array<SeriesOptionsType>,
          tooltip: {
            enabled: false,
          },
        }),
      ]),
    [applyStyledHighcharts, applyXAxisOffset, palette, series]
  );

  const legendItems = series.map((item) => ({
    color: item.states.hover.color,
    label: item.name,
    value: item.data[0],
    id: item.name,
  }));

  return (
    <Card p={4} pb={0} bgcolor={palette.core.bg} {...testProps('chart')}>
      {legendItems.length > 0 && (
        <Box alignSelf="flex-start">
          <Stack direction="row" bgcolor={palette.core.color1} py={1} px={2} borderRadius={2} gap={3.5}>
            {legendItems.map((item) => (
              <Stack direction="row" alignItems="center" gap={2} key={item.id}>
                <ColorSquare color={item.color} />
                <Stack direction="row" gap={1}>
                  <Text variant="body-regular" size="S" color="text1">
                    {item.label}
                  </Text>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
      )}
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Card>
  );
};

const ChartContainer = () => {
  const { series, isFetching } = useOptimizationSeries();

  if (!isFetching) {
    return <Chart series={series} />;
  }
};

export default ChartContainer;
