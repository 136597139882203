import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { Stack, styled } from '@mui/material';
import {
  GridPaginationModel,
  GridRow,
  GridRowClassNameParams,
  GridRowHeightParams,
  GridRowModel,
} from '@mui/x-data-grid-pro';

import DataGrid from '@verticeone/design-system/src/components/DataGrid';
import NoData from '@verticeone/design-system/src/components/NoData';
import Button from '@verticeone/design-system/src/components/Button';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { IntelligentWorkflowsLayout } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';
import { UsersContextProvider } from '@vertice/core/src/contexts/UsersContext';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';

import { useRequestsNavigation } from '../hooks/useRequestsNavigation';
import { FilterPanel } from './components/filter/FilterPanel';
import { useRequestListStatusFilters } from './components/filter/useRequestListStatusFilters';
import { CreateRequestDialog } from './components/CreateRequestDialog';
import { RequestRow } from './types';
import { useRequests } from './useRequests';
import { useGroupingColumn, useRequestsColumns } from './useRequestsColumns';
import { useRequestFilter } from './components/filter/useRequestFilter';

const StyledDataGrid = styled(DataGrid<RequestRow>)(({ theme }) => ({
  '& .MuiDataGrid-cell:first-child': {
    paddingLeft: 4,
  },
  '& .child-row': {
    backgroundColor: theme.palette.core.color1,
    '&, &:hover, &.Mui-hovered': {
      backgroundColor: theme.palette.core.color1,
    },
  },
  '.MuiDataGrid-cell--withRightBorder': {
    borderRight: 'none',
  },
}));

const ColumnLink = styled(Link)(() => {
  return {
    display: 'block',
    width: 'calc(100% + 16px)',
    textDecoration: 'unset',
    color: 'unset',
    marginLeft: -4,
    paddingLeft: 4,
    paddingBlock: 12,
    marginRight: -16,
  };
});

const BaseRequestsListPage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get('search') || '';
  const { isUserRestrictedUser } = useLoggedUserAccountRoles();

  const myRequestsOnly =
    searchParams.get('myRequestsOnly') === 'true' || // If user is admin or power user, they should by defeault see all requests
    (searchParams.get('myRequestsOnly') === null && isUserRestrictedUser); // If user is normal user, they should see their requests only by default

  const currentPage = parseInt(searchParams.get('page') || '0');
  const pageSize = parseInt(searchParams.get('pageSize') || '25');
  const paginationModel = useMemo(() => ({ pageSize, page: currentPage }), [currentPage, pageSize]);

  const { statusFilters, activeStatusFilter, setActiveStatusFilter } = useRequestListStatusFilters();

  const { requests, requestIds, isLoadingRequests } = useRequests();
  const { filteredRequests, searchQueryRequests } = useRequestFilter(requests, {
    activeStatusFilter,
    searchQuery,
    myRequestsOnly,
  });

  const [createRequestDialogOpen, setCreateRequestDialogOpen] = useState(false);

  const columns = useRequestsColumns();
  const groupingColumn = useGroupingColumn();

  const { generateRequestPath } = useRequestsNavigation();

  const rowIdGetter = (row: GridRowModel<RequestRow>) => row.requestId;
  const getTreeDataPath = useCallback(
    ({ parentId: parent, requestId }: GridRowModel<RequestRow>) =>
      parent && requestIds.has(parent) ? [parent, requestId] : [requestId],
    [requestIds]
  );

  const getRowClassName = useCallback(
    ({ row }: GridRowClassNameParams<RequestRow>) => {
      return row.parentId && requestIds.has(row.parentId) ? 'child-row' : '';
    },
    [requestIds]
  );
  const getRowHeight = useCallback(
    ({ model }: GridRowHeightParams) => {
      return model.parentId && requestIds.has(model.parentId) ? 60 : 74;
    },
    [requestIds]
  );

  const onSearchQueryChange = useCallback(
    (query: string) => {
      setSearchParams((oldParams) => {
        oldParams.set('search', query);
        oldParams.set('page', '1');
        return oldParams;
      });
    },
    [setSearchParams]
  );

  const onMyRequestsOnlyChange = useCallback(
    (value: boolean) => {
      setSearchParams((oldParams) => {
        oldParams.set('myRequestsOnly', value.toString());
        oldParams.set('page', '1');
        return oldParams;
      });
    },
    [setSearchParams]
  );

  const setPaginationModel = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      setSearchParams((oldParams) => {
        oldParams.set('page', newPaginationModel.page.toString());
        oldParams.set('pageSize', newPaginationModel.pageSize.toString());
        return oldParams;
      });
    },
    [setSearchParams]
  );

  return (
    <IntelligentWorkflowsLayout
      heading={t('INTELLIGENT_WORKFLOWS.NAVBAR.REQUESTS')}
      actions={
        <Button
          variant="solid"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          onClick={() => setCreateRequestDialogOpen(true)}
        >
          {t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.CREATE_REQUEST_BUTTON')}
        </Button>
      }
    >
      <Stack gap={8}>
        <FilterPanel
          statusFilters={statusFilters}
          activeStatusFilter={activeStatusFilter}
          onStatusFilterClick={setActiveStatusFilter}
          searchQuery={searchQuery}
          onSearchQueryChange={onSearchQueryChange}
          myRequestsOnly={myRequestsOnly}
          onMyRequestsOnlyChange={onMyRequestsOnlyChange}
          requests={filteredRequests}
          isLoading={isLoadingRequests}
        />
        <StyledDataGrid
          treeData
          defaultGroupingExpansionDepth={2}
          getRowClassName={getRowClassName}
          rows={searchQueryRequests}
          columns={columns}
          getRowId={rowIdGetter}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColumn}
          sortingMode="client"
          disableRowSelectionOnClick
          rowSelection={false}
          noRowsOverlayHeight={200}
          getRowHeight={getRowHeight}
          autoHeight
          pagination
          paginationMode="client"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          slots={{
            row: (props) =>
              props.row.requestId ? (
                <ColumnLink to={generateRequestPath(props.row.requestId, props.row.isLegacy)} sx={{ padding: 0 }}>
                  <GridRow {...props} />
                </ColumnLink>
              ) : (
                <GridRow {...props} />
              ),
            noRowsOverlay: () => <NoData header={t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.NO_REQUESTS')} />,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize, page: currentPage } },
            sorting: {
              sortModel: [{ field: 'lastActivityAt', sort: 'desc' }],
            },
          }}
          color="neutral"
          loadingStyle="skeleton"
          loading={isLoadingRequests}
        />
      </Stack>
      <CreateRequestDialog
        open={createRequestDialogOpen}
        setOpen={(open) => setCreateRequestDialogOpen(open)}
        key={createRequestDialogOpen ? 'open' : 'closed'}
      />
    </IntelligentWorkflowsLayout>
  );
};

export const RequestsListPage = () => (
  <UsersContextProvider>
    <BaseRequestsListPage />
  </UsersContextProvider>
);
