import React from 'react';
import Loader from '@verticeone/design-system/src/components/Loader';
import NoData from '@verticeone/design-system/src/components/NoData';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import Button from '@verticeone/design-system/src/components/Button';
import { Link } from 'react-router-dom';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { RequestBenchmarkingDataButton } from '../../../vendor/buttons/RequestBenchmarkingDataButton';
import { ArrowForwardIos } from '@mui/icons-material';
import IconWrapper from '@verticeone/design-system/src/components/IconWrapper';
import { AlertsRow } from '../shared/pricingBenchmark/AlertsRow';
import PricingBenchmarkGraph from '../shared/pricingBenchmark/PricingBenchmarkGraph';
import { useGetBenchmarkDetailRowData } from './useGetBenchmarkDetailRowData';

type PricingBenchmarkDetailRowProps = {
  vendorId: string;
  productId: string;
};

export const PricingBenchmarkDetailRow = ({ vendorId, productId }: PricingBenchmarkDetailRowProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'PRODUCT.PRODUCT_BENCHMARKING_DETAIL_ROW' });
  const { palette } = useTheme();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  const { data, isLoading } = useGetBenchmarkDetailRowData({ vendorId, productId });

  if (isLoading) {
    return (
      <Box bgcolor={palette.core.color1} height={250}>
        <Loader />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box bgcolor={palette.core.color1} p={2} height={250}>
        <NoData
          header={t('NO_DATA.HEADER')}
          content={t('NO_DATA.CONTENT')}
          backgroundColor={palette.core.color1}
          button={<RequestBenchmarkingDataButton vendorId={vendorId} productId={productId} />}
        />
      </Box>
    );
  }

  const { period, product, benchmarkData } = data;

  return (
    <Stack bgcolor={palette.core.color1} p={2} gap={2}>
      <AlertsRow
        accountPricingRangePerTier={benchmarkData.accountPricingRangePerTier!}
        product={product}
        period={period}
      />
      <Box py={6} borderRadius={4} border={`1px solid ${palette.core.color3}`}>
        <PricingBenchmarkGraph data={benchmarkData} withNumerosity={false} period={period} />
      </Box>
      <Button
        fullWidth
        variant="outline"
        color="neutral"
        size="S"
        component={Link}
        to={generatePath(routes.VENDORS.VENDOR.PRODUCT.ABSOLUTE_PATH, { id: vendorId, productId: productId })}
        endIcon={<IconWrapper size="S" icon={ArrowForwardIos} sx={{ opacity: 0.6 }} />}
      >
        {t('VIEW_MORE')}
      </Button>
    </Stack>
  );
};
