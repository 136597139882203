import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@verticeone/design-system/src/components/Text';
import { type DataSource, useDataSource } from '../../../DataSourceProvider';

const dataSources: Record<DataSource, string> = {
  SageMaker: 'SAGE_MAKER',
};

const Title = () => {
  const { datasource } = useDataSource();
  const { t } = useTranslation(undefined, { keyPrefix: `CLOUD.RIO_NEW.OVERVIEW.WIDGETS.${dataSources[datasource]}` });

  return (
    <Text color="text1" size="S" variant="heading">
      {t('TITLE')}
    </Text>
  );
};

export default Title;
