import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogText,
  DialogActions,
} from '@verticeone/design-system/src/components/Dialog';
import Divider from '@verticeone/design-system/src/components/Divider';
import Button from '@verticeone/design-system/src/components/Button';
import React, { FC, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import VendorSelect from '../../../../vendor/VendorSelect';
import { Vendor } from '../../../../vendor/types';
import { useCreateNewPurchaseRequestFlow } from '../../../hooks/useCreateNewPurchaseRequestFlow';
import { useCreateRenewalRequestFlow } from '../../../hooks/useCreateRenewalRequestFlow';
import { ContractSelect } from '../../../../saas/contract/components/ContractSelect/ContractSelect';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { useServiceCards } from '../../../service/useServiceCards';
import { isSaasNewPurchaseServiceRef, isSaasRenewalServiceRef } from '../../../../../hooks/workflows/refUtils';
import { contractVendorToVendor } from '../../../../vendor/utils';
import { ContractOption } from '../../../../saas/contract/components/ContractSelect/types';

type CreateRequestDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

type DialogState = 'INITIAL' | 'PURCHASE' | 'RENEWAL';

export const CreateRequestDialog: FC<CreateRequestDialogProps> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const serviceCards = useServiceCards({
    filter: (service) => isSaasNewPurchaseServiceRef(service.urn) || isSaasRenewalServiceRef(service.urn),
    onClick: (serviceRef) => {
      if (isSaasNewPurchaseServiceRef(serviceRef)) {
        setDialogState('PURCHASE');
      }
      if (isSaasRenewalServiceRef(serviceRef)) {
        setDialogState('RENEWAL');
      }
    },
  });

  const [dialogState, setDialogState] = useState<DialogState>('INITIAL');
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [selectedContract, setSelectedContract] = useState<ContractOption | null>(null);
  const isInInitialState = dialogState === 'INITIAL';

  const { createPurchaseRequestFlow, isCreatingNewPurchaseRequest } = useCreateNewPurchaseRequestFlow();
  const { createRenewalRequestFlow, isCreatingRenewalRequest } = useCreateRenewalRequestFlow();
  const isCreatingRequest = isCreatingRenewalRequest || isCreatingNewPurchaseRequest;

  const purchaseReady = dialogState === 'PURCHASE' && selectedVendor;
  const renewalReady = dialogState === 'RENEWAL' && selectedContract;

  const handleCreateRequest = useCallback(() => {
    if (purchaseReady) {
      void createPurchaseRequestFlow({ vendor: selectedVendor });
    } else if (renewalReady) {
      const contractVendor = selectedContract.contract.parts.contractual?.vendor;
      if (contractVendor && selectedContract.id) {
        void createRenewalRequestFlow({
          vendor: contractVendorToVendor(contractVendor),
          products: selectedContract.contract.parts.contractual?.products,
          contractId: selectedContract.id,
          department: selectedContract.contract.classification?.departmentId,
        });
      }
    }
  }, [
    createPurchaseRequestFlow,
    createRenewalRequestFlow,
    purchaseReady,
    renewalReady,
    selectedContract,
    selectedVendor,
  ]);

  return (
    <Dialog
      open={open}
      setOpen={isInInitialState ? setOpen : undefined}
      size="M"
      width={780}
      onClose={() => setOpen(false)}
    >
      <DialogHeader>
        {t(`INTELLIGENT_WORKFLOWS.REQUESTS_LIST.CREATE_REQUEST_DIALOG.HEADING_${dialogState}`)}
      </DialogHeader>
      <Divider />
      <DialogContent>
        <Stack gap={4} minHeight={260}>
          <DialogText variant="body-regular">
            <Trans
              i18nKey={`INTELLIGENT_WORKFLOWS.REQUESTS_LIST.CREATE_REQUEST_DIALOG.DESCRIPTION_${dialogState}`}
              components={{ break: <br /> }}
            />
          </DialogText>
          {isInInitialState && (
            <Stack direction="row" gap={2}>
              {serviceCards}
            </Stack>
          )}
          {dialogState === 'PURCHASE' && (
            <VendorSelect
              value={selectedVendor}
              onChange={(v) => setSelectedVendor(v)}
              isDisabled={isCreatingNewPurchaseRequest}
            />
          )}
          {dialogState === 'RENEWAL' && (
            <ContractSelect
              isDisabled={isCreatingRenewalRequest}
              value={selectedContract}
              onChange={(c) => setSelectedContract(c)}
            />
          )}
        </Stack>
      </DialogContent>
      <Divider />
      {!isInInitialState && (
        <DialogActions>
          <Button
            variant="outline"
            disabled={isCreatingRequest}
            onClick={() => setOpen(false)}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          >
            {t('DIALOG.BUTTONS.CANCEL')}
          </Button>
          <Button
            variant="solid"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            onClick={handleCreateRequest}
            disabled={isCreatingRequest || (!purchaseReady && !renewalReady)}
            isLoading={isCreatingRequest}
          >
            {t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.CREATE_REQUEST_DIALOG.START_REQUEST_BUTTON')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
