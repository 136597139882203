import React from 'react';
import { Drawer, DrawerBody } from '@verticeone/design-system/src/components/Drawer';
import ComparisonDrawerHeader from './ComparisonDrawerHeader';
import Divider from '@verticeone/design-system/src/components/Divider';
import Card from '@verticeone/design-system/src/components/Card';
import ComparisonDrawerStats from './ComparisonDrawerStats';
import { TableDataRow } from './types';
import { useComparisonDrawer } from './ComparisonDrawerContext';
import ComparisonDrawerTable from './ComparisonDrawerTable';
import ComparisonDrawerGraph from './ComparisonDrawerGraph';
import useDrawerData from './useDrawerData';

const ComparisonDrawer = () => {
  const { isOpened, close } = useComparisonDrawer();
  const { data, isLoading, isEmpty, error } = useDrawerData();

  return (
    <Drawer width={'calc(100% / 12 * 9)'} open={isOpened} onClose={close} fullScrollbar>
      <ComparisonDrawerHeader isLoading={isLoading} columnsMapping={data?.columnsMapping || {}} />
      <DrawerBody>
        <Card>
          <ComparisonDrawerStats data={data?.statsData} isLoading={isLoading} />
          <Divider />
          <ComparisonDrawerGraph data={data?.gridData} isLoading={isLoading} isEmpty={isEmpty} error={error} />
          <ComparisonDrawerTable<TableDataRow>
            columnsMapping={data?.columnsMapping || {}}
            data={data?.tableData || []}
            isLoading={isLoading}
          />
        </Card>
      </DrawerBody>
    </Drawer>
  );
};

export default ComparisonDrawer;
