import { ComponentProps, ElementType, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';

import Button from '@verticeone/design-system/src/components/Button';
import ButtonGroup from '@verticeone/design-system/src/components/ButtonGroup';
import { DesignSystemColor } from '@verticeone/design-system/src/types';
import { TaskFormEntry } from '../../shared/formFields/TaskFormEntry';
import { SecurityScore } from '../../shared/constants';
import { FormData } from '../schema';

type SecurityScoreFormEntryProps = {
  name: keyof FormData;
};

type SecurityScoreBtnGroupProps = {
  value?: FormData['securityScore'];
  onChange: (value: FormData['securityScore']) => void;
} & ComponentProps<ElementType>;

type ScoreOption = {
  value: FormData['securityScore'];
  label: string;
  color: DesignSystemColor;
};

export const SecurityScoreFormEntry: FC<SecurityScoreFormEntryProps> = ({ name }) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<FormData>();
  const securityScore = useWatch<FormData, 'securityScore'>({ name: 'securityScore' });

  return (
    <TaskFormEntry<FormData, SecurityScoreBtnGroupProps>
      name={'securityScore'}
      label={t('INTAKE_FORM.SECURITY_ASSESSMENT.Q_SECURITY_SCORE')}
      required={false}
      component={SecurityScoreBtnGroup}
      componentProps={{
        onChange: (value: FormData['securityScore']) => setValue('securityScore', value, { shouldValidate: true }),
        value: securityScore,
      }}
    />
  );
};

const SecurityScoreBtnGroup: FC<SecurityScoreBtnGroupProps> = ({ value, onChange, ...restProps }) => {
  const { t } = useTranslation();
  const scoreOptions: ScoreOption[] = useMemo(
    () => [
      {
        value: SecurityScore.NONE,
        label: t(`INTAKE_FORM.SECURITY_ASSESSMENT.SCORE.${SecurityScore.NONE}`),
        color: 'neutral',
      },
      {
        value: SecurityScore.LOW,
        label: t(`INTAKE_FORM.SECURITY_ASSESSMENT.SCORE.${SecurityScore.LOW}`),
        color: 'error',
      },
      {
        value: SecurityScore.MODERATE,
        label: t(`INTAKE_FORM.SECURITY_ASSESSMENT.SCORE.${SecurityScore.MODERATE}`),
        color: 'warning',
      },
      {
        value: SecurityScore.HIGH,
        label: t(`INTAKE_FORM.SECURITY_ASSESSMENT.SCORE.${SecurityScore.HIGH}`),
        color: 'success',
      },
    ],
    [t]
  );

  const activeScoreButtonKeys = useMemo(
    () => scoreOptions.findIndex((option) => option.value === value),
    [scoreOptions, value]
  );

  return (
    <ButtonGroup activeButtonKeys={activeScoreButtonKeys} container {...restProps}>
      {scoreOptions.map((option) => (
        <Button key={option.value} onClick={() => onChange?.(option.value)} color={option.color}>
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
