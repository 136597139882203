import React from 'react';
import Text from '@verticeone/design-system/src/components/Text';
import { Card, CardHeader } from '@verticeone/design-system/src/components/Card';
import DataSourceProvider from '../../DataSourceProvider';
import { Summary } from '../../widgets/Overview/components';
import Graph from '../components/Graph';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SageMaker = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.PRODUCT.SAGE_MAKER' });

  return (
    <Card>
      <CardHeader>
        <Text variant="caption" size="XL" color="text1">
          {t('TITLE')}
        </Text>
      </CardHeader>
      <Stack p={6} gap={6}>
        <DataSourceProvider datasource="SageMaker">
          <Summary />
          <Graph />
        </DataSourceProvider>
      </Stack>
    </Card>
  );
};

export default SageMaker;
