import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { Link } from '@verticeone/design-system/src/components/Link';

import Text from '@verticeone/design-system/src/components/Text';
import Divider from '@verticeone/design-system/src/components/Divider';
import { Link as RouterLink } from 'react-router-dom';

import { IntelligentWorkflowsLayout } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';
import { RequestDetailSkeleton } from './RequestDetailSkeleton';
import { RequestHeaderMenu } from './components/RequestHeaderMenu';
import { RequestStatusChip } from '../components/RequestStatusChip';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { GenericRequestIcon } from '@vertice/core/src/modules/intelligentWorkflows/components/icons/Icons';
import { useRequestContext } from './RequestContext';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { CollapsibleSection } from './components/CollapsibleSection';
import { VisualizerSection, ConversationSection } from './sections';
import { useRequestWidgets } from './widgets/usePredefinedWidgets';

export const RequestDetailPage = () => {
  const { accountId } = useAccountContext();

  const {
    requestId,
    request,
    parentRequest,
    parentRequestPath,
    isLoading,
    workflowVersion,
    isExecutedByWorkflow,
    additionalWidgets,
    applicationIdentifier,
  } = useRequestContext();

  const parentRequestRef = parentRequest ? parseRequestRef(parentRequest.ref) : undefined;
  const parentRequestAccountId = parentRequestRef?.accountId;
  const isParentRequestFromDifferentAccount = parentRequestAccountId !== accountId;

  const widgets = useRequestWidgets(request, additionalWidgets);

  const header = useMemo(() => {
    return (
      <Stack>
        {parentRequest && (
          <Stack direction="row" alignItems="center" gap={1}>
            <GenericRequestIcon />
            <Text variant="body-regular" size="M">
              <Link
                component={RouterLink}
                to={parentRequestPath!}
                target={isParentRequestFromDifferentAccount ? '_blank' : '_self'}
              >
                {parentRequest?.name}
              </Link>
            </Text>
            <RequestStatusChip status={parentRequest?.status} variant="outline" size="XS" />
          </Stack>
        )}
        {request && (
          <Stack direction="row" alignItems="center" gap={1}>
            <GenericRequestIcon size="XL" />
            <Text variant="heading" size="M">
              {request?.name}
            </Text>
            <RequestStatusChip status={request?.status} variant="solid" />
          </Stack>
        )}
      </Stack>
    );
  }, [isParentRequestFromDifferentAccount, parentRequest, parentRequestPath, request]);

  if (isLoading || request === undefined) {
    return (
      <IntelligentWorkflowsLayout isLoading={isLoading}>
        <RequestDetailSkeleton />
      </IntelligentWorkflowsLayout>
    );
  }

  return (
    <IntelligentWorkflowsLayout
      heading={header}
      currentPageName={request.name}
      parentPageName={parentRequest?.name}
      parentPagePath={parentRequestPath}
      parentPageAccountId={parentRequestAccountId}
      actions={<RequestHeaderMenu isRequestTerminationEnabled={applicationIdentifier === 'IAT'} request={request} />}
      isLoading={isLoading}
    >
      <Stack gap={4}>
        {isExecutedByWorkflow && <VisualizerSection request={request} workflowVersion={workflowVersion} />}
        <Divider />
        <Stack gap={4} divider={<Divider />} direction={{ xs: 'column', md: 'row' }} width={'100%'}>
          <Stack gap={4} divider={<Divider />} width={'100%'}>
            {widgets?.map(({ widgetDef, widgetData }, index) => (
              <CollapsibleSection key={index} titleTranslationKey={widgetDef.titleTranslationKey}>
                <widgetDef.component widgetData={widgetData} />
              </CollapsibleSection>
            ))}
          </Stack>
          <Stack
            minHeight={768}
            maxHeight={'100%'}
            maxWidth={{ xs: '100%', md: 400 }}
            minWidth={{ xs: '100%', md: 400 }}
          >
            <ConversationSection requestId={requestId} />
          </Stack>
        </Stack>
      </Stack>
    </IntelligentWorkflowsLayout>
  );
};
