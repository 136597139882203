import React from 'react';
import { rgba } from 'polished';
import { type CheckboxProps as MuiCheckboxProps, default as MuiCheckbox } from '@mui/material/Checkbox/Checkbox';
import { styled } from '@mui/material/styles';
import type { DesignSystemSize, DesignSystemColor, TestProps } from '../../types';
import { gradientBorder } from '../../utils/css/gradientBorder';
import { ifExpression } from '../../utils/ifExpression';
import { CheckBox as CheckedCheckBox, IndeterminateCheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { testProps } from '../../utils/testProperties';
import { baseSizes } from '../../guidelines/Sizing/sizings';

export type CheckboxProps = TestProps &
  Omit<MuiCheckboxProps, 'size' | 'color'> & {
    size?: DesignSystemSize;
    color?: DesignSystemColor;
  };

type StyledCheckboxProps = {
  $size: DesignSystemSize;
  $color: DesignSystemColor;
} & Pick<CheckboxProps, 'checked' | 'indeterminate' | 'disabled'>;

type StyledCheckboxWrapperProps = Omit<StyledCheckboxProps, keyof CheckboxProps> & {
  children: React.ReactElement<CheckboxProps>;
  $disabled: boolean;
};

export const sizePaddingMap: Record<DesignSystemSize, number> = {
  XL: 3,
  L: 3,
  M: 2.25,
  S: 2.25,
  XS: 1.5,
  XXS: 1.5,
};

const StyledCheckbox = styled(MuiCheckbox)<StyledCheckboxProps>(
  ({ theme, $size, $color, checked, indeterminate, disabled }) => ({
    color: theme.palette[$color].color2,
    padding: 0,
    ...ifExpression(checked || indeterminate, {
      backgroundColor: theme.palette[$color].color2,
    }),
    ...ifExpression(!disabled, {
      '&:not(:hover)': {
        ...gradientBorder({
          color:
            !checked && !indeterminate
              ? theme.palette.core.color4
              : `linear-gradient(6.02deg, ${rgba(theme.palette.core.bg, 0.4)} 0%, ${
                  theme.palette[$color].color2
                } 100%)`,
          radius: 'calc(12% + 2px)',
          width: theme.spacing(0.5),
        }),
      },
    }),
    ...ifExpression(disabled, {
      borderRadius: 'calc(12% + 2px)',
      border: `2px solid ${theme.palette.inactive.color2}`,
      svg: {
        color: theme.palette.inactive.color2,
      },
    }),
    '&:hover': {
      ...ifExpression(checked || indeterminate, {
        backgroundColor: theme.palette[$color].hover.color2,
      }),
      ...gradientBorder({
        color:
          !checked && !indeterminate
            ? theme.palette[$color].hover.color2
            : `linear-gradient(6.02deg, ${rgba(theme.palette.core.bg, 0.4)} 0%, ${
                theme.palette[$color].hover.color2
              } 100%)`,
        radius: 'calc(12% + 2px)',
        width: theme.spacing(0.5),
      }),
    },
    '&.Mui-checked, &.MuiCheckbox-indeterminate': {
      color: theme.palette[$color].color2,
    },
    '& svg': {
      borderRadius: '18%',
      opacity: checked || indeterminate ? 1 : 0,
      backgroundColor: theme.palette.global.color.white.main,
      fontSize: baseSizes[$size],
      transform: 'scale(1.1)',
    },
  })
);

export const StyledCheckboxWrapper = styled('div')<StyledCheckboxWrapperProps>(
  ({ theme, $color, $size, $disabled, children }) => ({
    display: 'flex',
    borderRadius: theme.spacing(sizePaddingMap[$size]),
    padding: theme.spacing(sizePaddingMap[$size]),
    ...ifExpression(!$disabled, {
      [`&:focus-within, &:focus-within ${StyledCheckbox}::before`]: {
        backgroundColor: theme.palette[$color].color2,
      },
      [`&:focus-within, &:focus-within ${StyledCheckbox} svg`]: {
        backgroundColor: theme.palette[$color].color4,
      },
      [`&:active, &:active ${StyledCheckbox} svg`]: {
        backgroundColor: theme.palette[$color].hover.color4,
      },
      [`&:hover ${StyledCheckbox},  &:active ${StyledCheckbox}`]: {
        color: theme.palette[$color].hover.color2,
        ...gradientBorder({
          color:
            !children.props.checked && !children.props.indeterminate
              ? theme.palette[$color].hover.color2
              : `linear-gradient(6.02deg, ${rgba(theme.palette.core.bg, 0.4)} 0%, ${
                  theme.palette[$color].hover.color2
                } 100%)`,
          radius: 'calc(12% + 2px)',
          width: theme.spacing(0.5),
        }),
      },
    }),
  })
);

const Checkbox = ({ size = 'M', color = 'primary', onClick, sx, testId, disabled, ...otherProps }: CheckboxProps) => (
  <StyledCheckboxWrapper $color={color} $size={size} $disabled={!!disabled} sx={sx} {...testProps(testId, 'checkbox')}>
    <StyledCheckbox
      disabled={disabled}
      $size={size}
      $color={color}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        (document.activeElement as HTMLElement | null)?.blur();
        onClick?.(event);
      }}
      icon={<CheckBoxOutlineBlank viewBox="3 3 18 18" sx={{ fill: 'transparent' }} />}
      checkedIcon={<CheckedCheckBox viewBox="3 3 18 18" />}
      indeterminateIcon={<IndeterminateCheckBox viewBox="3 3 18 18" />}
      {...otherProps}
    />
  </StyledCheckboxWrapper>
);

export default Checkbox;
