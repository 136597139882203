import { Stack, SxProps, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import styled from '@mui/material/styles/styled';
import { Person } from '@mui/icons-material';
import { DesignSystemColor, DesignSystemSize, TestProps } from '../../types';
import { baseSizes } from '../../guidelines/Sizing/sizings';
import Text from '../Text';
import { testProps } from '../../utils/testProperties';
import { Theme } from '@mui/material/styles';
import { getAvatarColors } from './utils';
import Tooltip from '../Tooltip';

export type AvatarColors = Extract<DesignSystemColor, 'primary' | 'secondary' | 'tertiary' | 'neutral'> | 'auto';
export type AvatarShape = 'circle' | 'square';

export type AvatarProps = TestProps & {
  size?: DesignSystemSize;
  disabled?: boolean;
  color?: AvatarColors;
  shape?: AvatarShape;
  personName?: string;
  invertColors?: boolean;
  badge?: ReactNode;
  personId: string;
  personImageSrc?: string;
  sx?: SxProps<Theme>;
  disableTooltip?: boolean;
};

export const iconSizes: Record<DesignSystemSize, string> = {
  XL: '32px',
  L: '28px',
  M: '24px',
  S: '20px',
  XS: '16px',
  XXS: '12px',
};

export const StyledAvatar = styled(Stack)<{
  $size: DesignSystemSize;
  $backgroundColor: string;
  $foregroundColor: string;
  $shape: AvatarShape;
  $disabled?: boolean;
  $personImageSrc?: string;
  $hueShift?: number;
}>(({ $size, $backgroundColor, $foregroundColor, $disabled, $shape, $personImageSrc, $hueShift }) => {
  const baseSize = baseSizes[$size];

  return {
    width: `calc(${baseSize} * 2)`,
    height: `calc(${baseSize} * 2)`,
    borderRadius: $shape === 'circle' ? baseSize : '4px',
    backgroundColor: $backgroundColor,
    alignItems: 'center',
    position: 'relative',
    // image
    backgroundImage: $personImageSrc ? `url("${$personImageSrc}")` : undefined,
    filter: $disabled ? `grayscale(1)` : undefined,
    backgroundSize: 'cover',
    // icon
    svg: {
      marginTop: `calc((${baseSize} * 2 - ${iconSizes[$size]}) / 2)`,
      fill: $foregroundColor,
      width: iconSizes[$size],
      height: iconSizes[$size],
    },
    // initials
    span: {
      color: $foregroundColor,
      textTransform: 'uppercase',
      lineHeight: `calc(${baseSize} * 2)`,
    },
  };
});

const Avatar: FC<AvatarProps> = ({
  size = 'M',
  color = 'neutral',
  shape = 'circle',
  invertColors = false,
  badge,
  personImageSrc,
  personName,
  personId,
  disabled,
  testId,
  disableTooltip = false,
  sx,
}) => {
  const { palette } = useTheme();
  const initials = personName
    ?.trim()
    .split(' ')
    .map((n) => n[0]) || ['??'];
  // only show first and last initial
  if (initials.length > 2) {
    initials.splice(1, initials.length - 2);
  }

  let backgroundColor;
  let foregroundColor;
  if (disabled) {
    backgroundColor = palette['inactive'].color4;
    foregroundColor = palette['inactive'].color2;
  } else if (color === 'auto') {
    const { bgColor, fgColor } = getAvatarColors(palette, personId);
    backgroundColor = bgColor;
    foregroundColor = fgColor;
  } else {
    backgroundColor = palette[color].color4;
    foregroundColor = palette[color].color2;
  }

  return (
    <Tooltip content={personName} size={size} minified disableHoverListener={disableTooltip}>
      <StyledAvatar
        $size={size}
        $backgroundColor={invertColors ? foregroundColor : backgroundColor}
        $foregroundColor={invertColors ? backgroundColor : foregroundColor}
        $shape={shape}
        $disabled={disabled}
        $personImageSrc={personImageSrc}
        {...testProps(testId, 'avatar')}
        sx={sx}
      >
        {!personName && !personImageSrc && <Person />}
        {!personImageSrc && (
          <Text variant="button" size={size}>
            {initials}
          </Text>
        )}
        {badge}
      </StyledAvatar>
    </Tooltip>
  );
};

export default Avatar;
