import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Text from '@verticeone/design-system/src/components/Text';
import DefaultColumn from './DefaultColumn';
import { useDataVisibility } from '../utils';

const ObfuscatedCellRenderer = ({ value }: GridRenderCellParams) => (
  <Text
    variant="body-regular"
    size="M"
    title={value}
    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', filter: 'blur(6px)' }}
  >
    {value}
  </Text>
);

const ObfuscatedColumn = (props: GridRenderCellParams) => {
  const { spendAuditVisibleTests, canViewFullVersion } = useDataVisibility();

  const isVisible = canViewFullVersion || spendAuditVisibleTests.includes(props.row.code);
  return isVisible ? <DefaultColumn {...props} /> : <ObfuscatedCellRenderer {...props} />;
};

export default ObfuscatedColumn;
