import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Shadows } from '@mui/material/styles/shadows';
import { createTheme as createMuiTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import muiComponentsOverridesNew from './muiComponentsOverridesNew';
import typography from './typography';
import variants from './variants';
import breakpoints from './breakpoints';
import { LocalizationProvider as DatePickerLocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs as DatePickerAdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import type { ThemeVariant } from './types';
import { THEME_STORE_KEY } from './constants';
import '../translate';
import { LocaleContextProvider, useLocale } from '../utils/formatting/LocaleContext';

export type ThemeProviderProps = {
  children: React.ReactNode;
  locale: string;
  preselectedTheme?: ThemeVariant;
};

type ThemeContextType = {
  theme: ThemeVariant;
  setTheme: (theme: ThemeVariant) => void;
  locale: string;
};

export const createTheme = (name: ThemeVariant) => {
  const themeConfig = variants[name];

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints,
      components: muiComponentsOverridesNew,
      typography,
      shadows: ['none', ...Array(24).fill(`box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);`)] as Shadows,
      palette: themeConfig.palette,
    },
    {
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    }
  );
};

const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

function ThemeProvider({ children, locale, preselectedTheme = 'default' }: ThemeProviderProps) {
  const storedTheme = localStorage.getItem(THEME_STORE_KEY);
  const initTheme: ThemeVariant = storedTheme ? JSON.parse(storedTheme) : preselectedTheme;
  const [theme, setTheme] = useState<ThemeVariant>(initTheme);

  const changeTheme = (variant: ThemeVariant) => {
    localStorage.setItem('theme', JSON.stringify(variant));
    setTheme(variant);
  };

  // Temporarily use locale context copied from @vertice/core before the formatters get moved to
  // the lightweight @vertice/utils package.
  const localeContext = useLocale(locale);

  return (
    <DatePickerLocalizationProvider
      dateAdapter={DatePickerAdapterDayjs}
      localeText={{
        fieldMonthPlaceholder: ({ format }) => format,
      }}
    >
      <LocaleContextProvider value={localeContext}>
        <ThemeContext.Provider value={{ theme, setTheme: changeTheme, locale }}>
          <MuiThemeProvider theme={createTheme(theme)}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
      </LocaleContextProvider>
    </DatePickerLocalizationProvider>
  );
}

const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider component');
  }
  return context;
};

function InvertedThemeProvider({ children }: { children: ReactNode }) {
  const { theme, setTheme, locale } = useThemeContext();
  const invertedTheme: ThemeVariant = theme === 'dark' ? 'light' : 'dark';
  return (
    <ThemeContext.Provider value={{ theme: invertedTheme, setTheme, locale }}>
      <MuiThemeProvider theme={createTheme(invertedTheme)}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, useThemeContext, InvertedThemeProvider };
