import { useTranslation } from 'react-i18next';
import { useProductExtraColumnBuilder } from '../ProductList';
import React, { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';

import { DateCell } from '../ContractList/Cells/DateCell';
import DatePickerCell from '../ProductList/cells/DatePickerCell';
import { ProductListColumn } from '../ProductList/types';
import Text from '@verticeone/design-system/src/components/Text';
import { AnnualCostCell, EditableAnnualCostCell, EditableLicensesCell } from '../ProductList/cells';
import { CostModelProductItem, RollingFrequency } from './types';
import { GridHeaderCell } from '@verticeone/design-system/src/components/DataGrid/components/GridHeaderCell';

export type UseExtraColumnsParams = {
  rollingFrequency: RollingFrequency;
};

export const useExtraColumns = ({ rollingFrequency }: UseExtraColumnsParams) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.COST_MODEL_PRODUCT_LIST' });
  const { buildColumn } = useProductExtraColumnBuilder();

  return useMemo((): GridColDef<CostModelProductItem>[] => {
    const isRolling = !!rollingFrequency && rollingFrequency !== 'NO';
    const customSpansColumns = isRolling
      ? []
      : ([
          {
            field: 'startDate',
            headerName: t('START_DATE'),
            valueGetter: ({ row }) => row.allocationSpan?.start_date,
            valueSetter: ({ row, value }) => ({
              ...row,
              allocationSpan: { ...row.allocationSpan, start_date: value },
            }),
            renderCell: ({ value }) => (value ? <DateCell date={value} /> : null),
            renderEditCell: (params) => <DatePickerCell {...params} />,
            disableColumnMenu: true,
            editable: true,
            flex: 1,
          },
          {
            field: 'endDate',
            headerName: t('END_DATE'),
            valueGetter: ({ row }) => row.allocationSpan?.end_date,
            valueSetter: ({ row, value }) => ({
              ...row,
              allocationSpan: { ...row.allocationSpan, end_date: value } as CostModelProductItem['allocationSpan'],
            }),
            renderCell: ({ value }) => (value ? <DateCell date={value} /> : null),
            renderEditCell: (params) => <DatePickerCell {...params} />,
            disableColumnMenu: true,
            editable: true,
            flex: 1,
          },
        ] as GridColDef<CostModelProductItem>[]);

    return [
      buildColumn(ProductListColumn.LICENSE_TYPE)!,
      {
        field: 'licenseCost',
        headerName: isRolling ? t(`LICENSE_COST_${rollingFrequency}`) : t('LICENSE_COST_IN_TERM'),
        renderHeader: isRolling
          ? undefined
          : (params) => (
              <GridHeaderCell
                {...params}
                tooltip={{
                  content: t('LICENSE_COST_IN_TERM_TOOLTIP'),
                }}
              />
            ),
        renderCell: (params) => <Text variant="caption">{params.row.licenseCost ?? ''}</Text>,
        renderEditCell: (params) => <EditableAnnualCostCell {...params} />,
        disableColumnMenu: true,
        valueSetter: ({ row, value: licenseCost }) => {
          const { numberOfLicences, annualCost, ...rest } = row;
          return {
            ...rest,
            numberOfLicences,
            licenseCost,
            annualCost: numberOfLicences && licenseCost ? numberOfLicences * licenseCost : annualCost,
          };
        },
        editable: true,
        flex: 1,
      },
      {
        field: 'numberOfLicences',
        headerName: t('LICENSES'),
        renderCell: (params) => <Text variant="caption">{params.row.numberOfLicences ?? ''}</Text>,
        renderEditCell: (params) => <EditableLicensesCell {...params} />,
        disableColumnMenu: true,
        valueSetter: ({ row, value: numberOfLicences }) => {
          const { licenseCost, annualCost, ...rest } = row;
          return {
            ...rest,
            numberOfLicences,
            licenseCost,
            annualCost: numberOfLicences && licenseCost ? numberOfLicences * licenseCost : annualCost,
          };
        },
        editable: true,
        flex: 0.5,
      },
      {
        field: 'annualCost',
        headerName: isRolling ? t(`TOTAL_COST_${rollingFrequency}`) : t('TOTAL_COST_IN_TERM'),
        renderHeader: isRolling
          ? undefined
          : (params) => (
              <GridHeaderCell
                {...params}
                tooltip={{
                  content: t('TOTAL_COST_IN_TERM_TOOLTIP'),
                }}
              />
            ),
        renderCell: (params) => <AnnualCostCell {...params} />,
        renderEditCell: (params) => <EditableAnnualCostCell {...params} />,
        disableColumnMenu: true,
        valueSetter: ({ row, value }) => {
          const { numberOfLicences, licenseCost, annualCost, ...rest } = row;
          return {
            ...rest,
            numberOfLicences: value && licenseCost && !numberOfLicences ? value / licenseCost : numberOfLicences,
            licenseCost: value && numberOfLicences && !licenseCost ? value / numberOfLicences : licenseCost,
            annualCost: !licenseCost || !numberOfLicences ? value : annualCost,
          };
        },
        editable: true,
        flex: 1,
      },
      ...customSpansColumns,
    ];
  }, [rollingFrequency, t, buildColumn]);
};
