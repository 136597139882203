import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { components, GroupBase, type OptionProps, type SingleValueProps, SelectInstance } from 'react-select';
import Select, { SelectProps } from '@verticeone/design-system/src/components/Select';
import { ContractSelectItem } from './ContractSelectItem';
import { ContractOption } from './types';
import { useSelectContractsOptions } from './useSelectContractsOptions';

export type ContractSelectProps = Omit<SelectProps<ContractOption, false>, 'value' | 'onChange'> & {
  value: ContractOption | null;
  onChange: (value: ContractOption | null) => void;
};

const ContractSelectOption = (
  props: JSX.IntrinsicAttributes & OptionProps<ContractOption, false, GroupBase<ContractOption>>
) => (
  <components.Option {...props}>
    <ContractSelectItem option={props.data} />
  </components.Option>
);

const ContractSingleValue = (props: SingleValueProps<ContractOption>) => (
  <components.SingleValue {...props}>
    <ContractSelectItem option={props.data} />
  </components.SingleValue>
);

// used for search
const getOptionLabel = ({ contract }: ContractOption) => {
  return `${contract.parts.contractual?.vendor?.vendorName || ''} ${contract.parts.contractual?.products
    ?.map((p) => p.productName)
    .join(' ')}`;
};

export const ContractSelect = forwardRef(
  (
    { placeholder, noOptionsMessage, onChange, ...otherProps }: ContractSelectProps,
    ref: React.Ref<SelectInstance<ContractOption>> | undefined
  ) => {
    const { t } = useTranslation();
    const { options, isLoading } = useSelectContractsOptions();
    return (
      <Select
        ref={ref}
        options={options}
        getOptionValue={(c) => c.id}
        isLoading={isLoading}
        getOptionLabel={getOptionLabel}
        components={{ Option: ContractSelectOption, SingleValue: ContractSingleValue }}
        onChange={onChange}
        menuPortalTarget={document.body}
        placeholder={placeholder ?? t('ENTITIES.FILTERS.PLACEHOLDERS.SEARCH_CONTRACTS')}
        noOptionsMessage={noOptionsMessage ?? (() => t('ENTITIES.WORKFLOW_REQUEST.ERRORS.NOT_FOUND'))}
        {...otherProps}
      />
    );
  }
);
