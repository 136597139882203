import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import { useGetSupportedIntegrationsQuery } from '@vertice/slices';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { LastFetchedInfo } from '@vertice/core/src/modules/applications/components/LastFetchedInfo';
import { ErrorLogButton, ErrorLogDrawer } from '@vertice/core/src/modules/applications/components/ErrorLogDrawer';
import { useLastFetchedData } from '@vertice/core/src/modules/applications/hooks/useLastFetchedData';
import OktaSetupCard from './OktaSetup/OktaSetupCard';
import { EntraSetupCard } from './EntraSetup/EntraSetupCard';
import OneLoginSetupCard from './OneLogin/OneLoginSetupCard';
import JumpCloudSetupCard from './JumpCloud/JumpCloudSetupCard';
import { ONELOGIN_INTEGRATION_ID } from './OneLogin/Steps/common';
import { ENTRA_INTEGRATION_ID } from './EntraSetup/Steps/common';
import { OKTA_INTEGRATION_ID } from './OktaSetup/Steps/common';
import { JUMPCLOUD_INTEGRATION_ID } from './JumpCloud/Steps/common';
import { GOOGLE_INTEGRATION_ID } from './Google/Steps/common';
import GoogleSetupCard from './Google/GoogleSetupCard';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { DataSourceGroup } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { filterDailyAlarms } from '@vertice/core/src/modules/applications/Applications/helpers';

const CardWrapper = ({ children }: PropsWithChildren) => (
  <Grid container item xs={4} sx={{ minWidth: '400px' }}>
    {children}
  </Grid>
);

export const DiscoveryAndUsage = () => {
  const { t } = useTranslation();
  const { data: supportedIntegrations } = useGetSupportedIntegrationsQuery();
  const { isEnabled } = useFeatures();
  const googleIntegrationFeatureEnabled = isEnabled(FEATURES.INTEGRATION_GOOGLE);

  const [showErrorLog, setShowErrorLog] = useState(false);

  const { data, isLoading: isLoadingLastFetchedData } = useLastFetchedData(DataSourceGroup.All);
  const accessLogs = data?.getAlarms?.lastDataUpdate;
  const hasAccessLogs = accessLogs && accessLogs.length > 0;
  const alarmsPerDay = filterDailyAlarms(data?.getAlarms?.alarmsPerDay);

  const handleLogClose = () => {
    setShowErrorLog(false);
  };

  return (
    <Stack gap={6}>
      <Stack direction="row" justifyContent="space-between" gap={2}>
        <Text variant="heading" size="S">
          {t('PREFERENCES.INTEGRATIONS.DISCOVERY_AND_USAGE')}
        </Text>
        {!isLoadingLastFetchedData && (
          <Stack direction="row" alignItems="center" gap={4}>
            {hasAccessLogs && <LastFetchedInfo accessLogs={accessLogs} alarmsPerDay={alarmsPerDay} />}
            <ErrorLogButton onClick={() => setShowErrorLog(true)} />
            <ErrorLogDrawer open={showErrorLog} onClose={handleLogClose} alarmsPerDay={alarmsPerDay} />
          </Stack>
        )}
      </Stack>
      <Grid container spacing={3} direction="row">
        {supportedIntegrations?.map((supportedIntegration, index) => {
          switch (supportedIntegration.id) {
            case OKTA_INTEGRATION_ID:
              return (
                <CardWrapper key={index}>
                  <OktaSetupCard providerId={supportedIntegration.id} />
                </CardWrapper>
              );
            case ENTRA_INTEGRATION_ID:
              return (
                <CardWrapper key={index}>
                  <EntraSetupCard providerId={supportedIntegration.id} />
                </CardWrapper>
              );
            case ONELOGIN_INTEGRATION_ID:
              return (
                <CardWrapper key={index}>
                  <OneLoginSetupCard providerId={supportedIntegration.id} />
                </CardWrapper>
              );
            case JUMPCLOUD_INTEGRATION_ID:
              return (
                <CardWrapper key={index}>
                  <JumpCloudSetupCard providerId={supportedIntegration.id} />
                </CardWrapper>
              );
            case GOOGLE_INTEGRATION_ID:
              return googleIntegrationFeatureEnabled ? (
                <CardWrapper key={index}>
                  <GoogleSetupCard providerId={supportedIntegration.id} />
                </CardWrapper>
              ) : null;
            default:
              return null;
          }
        })}
      </Grid>
    </Stack>
  );
};
