import React, { FC, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled, Stack } from '@mui/material';
import { TEST_USER_CALENDLY_URL } from '@vertice/utils/src/constants/cfa/constants';
import { Account, getAccount, useGetAccountQuery, useGetUserPublicInformationQuery } from '@vertice/slices';
import { Loader } from '@vertice/components';
import Text from '@verticeone/design-system/src/components/Text';
import Button from '@verticeone/design-system/src/components/Button';
import { getFullName } from '@vertice/core/src/utils/formatting/user';
import safePick from '@vertice/core/src/utils/objects/safePick';
import { AuthType, useAuthentication } from '@verticeone/auth/src';

const LazyCalendlyButton = lazy(() => import('../CalendlyButton/CalendlyButton'));

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.core.color1,
  borderRadius: '5px',
  padding: '8px',
  gap: '16px',
}));

const AccountManagerCard: FC = () => {
  const { t } = useTranslation();
  const { accountId } = useSelector(getAccount);

  const { data: accountDetails, isLoading: isCompanyDataLoading } = useGetAccountQuery(
    { accountId: accountId! },
    { skip: !accountId }
  ) as { data: Account; isLoading: boolean };

  const {
    data: managerProfile,
    isUninitialized: isManagerProfileUninitialized,
    isLoading: isManagerProfileLoading,
  } = useGetUserPublicInformationQuery(
    { userId: (accountDetails as Account)?.associatedSuccessManager! },
    { skip: !accountDetails || !(accountDetails as Account)?.associatedSuccessManager }
  );

  const { type: authType } = useAuthentication();

  const calendlyUrl =
    window._configEnv.REACT_APP_PROFILE === 'production'
      ? managerProfile?.userProperties?.calendlyUrl
      : managerProfile?.userProperties?.calendlyUrl || TEST_USER_CALENDLY_URL;

  if (
    isCompanyDataLoading ||
    isManagerProfileLoading ||
    (isManagerProfileUninitialized && (accountDetails as Account)?.associatedManager)
  ) {
    return (
      <Wrapper>
        <Loader size={32} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Stack direction="column" sx={{ gap: '2px' }}>
        <Text variant="caption" size="M">
          {getFullName(safePick(managerProfile ?? {}, 'firstName', 'lastName'))}
        </Text>
        <Text variant="label" size="XXS" color="text1" sx={{ opacity: '0.4' }}>
          {t('SIDEBAR.CUSTOMER_SUCCESS')}
        </Text>
      </Stack>
      <Stack direction="row" gap={1}>
        {calendlyUrl && authType !== AuthType.ASSUME_ROLE && (
          <Suspense fallback={null}>
            <LazyCalendlyButton calendlyUrl={calendlyUrl}>{t('SIDEBAR.SCHEDULE_CALL')}</LazyCalendlyButton>
          </Suspense>
        )}
        {managerProfile?.email && (
          <Button variant="outline" size="XS" onClick={() => (window.location.href = 'mailto:' + managerProfile.email)}>
            {t('SIDEBAR.SEND_EMAIL')}
          </Button>
        )}
      </Stack>
    </Wrapper>
  );
};

export default AccountManagerCard;
