import { useMemo } from 'react';
import LoadableAdvanced from '@vertice/utils/src/loadableAdvanced';
import {
  useAnalyticsAccountServicesUsageQuery,
  ViewResult,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import useDeferredQuery from '@vertice/core/src/utils/api/useDeferredQuery';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { prepareTableData, prepareStatsData } from '../../BaseCloudTab/Table/utils';
import { CloudAnalyticsData } from '../../BaseCloudTab/Table/types';
import { useCloudAnalytics } from '../../../CloudAnalyticsContext';

export type TableRow = {
  usageType: string;
  cost: number;
  previousCost: number;
  difference: number | null;
  charges: number;
  credit: number;
  tax: number;
  previousCharges: number;
  previousCredit: number;
  previousTax: number;
};

type AccountsServicesDataProps = {
  linkedAccountId: string;
  productCode: string;
};

const useAccountServicesUsageData = ({
  linkedAccountId,
  productCode,
}: AccountsServicesDataProps): LoadableAdvanced<CloudAnalyticsData<TableRow>> => {
  const { accountId } = useAccountContext();
  const { period, chartDataLimit } = useCloudAnalytics();

  const {
    data: rawData,
    error,
    isLoading,
  } = useDeferredQuery(
    useAnalyticsAccountServicesUsageQuery,
    {
      accountId,
      linkedAccountId,
      productCode,
      ...period,
      limit: chartDataLimit,
    },
    { skip: !accountId, pollingInterval: 5000 },
    ({ athenaViewQuery }) =>
      athenaViewQuery?.__typename === 'DeferredQueryResult' ? undefined : { result: athenaViewQuery }
  );

  const computedTableData = useMemo(
    () =>
      prepareTableData<TableRow>(
        {
          usage_type: 'usageType',
          cost_period_one: 'cost',
          cost_period_two: 'previousCost',
          cost_change: 'difference',
          charges_period_one: 'charges',
          credit_period_one: 'credit',
          tax_period_one: 'tax',
          charges_period_two: 'previousCharges',
          credit_period_two: 'previousCredit',
          tax_period_two: 'previousTax',
        },
        rawData as ViewResult
      ),
    [rawData]
  );

  const computedStats = useMemo(() => prepareStatsData<TableRow>(computedTableData), [computedTableData]);

  return {
    error,
    isEmpty: Boolean(computedTableData && computedTableData?.length === 0),
    isLoading,
    data: {
      tableData: computedTableData,
      stats: computedStats,
    },
  };
};

export default useAccountServicesUsageData;
