import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { BaseWorkflowRenderer, WorkflowRendererProps } from './BaseWorkflowRenderer';
import { useLoggedUser } from '@verticeone/auth/src';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { WorkflowRendererContextProvider } from './WorkflowRendererContext';
import { useRequestTasks } from '../../request/RequestDetailPage/useRequestTasks';
import { TaskDrawer } from '../../task/TaskDrawer/TaskDrawer';
import { useRelevantUsersById } from '../../../../hooks/useRelevantUsersById';
import { Definitions } from '../../definitionsTypes';

type WorkflowRendererFullProps = PropsWithChildren<
  WorkflowRendererProps & {
    requestId: string;
    allowVerticeServiceNavigation?: boolean;
    isEditor?: boolean;
    workflowDefinitions?: Definitions;
  }
>;

const WorkflowRenderer: FC<WorkflowRendererFullProps> = ({
  children,
  model,
  workflowDefinitions,
  requestId,
  isEditor = false,
  allowVerticeServiceNavigation,
}) => {
  const { usersById } = useRelevantUsersById();
  const { userId: loggedUserId } = useLoggedUser();
  const { accountId } = useAccountContext();
  const { allTasks } = useRequestTasks(requestId);

  const [drawerTaskId, setDrawerTaskId] = useState<string | undefined>(undefined);

  const onOpenTask = (taskId: string) => {
    setDrawerTaskId(taskId);
  };
  const drawerTask = useMemo(() => {
    return allTasks.find((row) => drawerTaskId && row.id.includes(drawerTaskId));
  }, [allTasks, drawerTaskId]);

  return (
    <>
      <WorkflowRendererContextProvider
        value={{
          usersById,
          isEditor,
          loggedUserId,
          accountId,
          onOpenTask,
          allowVerticeServiceNavigation,
          workflowDefinitions,
          drawerTaskId,
        }}
      >
        <BaseWorkflowRenderer model={model}>{children}</BaseWorkflowRenderer>
      </WorkflowRendererContextProvider>
      {drawerTaskId && <TaskDrawer onClose={() => setDrawerTaskId(undefined)} taskRow={drawerTask} />}
    </>
  );
};

export default WorkflowRenderer;
