import React, { createContext, useContext } from 'react';

export type DataSource = 'SageMaker';

type DataSourceProviderProps = {
  datasource: DataSource;
  children: React.ReactNode;
};

type DataSourceProviderData = {
  datasource: DataSource;
};

const DataSourceContext = createContext<DataSourceProviderData>({} as DataSourceProviderData);

const DataSourceProvider = ({ datasource, children }: DataSourceProviderProps) => (
  <DataSourceContext.Provider value={{ datasource }}>{children}</DataSourceContext.Provider>
);

export const useDataSource = () => useContext(DataSourceContext);

export default DataSourceProvider;
