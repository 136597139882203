import React from 'react';
import Layout from './Layout';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import { useTranslation } from 'react-i18next';
import { Box, Stack, useTheme } from '@mui/material';
import Breadcrumbs, { BreadcrumbLink } from '@verticeone/design-system/src/components/Breadcrumbs';
import Text from '@verticeone/design-system/src/components/Text';

const Content = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Stack gap={8} py={10}>
      <Stack gap={1} px={10}>
        <Breadcrumbs variant="slash" size="S">
          <BreadcrumbLink>{t('CLOUD.NAVBAR.CLOUD')}</BreadcrumbLink>
          <BreadcrumbLink disabled>{t('CLOUD.NAVBAR.EDP')}</BreadcrumbLink>
        </Breadcrumbs>
        <Text variant="heading" size="M" color={palette.text.color1} tag="h1">
          {t('CLOUD.CLOUD_EDP.TITLE')}
        </Text>
      </Stack>
      <Box px={10} position="relative">
        <Layout />
      </Box>
    </Stack>
  );
};

const CloudInsightsEDP = () => {
  return (
    <LoadableAWSPageWrapper>
      <Content />
    </LoadableAWSPageWrapper>
  );
};

export default CloudInsightsEDP;
