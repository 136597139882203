import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import Text from '@verticeone/design-system/src/components/Text';
import Tooltip from '@verticeone/design-system/src/components/Tooltip';
import type { TestResultTooltip } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useValueFormatter } from '../utils';

type ResultTooltipProps = {
  tooltip?: TestResultTooltip;
  children: React.ReactElement;
};

const ResultTooltip = ({ tooltip, children }: ResultTooltipProps) => {
  const valueFormatter = useValueFormatter();

  const content = useMemo(
    () => (
      <Stack>
        {tooltip?.data?.map((row: Array<string>, row_index: number) => (
          <Stack key={row_index} direction="row" gap={4} alignItems="center">
            {row.map((column: string, col_index: number) => (
              <Text key={column} variant="body-regular" flex={col_index === 0 ? 2 : 1}>
                {valueFormatter(column, tooltip.types![col_index])}
              </Text>
            ))}
          </Stack>
        ))}
      </Stack>
    ),
    [tooltip, valueFormatter]
  );

  if (!tooltip) {
    return <>{children}</>;
  }

  return (
    <Tooltip size="S" content={content}>
      {children}
    </Tooltip>
  );
};

export default ResultTooltip;
