import { RendererEdge, RendererNode, RendererTaskNode } from './types';
import { useEffect, useMemo, useState } from 'react';
import { ReactFlowInstance } from '@xyflow/react';
import { TaskStatus } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';

const isNodeTaskNode = (node: RendererNode): node is RendererTaskNode => node.data.kind === 'task';
const shouldNodeFitIntoView = ({ data }: RendererTaskNode) =>
  data.state?.status && Array<TaskStatus>('ACTIVE', 'FAILED').includes(data.state.status);

const MIN_TASK_NODES_TO_FIT_ACTIVE_TASKS = 3;

export const useFitActiveTasksIntoView = ({
  nodes,
  skip,
  disableAnimation = false,
}: {
  nodes: RendererNode[];
  skip: boolean;
  disableAnimation?: boolean;
}) => {
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<RendererNode, RendererEdge>>();

  const { taskNodesToFitView, taskNodesCount } = useMemo(() => {
    const taskNodes = nodes.filter(isNodeTaskNode);

    return {
      taskNodesToFitView: taskNodes.filter(shouldNodeFitIntoView),
      taskNodesCount: taskNodes.length,
    };
  }, [nodes]);

  useEffect(() => {
    const taskNodesToFitViewCount = taskNodesToFitView.length;

    if (
      reactFlowInstance &&
      taskNodesCount > MIN_TASK_NODES_TO_FIT_ACTIVE_TASKS &&
      taskNodesToFitViewCount > 0 &&
      !skip
    ) {
      const fitViewToActiveNotes = async () => {
        await reactFlowInstance.fitView({
          nodes: taskNodesToFitView.map((node) => ({ id: node.id })),
          duration: disableAnimation ? 0 : 1000,
          padding: taskNodesToFitViewCount > 1 ? 0.1 : 3,
        });
      };

      const timeoutId = setTimeout(fitViewToActiveNotes, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [taskNodesToFitView, taskNodesCount, reactFlowInstance, skip, disableAnimation]);

  return {
    setReactFlowInstance,
  };
};
