import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';

import Text from '@verticeone/design-system/src/components/Text';
import { LoadableComponent } from '@verticeone/design-system/src/components/Loader';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useFormatCurrency } from '@vertice/core/src/utils/formatting/currency';
import { useOptimizationsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import useAnnualSpendData from '../../../../../modules/cloud/hooks/useAnnualSpendData';
import { AWS_BRAND_COLOR } from '../../../../../modules/cloud/constants';
import { useCloudOptimization } from '../../CloudOptimizationContext';
import { testProps } from '@verticeone/design-system/src/utils/testProperties';

const Tile = styled(Stack)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(6),
  backgroundColor: theme.palette.core.bg,
}));

const ScoreBarTitle = () => {
  const { accountId } = useAccountContext();
  const formatCurrency = useFormatCurrency();
  const annualSpendDataQuery = useAnnualSpendData();
  const { isAnnualPeriod } = useCloudOptimization();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS' });
  const multiplier = isAnnualPeriod ? 12 : 1;
  const optimizationsQuery = useOptimizationsQuery(
    { accountId },
    {
      skip: !accountId,
      selectFromResult: (result) => {
        if (result?.data?.optimizationsQuery?.__typename === 'OptimizationsResult') {
          return {
            ...result,
            data: result.data.optimizationsQuery.summary.estimatedSavings.find(({ category }) => category === 'TOTAL')
              ?.saving,
          };
        }

        return { ...result, data: undefined };
      },
    }
  );

  return (
    <Stack direction="row" gap={0.25} {...testProps('cost-overview')}>
      <Tile {...testProps('net-spend')}>
        <Text variant="body-bold" size="S" color="text2">
          {isAnnualPeriod ? t('NET_ANNUAL_SPEND') : t('NET_MONTHLY_SPEND')}
        </Text>
        <LoadableComponent isLoading={annualSpendDataQuery.isLoading} color={AWS_BRAND_COLOR}>
          <Text variant="heading" size="L" color="text1">
            {formatCurrency(
              (isAnnualPeriod
                ? annualSpendDataQuery?.data?.annualInvoicedSpend
                : annualSpendDataQuery?.data?.monthlyInvoicedSpend) ?? 0,
              { maximumFractionDigits: 0 }
            )}
          </Text>
        </LoadableComponent>
      </Tile>
      <Tile {...testProps('gross-spend')}>
        <Text variant="body-bold" size="S" color="text2">
          {isAnnualPeriod ? t('GROSS_ANNUAL_SPEND') : t('GROSS_MONTHLY_SPEND')}
        </Text>
        <LoadableComponent isLoading={annualSpendDataQuery.isLoading} color={AWS_BRAND_COLOR}>
          <Text variant="heading" size="L" color="text1">
            {formatCurrency(
              (isAnnualPeriod
                ? annualSpendDataQuery?.data?.annualGrossSpend
                : annualSpendDataQuery?.data?.monthlyGrossSpend) ?? 0,
              { maximumFractionDigits: 0 }
            )}
          </Text>
        </LoadableComponent>
      </Tile>
      <Tile {...testProps('estimated-savings')}>
        <Text variant="body-bold" size="S" color="text2">
          {isAnnualPeriod ? t('ESTIMATED_ANNUAL_SAVINGS') : t('ESTIMATED_MONTHLY_SAVINGS')}
        </Text>
        <LoadableComponent isLoading={optimizationsQuery.isLoading} color={AWS_BRAND_COLOR}>
          <Text variant="heading" size="L" color="text1">
            {formatCurrency(!!optimizationsQuery.data ? optimizationsQuery.data * multiplier : 0, {
              maximumFractionDigits: 0,
            })}
          </Text>
        </LoadableComponent>
      </Tile>
    </Stack>
  );
};

export default ScoreBarTitle;
