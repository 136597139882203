import React, { FC } from 'react';

import VendorLogo from '@vertice/core/src/components/VendorLogo';
import { Stack } from '@mui/material';
import { WorkflowServiceLogo } from '@verticeone/design-system/src/assets';
import { Vendor } from '../../../../../vendor/types';

export const RequestLogo: FC<{ vendor?: Vendor; isChild: boolean }> = ({ vendor, isChild }) => {
  return (
    <Stack width={40} alignItems="center" justifyContent="center">
      {isChild ? (
        <WorkflowServiceLogo width={28} />
      ) : (
        <VendorLogo vendorId={vendor?.type === 'PREDEFINED' ? vendor.id : undefined} size={40} />
      )}
    </Stack>
  );
};
